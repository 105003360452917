import { useCallback, useMemo } from 'react';

export const useMatchIdMapping = (live) => {
  const matchIdMapping = useMemo(() => {
    if (!live || !live.matchsDetails) {
      return {};
    }

    return live.matchsDetails.reduce((mapping, matchDetail) => {
      const { idMatch, homeTeam, awayTeam } = matchDetail;
      const teamName = `${homeTeam.name.slice(0, 4).trim()}-${awayTeam.name.slice(0, 4).trim()}`;
      mapping[idMatch] = teamName;
      return mapping;
    }, {});
  }, [live]);

  const getIdMatchFromMatchName = useCallback((teamName) => {
    for (const [idMatch, mappedTeamName] of Object.entries(matchIdMapping)) {
      if (mappedTeamName === teamName) {
        return idMatch;
      }
    }
    return null;
  }, [matchIdMapping]);

  return [matchIdMapping, getIdMatchFromMatchName];
};