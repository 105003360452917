export const getPeriodDuration = (discipline) => {
    switch(discipline){
        case 'soccer' :
            return 45
        case 'rugby':
            return 40
        case 'handball':
            return 30
        case 'basket':
            return 10
        default : 
            return 45
    }
}