export function cleanTweetURL(url) {
  // Vérifiez que l'URL est fournie et n'est pas une chaîne vide
  if (!url) {
     return '#';
  }

  let cleanedUrl;

  // Essayez de créer un objet URL pour valider le format de l'URL
  try {
      cleanedUrl = new URL(url);
  } catch (error) {
    console.log(error);
    return '#';
  }

  // Remplacer x.com par twitter.com
  if (cleanedUrl.host === "x.com") {
      cleanedUrl = new URL(url.replace("x.com", "twitter.com"));
  }

  return `${cleanedUrl.protocol}//${cleanedUrl.host}${cleanedUrl.pathname}`;
}