import { Box, Typography, LinearProgress  } from "@mui/material";

const BarChart = ({value}) => {

    return <Box
    sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: '100%',
          height: 10,
          borderRadius: '15px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'red',
            borderRadius: '15px',
          },
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'media.darkblue',
          },
        }}
      />
    </Box>
    <Typography variant="mediaH2" sx={{ ml: 1 }}>
      {value}%
    </Typography>
  </Box>
  
  }

  export default BarChart