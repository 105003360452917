import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { CircularProgress, Typography, Button, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getAllV2Gamefacts, getAllV2GamefactsStats, getOngletComponent, getPageInfos, getNavigationsComponent } from '../../../api/index.js';
import SearchFilterDTO from '../../DTO/SearchFilterDTO.js';
import ClassementDTO from '../../DTO/ClassementDTO.js';
import { useURLSearchParams } from '../../hooks/useUrlParams.js';
import Footer from '../../components/footer/Footer.js';

const ClassementResolver = ({viewIdName = "classement"}) => {

  //Display specific iframe options
  const [queryParams] = useSearchParams();
  const redirectUrl = queryParams.get('redirect-url');
  const displayText = queryParams.get('display-text');
  let displayPosition = queryParams.get('display-pos');
  const DSASLogo = queryParams.get('dsas-logo');
  const DSASLogoSize=queryParams.get('display-logo-sz');
  const DSASLogoDisplay=queryParams.get('display-logo-pos');

  //Force top display of redirect button by default if not set
  if (!displayPosition)
    displayPosition = 'top'


  const castToBoolean = (value, defaultValue) => {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    return value.toLowerCase() === 'true';
  };

  const showDSASLogo = castToBoolean(DSASLogo, true);

  const { id } = useParams();

  const [pageInfosByCompetition, setPageInfosByCompetition] = useState()
  const [live, setLive] = useState()

  const { searchParams, setParam, deleteParam } = useURLSearchParams();

  useEffect(() => {
    // On doit récupérer la liste des competitions du live
    const fetchData = async () => {
      try {
        const response = await getPageInfos(id, viewIdName);
        const { data } = response

        setPageInfosByCompetition(data.pageInfosByCompetition);
        setLive(data.live)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

  }, [])
  
  if (!pageInfosByCompetition || !live) {
    return <CircularProgress/>
  }

  if (!pageInfosByCompetition[0] || pageInfosByCompetition[0]===null) {
    return 'Aucune page disponible'
  }


  return <Box sx={{textAlign : 'center'}}>
    {/*<Typography sx={{fontSize : '1.1em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168", padding:'20px'}}>classement</Typography>*/}
   
   {/* REDIRECT BUTTON - TOP POSITION */}
    {(redirectUrl && displayText && displayPosition==='top') && (
        <Button
        variant="contained"
        style={{ backgroundColor: '#4A5168', color: '#ffffff', fontSize:'0.7em', padding:'5px' }}
        onClick={() => window.top.location.href = redirectUrl}
      >
        {displayText}
      </Button>
      )}

    {pageInfosByCompetition && pageInfosByCompetition.map((pageInfos, idx) => 
            <ViewsResolver 
              key={idx} 
              views={pageInfos.views} 
              competition={pageInfos.competition} 
              pageIdName={viewIdName} 
              searchParams={searchParams} 
              setParam={setParam} 
              deleteParam={deleteParam}
              showDSASLogo={showDSASLogo}
              DSASLogoSize={DSASLogoSize} 
              DSASLogoDisplay={DSASLogoDisplay}
              redirectUrl={redirectUrl}
              displayText={displayText}
              displayPosition={displayPosition}
              />
    )}
  </Box>
  

}

const ViewsResolver = ({ views, competition, pageIdName, searchParams, setParam, deleteParam, showDSASLogo,  DSASLogoSize, DSASLogoDisplay, redirectUrl, displayText, displayPosition}) => {

  
  const { id } = useParams();

  const paramView = useMemo(() => searchParams.get("view"), [searchParams]);
  const paramEtape = useMemo(() => searchParams.get("etape"), [searchParams]);

  const [activeView, setActiveView] = useState()

  const [activeOnglet, setActiveOnglet] = useState()

  const [navigationsVariables, setNavigationsVariables] = useState(paramEtape ? {etape : paramEtape} : {})

  const [dataTab, setDataTab] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()



  const fetchData = async (tab) => {

    setLoading(true)
    setError(undefined)
    setDataTab(undefined)

    try {
      const {idName} = tab
      const response = await resolveOnglet(idName, navigationsVariables);
      const { data} = response

      setDataTab(data)

    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    if(dataTab || error)
      setLoading(false)
  }, [dataTab, error])

  useEffect(() => {

    // Regarde dans activeView et on verifie que navigationsVariable contienne les navigations de l'activeView
    if(!activeView)
      return

    if(activeView.navigations && !activeView.navigations.every(n => navigationsVariables[n.idName]))
      return

    if(!activeView.navigations && Object.keys(navigationsVariables).length > 0)
      return

    if(activeOnglet)
      fetchData(activeOnglet)

  }, [activeOnglet, navigationsVariables])

  useEffect(() => {
    if(paramView){
      let newActiveView = views.find(v => v.idName === paramView)
      setActiveView(newActiveView)
    }
    else{
      setActiveView(views[0])
    }

  }, [])

  useEffect(() => {
    if(activeView && activeView.idName !== paramView){
      setParam('view', activeView.idName)
      setNavigationsVariables({})
    }
  }, [activeView])

  useEffect(() => {

    if(Object.keys(navigationsVariables).length === 0){
      deleteParam('etape')
    }

    Object.keys(navigationsVariables).map(key => {
      setParam([key], navigationsVariables[key])
    })
    
  }, [navigationsVariables])

  const resolveNavigation = useCallback(async (idNameNavigation) => {
    return await getNavigationsComponent(id, competition._id, competition.idNameConfig, pageIdName, activeView.idName, idNameNavigation)
  }, [activeView, competition])

  const resolveOnglet = useCallback(async (idNameOnglet, external) => {
    return await getOngletComponent(id, competition._id, competition.idNameConfig, pageIdName, activeView.idName, idNameOnglet, external)
  }, [activeView, competition])

  if(!activeView)
    return <CircularProgress/>

  return (
    <Box sx={{m : 1}}>
      <Box sx={{textAlign : 'center', mb : 1}}>
        {views.length > 1 && views.map(view => <Button variant={activeView===view ? 'contained' : 'text'} key={view.idName} onClick={() => setActiveView(view)}>{view.label}</Button>)}
        {views.length === 1 && views.map(view => <Typography  sx={{fontSize : '1.1em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168"}} key={view.idName}>{view.label}</Typography>)}
      </Box>

       {activeView.navigations && <NavigationResolver 
                                    activeView={activeView} 
                                    resolveNavigation={resolveNavigation} 

                                    navigationsVariables={navigationsVariables}
                                    setNavigationsVariables={setNavigationsVariables}

                                    searchParams={searchParams} setParam={setParam} deleteParam={deleteParam}
                                  />
        }

      <OngletResolver onglets={activeView.onglets} resolveOnglet={resolveOnglet} 
                                    navigationsVariables={navigationsVariables}
                                    setNavigationsVariables={setNavigationsVariables}

                                    searchParams={searchParams} setParam={setParam} deleteParam={deleteParam}
                                    activeOnglet={activeOnglet} setActiveOnglet={setActiveOnglet}
                                    dataTab={dataTab} loading={loading} error={error}
                                    showDSASLogo={showDSASLogo}
                                    DSASLogoSize={DSASLogoSize} 
                                    DSASLogoDisplay={DSASLogoDisplay}
                                    redirectUrl={redirectUrl}
                                    displayText={displayText}
                                    displayPosition={displayPosition}
                                    />
    </Box>
    
  )
}

const NavigationResolver = ({activeView, resolveNavigation, navigationsVariables, setNavigationsVariables, searchParams, setParam, deleteParam}) => {

  const [resolvedNavigations, setResolvedNavigations] = useState([])

  useEffect(async () => {

    if(!activeView.navigations)
      return

    const result = []
    const navVariables = {...navigationsVariables}

    for(var navigation of activeView.navigations){
    // Appeler le serveur qu'il me renvoie la liste des navigations
      const response = await resolveNavigation(navigation.idName)
      const { data } = response
      result.push(data)

      Object.keys(data).map(key => {
        if(searchParams.get(key)){
          navVariables[key] = searchParams.get(key)
        }else{
          navVariables[key] = data[key][0].id
        }
        
      })
    }

    setResolvedNavigations(result)
    setNavigationsVariables(navVariables)

  }, [, activeView.navigations])

  const getLibelle = (idName) => {
    const navigationFound = activeView.navigations.find(n => n.idName === idName)

    if(navigationFound)
        return navigationFound.libelle.charAt(0).toUpperCase() + navigationFound.libelle.slice(1);

    return idName.charAt(0).toUpperCase() + idName.slice(1);
  }

  if(!resolvedNavigations)
    return <CircularProgress/>

  return (
    <Box sx={{mt:2}}>
      {resolvedNavigations.map(navigation => Object.keys(navigation).map((key, index) => (
          <SelectInput key={index} label={getLibelle(key)} options={navigation[key]} selected={navigationsVariables[key]} onChange={(event) => setNavigationsVariables(n => ({...n, [key] : event.target.value}))} />
          ))
        )}
    </Box>
  )

}

const SelectInput = ({ label, options, selected = '', onChange }) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select value={selected} onChange={onChange} label={label}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.prefix} : {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const OngletResolver = ({ onglets, 
                          resolveOnglet, 
                          navigationsVariables, 
                          setNavigationsVariables, 
                          searchParams, 
                          setParam, 
                          deleteParam, 
                          activeOnglet, 
                          setActiveOnglet, 
                          dataTab, 
                          loading, 
                          error, 
                          showDSASLogo, 
                          DSASLogoSize,
                          DSASLogoDisplay,
                          redirectUrl, 
                          displayText, 
                          displayPosition }) => {

  const { id } = useParams();

  const paramTab = useMemo(() => searchParams.get("tab"), [searchParams]);

  

  useEffect(() => {
    if(paramTab){
      let newActiveOnglet = onglets.find(o => o.idName === paramTab)
      setActiveOnglet(newActiveOnglet ?? onglets[0])
    }
    else
      setActiveOnglet(onglets[0])

  }, [, onglets])

  

  useEffect(() => {
    if(activeOnglet && activeOnglet.idName !== paramTab){
      setParam('tab', activeOnglet.idName)
    }
  }, [activeOnglet])


  return <React.Fragment>
    <Box sx={{textAlign : 'center'}}>
      { onglets.length > 1 && onglets.map((onglet, idx) => <Button 
                sx={{borderRadius : '20px', mr : 1}}
                variant={activeOnglet===onglet ? 'outlined' : 'text'} 
                key={onglet.idName}
                onClick={() => setActiveOnglet(onglet)}
                disabled={loading}
                >
                  <Typography fontSize={'0.9em'} sx={{display : 'flex', alignItems : 'center'}}><span style={{color : onglet.color, fontSize : "2em", lineHeight : '0.1em', paddingRight : '0.2em'}}>&#x2022;</span> {onglet.label}</Typography>  
                </Button>)}
    </Box>

      {loading && <CircularProgress/>}
      {(error) && <Typography pt={"50px"}>Erreur lors du chargement des résultats.</Typography>}
      {dataTab && <div style={{ padding: 0, margin:0 }}>
          <Outlet context={{ dataComponent: dataTab }} />
        </div>}

      {(redirectUrl && displayText && displayPosition==='bottom') && (
        <Button
        variant="contained"
        style={{
          position: 'absolute',
          backgroundColor: '#4A5168',
          color: '#ffffff',
          fontSize: '0.7em',
          padding: '5px',
          left: '50%',  // Centre horizontalement par rapport au conteneur parent
          transform: 'translateX(-50%)',  // Ajuste pour un centrage parfait
          bottom: '10px' // Ajuste la position verticale si nécessaire
        }}
        onClick={() => window.top.location.href = redirectUrl}
      >
        {displayText}
      </Button>
      )}

      <Footer isDSAS={showDSASLogo} DSASLogoSize={DSASLogoSize} DSASLogoDisplay={DSASLogoDisplay} />

    </React.Fragment>;
}

export default ClassementResolver