import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import RouteManager from './routes/RouteManager.js';
import { useTheme } from './ThemeContext.js';
import { Router, Routes } from 'react-router-dom';

  const App = () => {

    const { theme } = useTheme();
  
    return (
      <ThemeProvider theme={theme}>
            {/*<RouteManagerV2/>*/}
            <RouteManager />
      </ThemeProvider>
    );
  };
  
  export default App;