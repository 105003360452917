import { 
    GAME_FACT_BASKET_COMMENT, 
    GAME_FACT_BASKET_FAULT, 
    GAME_FACT_BASKET_FREETHROW, 
    GAME_FACT_BASKET_STOP, 
    GAME_FACT_BASKET_TECHNICAL,
    GAME_FACT_BASKET_THREEPOINTS, 
    GAME_FACT_BASKET_TIMEOUT, 
    GAME_FACT_BASKET_TWOPOINTS, 
    
    GAME_FACT_COMMENT, 
    
    GAME_FACT_DROP, 
    GAME_FACT_ESSAI, 
    GAME_FACT_FAULT, 
    GAME_FACT_PENALITE, 
    GAME_FACT_TRANSFORMATION, 
    GAME_FACT_REDCARD, 
    GAME_FACT_YELLOWCARD, 
    GAME_FACT_REPLACEMENT, 
    GAME_FACT_RUGBY_STOP, 
    GAME_FACT_SHOOTOUTPENALTY, 
    GAME_FACT_RUGBY_STAR,
    
    GAME_FACT_SOCCER_BUT, 
    GAME_FACT_SOCCER_STAR,
    GAME_FACT_SOCCER_COMMENT, 
    GAME_FACT_SOCCER_FAULT, 
    GAME_FACT_SOCCER_PENALTY, 
    GAME_FACT_SOCCER_REDCARD, 
    GAME_FACT_SOCCER_REPLACEMENT, 
    GAME_FACT_SOCCER_SECONDYELLOWCARD, 
    GAME_FACT_SOCCER_SHOOTOUTPENALTY, 
    GAME_FACT_SOCCER_SHOT, 
    GAME_FACT_SOCCER_STOP, 
    GAME_FACT_SOCCER_YELLOWCARD, 
    GAME_FACT_START, 

//Handball
//Arrêt
GAME_FACT_HANDBALL_STOP,
//But
GAME_FACT_HANDBALL_GOAL,
//Tir au but
GAME_FACT_HANDBALL_SHOOTOUT,
//Penalty
GAME_FACT_HANDBALL_PENALTY,
//Avertissement
GAME_FACT_HANDBALL_YELLOWCARD,
//Exclusion temporaire
GAME_FACT_HANDBALL_TWOMIN_SUSPENSION,
//Disqualification
GAME_FACT_HANDBALL_REDCARD,
//Temps mort
GAME_FACT_HANDBALL_TIMEOUT,
//Balle perdue
GAME_FACT_HANDBALL_TURNOVER,
GAME_FACT_HANDBALL_COMMENT,
GAME_FACT_HANDBALL_SECONDYELLOWCARD,
//TODO handle minutes played / replacement
GAME_FACT_HANDBALL_REPLACEMENT


    
    
    
} from "../gamefacts/types.js";
import { MEDIA_ARTICLE, MEDIA_NOTATION, MEDIA_QUIZZ, MEDIA_SURVEY } from "../medias/types.js";

import MediaQuizz from "../medias/view/MediaQuizz.js";
import MediaSurvey from "../medias/view/MediaSurvey.js";
import MediaNotation from "../medias/view/MediaNotation.js";
import MediaArticle from "../medias/view/MediaArticle.js";

import SoccerBut from "../gamefacts/view/soccer/SoccerBut.js";

import BasketPoint from "../gamefacts/view/basket/BasketPoint.js";

import RugbyEssai from "../gamefacts/view/rugby/RugbyEssai.js";
import RugbyTransformation from "../gamefacts/view/rugby/RugbyTransformation.js";
import RugbyDrop from "../gamefacts/view/rugby/RugbyDrop.js";
import RugbyPenality from "../gamefacts/view/rugby/RugbyPenality.js";

import HandballGoal from "../gamefacts/view/handball/HandballGoal.js";
import HandballPenalty from "../gamefacts/view/handball/HandballPenalty.js";

import GenericRemplacement from "../gamefacts/view/generic/GenericRemplacement.js";
import GenericOther from "../gamefacts/view/generic/GenericOther.js";
import GenericCommentaire from "../gamefacts/view/generic/GenericCommentaire.js";

import butIcon from '../gamefacts/icons/soccer/but_valide.png'
import butInvalideIcon from '../gamefacts/icons/soccer/but_invalide.png'
import penaltyIcon from '../gamefacts/icons/soccer/penalty_valide.png'
import penaltyInvalideIcon from '../gamefacts/icons/soccer/penalty_invalide.png'
import tirIcon from '../gamefacts/icons/soccer/tir.png'
import soccerShoutoutIcon from '../gamefacts/icons/soccer/live_tir_au_but_foot_valider.png'
import soccerShoutoutInvalideIcon from '../gamefacts/icons/soccer/live_tir_au_but_foot_rater.png'


import essaiIcon from '../gamefacts/icons/rugby/essai.png'
import transformationIcon from '../gamefacts/icons/rugby/transformation_valide.png'
import transformationInvalideIcon from '../gamefacts/icons/rugby/transformation_invalide.png'
import dropIcon from '../gamefacts/icons/rugby/drop_valide.png'
import dropInvalideIcon from '../gamefacts/icons/rugby/drop_invalide.png'
import penalityIcon from '../gamefacts/icons/rugby/penality_valide.png'
import penalityInvalideIcon from '../gamefacts/icons/rugby/penality_invalide.png'
import rugbyShoutoutIcon from '../gamefacts/icons/rugby/live_tir_au_but_rugby_valider.png'
import rugbyShoutoutInvalideIcon from '../gamefacts/icons/rugby/live_tir_au_but_rugby_rater.png'

import commentaireIcon from '../gamefacts/icons/generic/commentaire.png'
import changementIcon from '../gamefacts/icons/generic/changement.png'
import cartonjauneIcon from '../gamefacts/icons/generic/carton_jaune.png'
import cartonrougeIcon from '../gamefacts/icons/generic/carton_rouge.png'
import deuxiemecartonjauneIcon from '../gamefacts/icons/generic/deuxieme_carton_jaune.png'
import fauteIcon from '../gamefacts/icons/generic/faute.png'
import starIcon from '../gamefacts/icons/generic/star.png'

import lancerfrancIcon from '../gamefacts/icons/basket/picto_lancer_franc_1pts_basket.png'
import deuxpointsIcon from '../gamefacts/icons/basket/picto_lancer_franc_2pts_basket.png'
import troispointsIcon from '../gamefacts/icons/basket/picto_lancer_franc_3pts_basket.png'
import fauteBasketIcon from '../gamefacts/icons/basket/picto_faute_basket.png'
import fauteTechniqueBasketIcon from '../gamefacts/icons/basket/picto_faute_technique_basket.png'
import timeoutBasketIcon from '../gamefacts/icons/basket/picto_temps_mort_basket.png'

import picto_avertissement_hand from '../gamefacts/icons/handball/picto_avertissement_hand.png'
import picto_balle_perdue_hand from '../gamefacts/icons/handball/picto_balle_perdue_hand.png'
import picto_but_hand from '../gamefacts/icons/handball/picto_but_hand.png'
import picto_but_rate_hand from '../gamefacts/icons/handball/picto_but_rate_hand.png'
import picto_disqualification_hand from '../gamefacts/icons/handball/picto_disqualification_hand.png'
import picto_penalty_hand from '../gamefacts/icons/handball/picto_penalty_hand.png'
import picto_penalty_rater_hand from '../gamefacts/icons/handball/picto_penalty_rater_hand.png'
import picto_penalty_reussi_hand from '../gamefacts/icons/handball/picto_penalty_reussi_hand.png'
import picto_temps_mort_hand from '../gamefacts/icons/handball/picto_temps_mort_hand.png'
import picto_tir_au_but_rate_hand from '../gamefacts/icons/handball/picto_tir_au_but_rate_hand.png'
import picto_tir_au_but_reussi_hand from '../gamefacts/icons/handball/picto_tir_au_but_reussi_hand.png'
import picto_exclusion_hand from '../gamefacts/icons/handball/picto_exclusion_hand.png'

export const itemDetails = [
    {
        type : GAME_FACT_START,
        component : GenericCommentaire,
        icon : commentaireIcon
    },

    {
        type : GAME_FACT_ESSAI,
        component : RugbyEssai,
        icon : [essaiIcon]
    },
    {
        type : GAME_FACT_TRANSFORMATION,
        component : RugbyTransformation,
        icon : [transformationInvalideIcon, transformationIcon]
    },
    {
        type : GAME_FACT_DROP,
        component : RugbyDrop,
        icon : [dropInvalideIcon, dropIcon]
    },
    {
        type : GAME_FACT_PENALITE,
        component : RugbyPenality,
        icon : [penalityInvalideIcon, penalityIcon]
    },
    {
        type : GAME_FACT_SHOOTOUTPENALTY,
        component : RugbyPenality,
        icon : [rugbyShoutoutInvalideIcon, rugbyShoutoutIcon]
    },
    {
        type : GAME_FACT_REPLACEMENT,
        component : GenericRemplacement,
        icon : changementIcon
    },
    {
        type : GAME_FACT_YELLOWCARD,
        component : GenericOther,
        icon : cartonjauneIcon
    },
    {
        type : GAME_FACT_REDCARD,
        component : GenericOther,
        icon : cartonrougeIcon
    },
    {
        type : GAME_FACT_FAULT,
        component : GenericOther,
        icon : fauteIcon
    },
    {
        type : GAME_FACT_RUGBY_STOP,
        component : GenericCommentaire,
        icon : commentaireIcon
    },
    {
        type : GAME_FACT_COMMENT,
        component : GenericCommentaire,
        icon : commentaireIcon
    },
    {
        type : GAME_FACT_RUGBY_STAR,
        component : GenericOther,
        icon : starIcon
    },


    {
        type : GAME_FACT_SOCCER_STAR,
        component : GenericOther,
        icon : starIcon
    },
    {
        type : GAME_FACT_SOCCER_BUT,
        component : SoccerBut,
        icon : [butInvalideIcon, butIcon]
    },
    {
        type : GAME_FACT_SOCCER_SHOT,
        component : GenericOther,
        icon : tirIcon
    },
    {
        type : GAME_FACT_SOCCER_PENALTY,
        component : SoccerBut,
        icon : [penaltyInvalideIcon, penaltyIcon]
    },
    {
        type : GAME_FACT_SOCCER_SHOOTOUTPENALTY,
        component : SoccerBut,
        icon : [soccerShoutoutInvalideIcon, soccerShoutoutIcon]
    },
    {
        type : GAME_FACT_SOCCER_REPLACEMENT,
        component : GenericRemplacement,
        icon : changementIcon
    },
    {
        type : GAME_FACT_SOCCER_YELLOWCARD,
        component : GenericOther,
        icon : cartonjauneIcon
    },
    {
        type : GAME_FACT_SOCCER_SECONDYELLOWCARD,
        component : GenericOther,
        icon : deuxiemecartonjauneIcon
    },
    {
        type : GAME_FACT_SOCCER_REDCARD,
        component : GenericOther,
        icon : cartonrougeIcon
    },
    {
        type : GAME_FACT_SOCCER_FAULT,
        component : GenericOther,
        icon : fauteIcon
    },
    {
        type : GAME_FACT_SOCCER_STOP,
        component : GenericCommentaire,
        icon : commentaireIcon
    },
    {
        type : GAME_FACT_SOCCER_COMMENT,
        component : GenericCommentaire,
        icon : commentaireIcon
    },


    {
        type : GAME_FACT_BASKET_FREETHROW,
        component : BasketPoint,
        icon : [lancerfrancIcon]
    },
    {
        type : GAME_FACT_BASKET_TWOPOINTS,
        component : BasketPoint,
        icon : [deuxpointsIcon]
    },
    {
        type : GAME_FACT_BASKET_THREEPOINTS,
        component : BasketPoint,
        icon : [troispointsIcon]
    },
    {
        type : GAME_FACT_BASKET_FAULT,
        component : GenericOther,
        icon : fauteBasketIcon
    },
    {
        type : GAME_FACT_BASKET_TECHNICAL,
        component : GenericOther,
        icon : fauteTechniqueBasketIcon
    },
    {
        type : GAME_FACT_BASKET_TIMEOUT,
        component : GenericOther,
        icon : timeoutBasketIcon
    },
    {
        type : GAME_FACT_BASKET_STOP,
        component : GenericCommentaire,
        icon : commentaireIcon
    },
    {
        type : GAME_FACT_BASKET_COMMENT,
        component : GenericCommentaire,
        icon : commentaireIcon
    },




    {
        type : GAME_FACT_HANDBALL_STOP,
        component : GenericCommentaire,
        icon : commentaireIcon
    },

    {
        type : GAME_FACT_HANDBALL_GOAL,
        component : HandballGoal,
        icon : [picto_but_rate_hand, picto_but_hand]
    },
    {
        type : GAME_FACT_HANDBALL_SHOOTOUT,
        component : HandballGoal,
        icon : [picto_tir_au_but_rate_hand, picto_tir_au_but_reussi_hand]
    },
    {
        type : GAME_FACT_HANDBALL_PENALTY,
        component : HandballPenalty,
        icon : [picto_penalty_rater_hand, picto_penalty_reussi_hand]
    },
    {
        type : GAME_FACT_HANDBALL_YELLOWCARD,
        component : GenericOther,
        icon : picto_avertissement_hand
    },
    {
        type : GAME_FACT_HANDBALL_SECONDYELLOWCARD,
        component : GenericOther,
        icon : picto_disqualification_hand
    },
    {
        type : GAME_FACT_HANDBALL_REDCARD,
        component : GenericOther,
        icon : picto_disqualification_hand
    },
    {
        type : GAME_FACT_HANDBALL_TWOMIN_SUSPENSION,
        component : GenericOther,
        icon : picto_exclusion_hand
    },
    {
        type : GAME_FACT_HANDBALL_TIMEOUT,
        component : GenericOther,
        icon : picto_temps_mort_hand
    },
    {
        type : GAME_FACT_HANDBALL_TURNOVER,
        component : GenericOther,
        icon : picto_balle_perdue_hand
    },
    {
        type : GAME_FACT_HANDBALL_COMMENT,
        component : GenericCommentaire,
        icon : commentaireIcon
    },



    {
        type : 'default',
        component : GenericCommentaire,
        icon : commentaireIcon
    },



    {
        type : MEDIA_QUIZZ,
        component : MediaQuizz,
    },
    {
        type : MEDIA_SURVEY,
        component : MediaSurvey,
    },
    {
        type : MEDIA_NOTATION,
        component : MediaNotation,
    },
    {
        type : MEDIA_ARTICLE,
        component : MediaArticle
    }
]