import { useCallback, useMemo } from 'react';

export const useActuIdMapping = (live) => {
  const actuIdMapping = useMemo(() => {
    if (!live || !live.actualitesDetails) {
      return {};
    }

    return live.actualitesDetails.reduce((mapping, actuDetail) => {
      const { idActu, name } = actuDetail;
      mapping[idActu] = name;
      return mapping;
    }, {});
  }, [live]);

  const getIdActuFromActuName = useCallback((actuName) => {
    for (const [idActu, mappedTeamName] of Object.entries(actuIdMapping)) {
      if (mappedTeamName === actuName) {
        return idActu;
      }
    }
    return null;
  }, [actuIdMapping]);

  return [actuIdMapping, getIdActuFromActuName];
};