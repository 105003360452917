export const getRootUrl = () => {
    if(process.env.NODE_ENV === 'development'){
        return 'http://localhost:3006';
    }else{
        return process.env.REACT_APP_LIVE_CLIENT_URL;
    }
}

export const getServerUrl = () => {
    if(process.env.NODE_ENV === 'development'){
        return 'http://localhost:5000/collector-api';
    }else{
        return process.env.REACT_APP_COLLECTOR_API_URL;
    }
}