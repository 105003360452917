import GameFactDTO from "./GameFactDTO.js"
import PhaseDTO from "./PhaseDTO.js"
import PlayerDTO from "./PlayerDTO.js"
import StadiumDTO from "./StadiumDTO.js"
import TeamDTO from "./TeamDTO.js"

class SportEventDTO {

    constructor(sportEventData){
        if(!sportEventData)
            return

        const {_id, provider, type = 'team', name, date, phase, stadium, teams, players, gamefacts} = sportEventData

        this._id = _id
        this.provider = provider

        this.type = type

        this.name = name
        this.date = date

        this.stadium = new StadiumDTO(stadium)

        this.phase = new PhaseDTO(phase)

        this.teams = teams?.map(team => new TeamDTO(team))
        this.players = players?.map(player => new PlayerDTO(player))

        this.gamefacts = gamefacts?.map(gamefact => new GameFactDTO(gamefact))
    }

}

export default SportEventDTO