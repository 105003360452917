import { Typography } from "@mui/material"

const TypoTimer = ({timer, lisere}) => {

    return <Typography
    variant="h2"
    component={"span"}
    sx={{
      borderBottom: lisere ? `2px solid ${lisere}` : 'none',
      paddingBottom : '3px'
    }}
  >
    {timer}
  </Typography>
}

export default TypoTimer