const flags = require.context('../../images/flags', false, /\.png$/);

export const getCountryFlag = (country, width = 20) => {

    try {
      const flag = flags(`./${country}.png`);
      return <img src={flag} width={width} style={{maxWidth : '20px'}}></img>;
    } catch (e) {
      return null; // ou retourner une image par défaut
    }
  }