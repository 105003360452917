import React, { useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Button } from '@mui/material';
import medal_gold from '../../images/gamefacts/medal/medal_gold.png'
import medal_silver from '../../images/gamefacts/medal/medal_silver.png'
import medal_bronze from '../../images/gamefacts/medal/medal_bronze.png'

import './classementDetail.css'
import { useLiveResolver } from '../../hooks/useLiveResolver.js';

import nationalities from '../../BDD/nationalities.js'
import SearchFilterDTO from '../../DTO/SearchFilterDTO.js';
import { getCountryFlag } from '../utils/flags.js';
import { getV2LiveById } from '../../../api/index.js';
import LiveDTO from '../../DTO/LiveDTO.js';
import { getImage } from '../utils/images.js';



const ClassementDetail = () => {

  const [queryParams] = useSearchParams();
  const displayLimit = queryParams.get('limit') || undefined;

  const {dataComponent} = useOutletContext();

    if(!dataComponent)
        return <CircularProgress />

    const {component} = dataComponent

    return <DataComponent dataComponent={dataComponent} displayLimit={displayLimit}/>

    

    /*return <Box>
        <Box sx={{display : 'flex', flexDirection : 'column', alignItems : 'center', minHeight : 50, justifyContent : 'space-evenly'}}>
          <Typography sx={{fontSize : '1.1em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168"}}>TABLEAU DES MéDAILLES</Typography>
          <Typography sx={{fontSize : '0.9em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168"}}>{live.name}</Typography>
        </Box>
        
        <Box sx={{display : 'flex', alignItems : 'center', flexDirection : 'column', mt : 3}}>
            <MedalTable classement={liveClassement.entries} />
        </Box>
        
    </Box>*/
};

const DataComponent = ({dataComponent, displayLimit}) => {

  const {live, competitionDetail, viewName, component, columns, data, focus} = dataComponent

  const resolveComponent = (type) => {

    switch(type){
      case 'Table':
        return <TableComponent component={component} columns={columns} data={data} focus={focus} displayLimit={displayLimit}/>
      default : 
        return <CircularProgress />
    }
  }

  if(!data || !data.length)
    return <Typography  pt={"10px"}>Le tableau des médailles sera affiché dès que seront communiqués les premiers résultats officiels.</Typography>
  
  return <Box sx={{display : 'flex', alignItems : 'center', flexDirection : 'column'}}>
            {resolveComponent(dataComponent.component.type)}
        </Box>
        

}

const TableComponent = ({ component, columns, data, focus = [], displayLimit }) => {

  const {lineVisible} = component
  const [toggled, setToggled] = useState(false);



  const resolveColumnTitle = (column) => {
    const {id, title} = column

    if(!title)
        return 

    const {name, logo, alt} = title

    if(name)
        return name
    
    if(logo)
      return getImage(logo, alt)
  }

  const resolveCell = (entry, key) => {
    const column = columns.find(c => c.id === key)
    const value = entry[key]

    if(!column)
      return ''

    let content = ''

    switch(column.format){
      case 'Flag':
        content = getCountryFlag(value)
      break
      default : 
        content = value
    }

    return <TableCell key={key} sx={{...entry.style, ...column.style}}>{content}</TableCell>
  }

  const visibleItems = useMemo(() => {


    if(!data)
      return []

    if(toggled)
      return data

    if (displayLimit){
      return data.slice(0, displayLimit)
    }

    return data.slice(0, lineVisible)

  }, [data, toggled])

  return (
    <React.Fragment style={{ margin: '0px' }}>
      <TableContainer >
        <Table>
          <TableHead>
            {focus && focus.map((f, idx) => <TableRow
                className='focusRank'
                key={"focus_"+idx}
                sx={{ p : 0,
                  borderLeft : '1px solid black', 
                  borderRight : '1px solid black', 
                  borderTop : idx===0 ? '1px solid black' : '', 
                  borderBottom : idx===focus.length-1 ? '1px solid black' : ''}}
              >
                {Object.keys(f)
                .sort((a, b) => {
                  // Recupérer l'ordre de chaque key dans columns
                  const columnA = columns.find(c => c.id === a)
                  const columnB = columns.find(c => c.id === b)

                  if(!columnA || !columnB)
                    return 0

                  return columnA.order - columnB.order
                })
                .map(key => resolveCell(f, key))}
              </TableRow>)
            }
            {focus.length > 0 && <TableRow
                sx={{ height : '1em'}}
              >
              </TableRow>
            }
            <TableRow sx={{pt : 3}}>
              {columns && columns
              .sort((a, b) => a.order - b.order)
              .map(column => <TableCell key={'head_'+column.id} sx={{...column.style}}>{resolveColumnTitle(column)}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleItems?.map((entry, idx) => (
            (displayLimit === undefined || displayLimit === '' || idx < displayLimit) && (
              <TableRow key={'row_' + idx} sx={{ p: 0 }}>
                {Object.keys(entry)
                  .sort((a, b) => {
                    // Recupérer l'ordre de chaque key dans columns
                    const columnA = columns.find(c => c.id === a);
                    const columnB = columns.find(c => c.id === b);
                    if (!columnA || !columnB) return 0;
                    return columnA.order - columnB.order;
                  })
                  .map(key => resolveCell(entry, key))}
              </TableRow>
            )
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*Hide display more button if displayLimit is set*/}
      {((!displayLimit || displayLimit === undefined || displayLimit === '') && (visibleItems.length < data.length || toggled)) && <Button sx={{width : 100, mt : 5, bgcolor : "multiplex.darkblue", fontSize : '0.7em'}} onClick={() => setToggled(!toggled ? true : false)} variant="contained">
            {!toggled ? 'Voir plus' : 'Voir moins'}
          </Button>}
    </React.Fragment>
  )

}

const MedalTable = ({ classement }) => {

    const [sortedClassement, setSortedClassement] = useState([])

    const [toggled, setToggled] = useState(false);
    const defaultVisibleItems = 10

    const [franceEntry, setFranceEntry] = useState(null)

    useEffect(() => {

      let rank = 1; // Le rang initial
      let previousGold = null;
      let previousSilver = null;
      let previousBronze = null;
      let previousRank = 1;

      const sortClassement = classement?.sort((a, b) => {
        const goldA = getMedalCount(a.gameFacts, 'gold');
        const goldB = getMedalCount(b.gameFacts, 'gold');
        if (goldA !== goldB) return goldB - goldA;
      
        const silverA = getMedalCount(a.gameFacts, 'silver');
        const silverB = getMedalCount(b.gameFacts, 'silver');
        if (silverA !== silverB) return silverB - silverA;
      
        const bronzeA = getMedalCount(a.gameFacts, 'bronze');
        const bronzeB = getMedalCount(b.gameFacts, 'bronze');
        return bronzeB - bronzeA;
      }).map((entry, idx) => {
        const gold = getMedalCount(entry.gameFacts, 'gold');
        const silver = getMedalCount(entry.gameFacts, 'silver');
        const bronze = getMedalCount(entry.gameFacts, 'bronze');
      
        if (idx > 0 && gold === previousGold && silver === previousSilver && bronze === previousBronze) {
          rank = previousRank;
        } else {
          if (idx > 0) {
            rank = idx + 1
            previousRank = rank;
          };
        }
      
        previousGold = gold;
        previousSilver = silver;
        previousBronze = bronze;
        
        return {...entry, rank};
      });

      const emptyNationalities = nationalities.sort((a, b) => a.localeCompare(b)).filter(n => !sortClassement.find(t => t._id === n)).map(n => ({
        gameFacts : [], label : n, total : 0, _id : n, rank : '-'
      }))

      const fullClassement = sortClassement.concat(emptyNationalities)
      setFranceEntry(fullClassement.find(c => c._id === 'France'))

    setSortedClassement(fullClassement)

    }, [classement])

    const visibleItems = useMemo(() => {

      if(!sortedClassement)
        return []

      if(toggled)
        return sortedClassement

      return sortedClassement.slice(0, defaultVisibleItems)

    }, [sortedClassement, toggled])
  

   if (!sortedClassement || !sortedClassement.length) {
      return <CircularProgress/>
    }

    return (
      <React.Fragment>
        {
      classement && (<TableContainer>
        <Table aria-label="medal table">
          <TableHead>
          {franceEntry && 
              <TableRow
                className='focusRank'
                key={franceEntry.label}
                sx={{ p : 0, border : '1px solid black'}}
              >
                <TableCell sx={{width : 20 }}>{franceEntry.rank}</TableCell>
                <TableCell sx={{width : 20 }}>{getCountryFlag(franceEntry.label)}</TableCell>
                <TableCell sx={{fontWeight : 'bold' }}>{franceEntry.label}</TableCell>
                <TableCell align="center">{getMedalCount(franceEntry.gameFacts, 'gold')}</TableCell>
                <TableCell align="center">{getMedalCount(franceEntry.gameFacts, 'silver')}</TableCell>
                <TableCell align="center">{getMedalCount(franceEntry.gameFacts, 'bronze')}</TableCell>
                <TableCell align="center">{getTotalMedals(franceEntry.gameFacts)}</TableCell>
              </TableRow>
            }
            <TableRow
                sx={{ height : '1em'}}
              >
              </TableRow>
            <TableRow sx={{pt : 3}}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              {
                //getImage(row.title.logo)
              }
              <TableCell align="center"><img src={medal_gold}></img></TableCell>
              <TableCell align="center"><img src={medal_silver}></img></TableCell>
              <TableCell align="center"><img src={medal_bronze}></img></TableCell>
              <TableCell align="center">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleItems?.map((entry) => (
              <TableRow
                key={entry.label}
                sx={{ p : 0}}
              >
                <TableCell component="th" scope="row">{entry.rank}</TableCell>
                <TableCell>{getCountryFlag(entry.label)}</TableCell>
                <TableCell sx={{fontWeight : entry._id === 'France' ? 'bold' : 'normal' }}>{entry.label}</TableCell>
                <TableCell align="center">{getMedalCount(entry.gameFacts, 'gold') || '-'}</TableCell>
                <TableCell align="center">{getMedalCount(entry.gameFacts, 'silver') || '-'}</TableCell>
                <TableCell align="center">{getMedalCount(entry.gameFacts, 'bronze') || '-'}</TableCell>
                <TableCell align="center">{getTotalMedals(entry.gameFacts)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)
      }
          {(visibleItems.length < sortedClassement.length || toggled) && <Button sx={{width : 100, mt : 5, bgcolor : "multiplex.darkblue", fontSize : '0.7em'}} onClick={() => setToggled(!toggled ? true : false)} variant="contained">
            {!toggled ? 'Voir plus' : 'Voir moins'}
          </Button>}
</React.Fragment>
    );
};
  
  // Fonction pour récupérer le nombre de médailles par type
  const getMedalCount = (gameFacts, medalType) => {
    const fact = gameFacts.find(fact => fact.value === medalType);
    return fact ? fact.count : 0;
  };
  
  // Fonction pour calculer le total de médailles
  const getTotalMedals = (gameFacts) => {
    return gameFacts.reduce((acc, fact) => acc + fact.count, 0);
  };



export default ClassementDetail