import { useParams } from "react-router-dom";
import { useNavigateTo } from "../../hooks/useNavigateTo.js";
import { Box, MenuItem, Select } from "@mui/material";
import DayNavigation from "../navigation/DayNavigation.js";
import MatchsDisplay from "../matchsDisplay/MatchsDisplay.js";
import { useEffect } from "react";
import { getCompetitionClassement } from "../../../../../api/index.js";
import Classement from "../../classement/Classement.js";

const MultiplexCompetition = ({live, liveCompetitionsDays}) => {
    const navigateTo = useNavigateTo();

    const { date } = useParams();

    const queryParameters = new URLSearchParams(window.location.search)
    const competition = queryParameters.get("competition")

    const handleCompetitionChange = (event) => {
        const selectedCompetition = liveCompetitionsDays.find(c => c._id === event.target.value)
        // Construire la nouvelle URL en remplaçant le paramètre 'date'
        const newPath = window.location.pathname.replace(/\/day\/[^/]+/, `/day/${selectedCompetition.activeDay}`);

        navigateTo(newPath, {competition : event.target.value})
    };

    const navigateToNewDate = (newDate) => {
        const newPath = window.location.pathname.replace(/\/day\/[^/]+/, `/day/${newDate}`);

        navigateTo(newPath);
    };

    return <Box>
        <Select
            value={competition}
            onChange={handleCompetitionChange}
            displayEmpty
            fullWidth
            sx={{ minWidth: 200 }}
            >
            <MenuItem value="" disabled>
                Sélectionner une compétition
            </MenuItem>
            {liveCompetitionsDays.map((competition) => (
                <MenuItem key={competition._id} value={competition._id}>
                {competition.name}
                </MenuItem>
            ))}
        </Select>
        <DayNavigation liveDates={liveCompetitionsDays.find(c=> c._id === competition).days} activeDate={date} setActiveDate={navigateToNewDate} />
        <MatchsDisplay live={live} />
        <Classement discipline={live.matchsDetails && live.matchsDetails[0].discipline}/>
    </Box>
}

export default MultiplexCompetition