import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import MatchResolver from '../components/live/match/MatchResolver.js';
import LiveSocketManager from '../components/live/LiveSocketManager.js';
import LiveDatesResolver from '../components/live/LiveDatesResolver.js';
import LiveDetailResolver from '../components/live/LiveDetailResolver.js';
import MultiplexResolver from '../components/live/multiplex/MultiplexResolver.js';
import LiveActuDetailResolver from '../components/live/LiveActuDetailResolver.js';
import ActuResolver from '../components/live/actualite/ActuResolver.js';
import CompetitionView from '../components/live/view/CompetitionView.js';
import PlayerProfileView from '../components/live/view/PlayerProfileView.js';
import TeamProfileView from '../components/live/view/TeamProfileView.js';

import CalendarResolver from '../../v2/pages/calendar/CalendarResolver.js';
import CalendarDetail from '../../v2/pages/calendar/CalendarDetail.js';

import ClassementResolver from '../../v2/pages/classement/ClassementResolver.js';
import ClassementDetail from '../../v2/pages/classement/ClassementDetail.js';

import ResultatDetail from '../../v2/pages/resultats/ResultatDetail.js';

import CalendrierV2View from '../components/live/view/CalendrierV2View.js';
import ClassementV2View from '../components/live/view/ClassementV2View.js';
import ResultatV2View from '../components/live/view/ResultatV2View.js';

const Home = () => {
    return <h1>L'url saisie est inconnue</h1>;
  };

const RouteManager = () => {
    return (
      <Router>
      <Routes>

            <Route exact path="/preview/:id" element={<RedirectOldURL/>} />

            <Route exact path="live/:id/calendar" element={<CalendarResolver/>} >
                <Route path=":date" element={<CalendarDetail />} >
                </Route>
            </Route>

            <Route exact path="live/:id/classement" element={<ClassementResolver viewIdName="classement"/>} >
              <Route index element={<ClassementDetail />} />
            </Route>

            <Route exact path="live/:id/resultat" element={<ResultatDetail/>} >
            </Route>

            {/* Existing DSAS API routes */}
            <Route path="dsas-api/classements-resultats" element={<CompetitionView/>} />
            <Route path="dsas-api/classements-resultats/:competition" element={<CompetitionView/>} />
            <Route path="dsas-api/classements-resultats/:competition/:journee" element={<CompetitionView/>} />
            <Route path="dsas-api/joueur/:joueur" element={<PlayerProfileView/>} />
            <Route path="dsas-api/equipe/:equipe" element={<TeamProfileView/>} />  

            <Route path="dsas-api/:liveid/:competitionid/calendrier" element={<CalendrierV2View/>} />
            <Route path="dsas-api/:liveid/:competitionid/calendrier/:date" element={<CalendrierV2View/>} />
            <Route path="dsas-api/:liveid/:competitionid/tableau-des-medailles-epreuves" element={<ResultatV2View/>} />
            <Route path="dsas-api/:liveid/:competitionid/tableau-des-medailles" element={<ClassementV2View/>} />     


            {<Route exact path=":id" element={<LiveSocketManager/>} >
              <Route path=":mode" element={<LiveDatesResolver />} >
                <Route path=":date" element={<LiveDetailResolver />} >
                    <Route index element={<MultiplexResolver />} />
                    <Route path=":match" element={<MatchResolver />} />
                </Route>
              </Route>
              </Route>}
              
            <Route path="/actualite/:id" element={<LiveSocketManager/>} >
              <Route path="" element={<LiveActuDetailResolver />} >
                  <Route path=":actu" element={<ActuResolver />} />
              </Route>
            </Route>
            
            {/* Route par défaut */}
          <Route path="/*" element={<Home />}>
          </Route>
        </Routes>
        </Router>
    );
};

const RedirectOldURL = () => {
  const navigate = useNavigate();

  const { id } = useParams();

      // Vérifier si l'utilisateur est sur "/preview/:id" et rediriger vers "/:id"
      const handlePreviewRedirect = () => {
          navigate(`/${id}`);
      }

      // Appeler la fonction de redirection dans l'effet initial
      React.useEffect(() => {
        handlePreviewRedirect();
      }, []);

      return ('Redirect ...')
}

export default RouteManager