import React from 'react'
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'


import { createTheme} from '@mui/material';

import AppV1 from './v1/App.js'


import './index.css'
import { ThemeProvider } from './v1/ThemeContext.js';

import { getServerUrl } from "../src/api/infoEnv.js";


var script = document.createElement('script');
script.src = `${getServerUrl()}/bundleView.js`
document.head.appendChild(script);

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const theme = createTheme()

theme.typography.h4 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};

theme.typography.h5 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

theme.typography.body2 = {
  fontSize: '0.8rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

// v0
/*ReactDOM.render(
    
    <Provider store={store}>
        <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
            
        </ThemeProvider>
    </Provider>, 
    document.getElementById('root'));
*/
// v1

ReactDOM.render(
    
      <Provider store={store}>
        <ThemeProvider>
          <AppV1 />
        </ThemeProvider>
            
      </Provider>, 
      document.getElementById('root'));