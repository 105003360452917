import { Box, IconButton, MenuItem, Typography } from "@mui/material";
import React, {useState} from 'react'
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const EntrySelect = ({ choice, handleChoice }) => {
    const [open, setOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState('');
  
    const handleToggle = () => {
      setOpen(!open);
    };
  
    const handleSelect = (entryId) => {
      setSelectedEntry(entryId);
      handleChoice(entryId)
      setOpen(false);
    };
  
    const theme = useTheme();
    return (
      <Box sx={{ width: '100%', mb : 1 }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            maxWidth: '600px',
            borderRadius: '15px 15px 15px 15px',
            backgroundColor: 'grey',
          }}
        >
          <Typography
            component="div"
            variant="mediaH3"
            sx={{
              backgroundColor: 'white',
              height: '50px',
              width: 'calc(100% - 60px)',
              borderRadius: '15px 0px 0px 15px',
              pl : 2,
              display: 'flex',
            alignItems: 'center', // Centrer le contenu verticalement
              '&:hover': {
                cursor : 'pointer',
                '& + button': {
                  backgroundColor: `${theme.palette.media.lightgrey}`, // Modifier la couleur de fond de l'IconButton lors du survol de la Typography
                },
              },
            }}
            onClick={handleToggle}
          >
            {choice.label}
          </Typography>
          <IconButton
            sx={{
              position: 'absolute',
              right: 0,
              height: '50px',
              width: '40px',
              backgroundColor: 'white',
              borderRadius: '0px 15px 15px 0px', // Ajouter un rayon de bordure aux angles haut droit et bas droit
              padding: '4px',
              fontSize: '15px', // Réduire la taille de l'icône
              '&:hover': {
                backgroundColor: `${theme.palette.media.lightgrey}`, // Modifier la couleur de fond de l'IconButton lors du survol de la Typography
              },
            }}
            onClick={handleToggle}
          >
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </IconButton>
  
          {open && (
          <Box
            sx={{
              position: 'absolute',
              top: 'calc(100% + 2px)',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              zIndex: 1,
            }}
          >
            {choice.entries.map((entry) => (
              <MenuItem key={entry.id} onClick={() => handleSelect(entry.id)}>
                {entry.name}
              </MenuItem>
            ))}
          </Box>
        )}
        </Box>
  
        {selectedEntry && (
          <Typography
            variant="mediaH3"
            component={"div"}
            sx={{
              backgroundColor: 'media.lightgrey',
              borderRadius: '4px',
              marginTop: '2px',
              padding: '4px',
              pl : 2
            }}
          >
            {choice.entries.find((entry) => entry.id === selectedEntry)?.name}
          </Typography>
        )}
      </Box>
    );
  };

  export default EntrySelect