import { Box, Divider, Grid, Typography } from "@mui/material"
import { useScoreResolver } from "../../hooks/useScoreResolver.js"
import ImageComponent from "../../dsasComponents/ImageComponent.js"
import MatchScore from "../fragments/MatchScore.js"
import BoxImageComponent from "../../dsasComponents/BoxImageComponent.js"
import { getPlayerById } from "../../sports/genericUtils.js"
import MatchTimerState from "../fragments/MatchTimerState.js"

import picto_shoutout_valid from "../../../../../images/live/icones/generic/live_picto_cercle_valider.png"
import picto_shoutout_invalid from "../../../../../images/live/icones/generic/live_picto_cercle_rater.png"



const MatchHeader = ({match}) => {

    const {homeTeam, awayTeam} = match

    const [homeScore, awayScore, homeScorers, awayScorers, , , homeShoutouts, awayShoutouts] = useScoreResolver(match)

    return (
    <>
    <Grid container sx={{maxWidth:600, justifyContent : 'center', p : 2, bgcolor : 'white', zIndex : 1}}>
        <Grid item xs={12} sx={{height : 300}}>
            <BoxImageComponent src={match.imageUrl} alt={"Match Image"} discipline={match.discipline} />
        </Grid>
    </Grid>
    <Grid container sx={{maxWidth:600, justifyContent : 'center', p : 2, bgcolor : 'white', boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.1)' }}>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-start'}}>
            <ImageComponent src={homeTeam.logoUrl} alt={homeTeam.name} width={40} height={50} />
            <Typography component="div" 
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent : 'center',
                width : 'calc(100% - 40px)',
                background : match.homeTeam.color, 
                color : match.homeTeam.textColor}}
            >{homeTeam.name}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'}}>
            <MatchScore match={match} />
        </Grid>
        <Grid item xs={5} sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end'}}>
            <Typography component="div" 
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent : 'center',
                width : 'calc(100% - 40px)',
                background : match.awayTeam.color, 
                color : match.awayTeam.textColor}}
            >{awayTeam.name}</Typography>
            <ImageComponent src={awayTeam.logoUrl} alt={awayTeam.name} width={40} height={50} />
        </Grid>
    </Grid>
    <Grid container sx={{maxWidth:600, justifyContent : 'center'}}>
        <Grid item xs={6} sx={{paddingTop : 1, display: 'flex', flexDirection : 'column'}}>
                {homeScorers && homeScorers.map((i, idx) => 
                <Box sx={{pl : 7, pr : 7}} key={idx}>
                    <Box key={idx} sx={{display : 'flex', flexDirection : 'row'}}>
                        {i.icone && <img src={i.icone} style={{height : '20px'}} alt={'scorer icone'}></img>}
                        <Typography sx={{fontSize : '0.8em'}} color="text.secondary">{getPlayerById(i.player, match).lastname}&nbsp;&bull;</Typography>
                        {match.discipline === 'basket' || match.discipline === 'handball' ? 
                            <Typography sx={{textIndent : 2, fontSize : '0.8em'}} color="text.secondary">{i.points} {i.suffixeLabel}</Typography> :
                            <Typography sx={{textIndent : 2, fontSize : '0.8em'}} color="text.secondary"> {i.timer}</Typography>
                        }
                    </Box>
                    <Divider/>
                </Box> )}
        </Grid>
        <Grid item xs={6} sx={{paddingTop : 1, display: 'flex', flexDirection : 'column'}}>
                {awayScorers && awayScorers.map((i, idx) => 
                <Box sx={{pr : 7, pl : 7}} key={idx}>
                    <Box key={idx} sx={{display : 'flex', flexDirection : 'row', width : '100%', justifyContent : 'end' }}>
                        {match.discipline === 'basket' || match.discipline === 'handball' ? 
                            <Typography sx={{textIndent : 2, fontSize : '0.8em'}} color="text.secondary"> {i.points} {i.suffixeLabel}&nbsp;</Typography> :
                            <Typography sx={{textIndent : 2, fontSize : '0.8em'}} color="text.secondary"> {i.timer} </Typography>
                        }
                        
                        <Typography sx={{fontSize : '0.8em'}} color="text.secondary">&bull;&nbsp;{getPlayerById(i.player, match).lastname}</Typography>
                        &nbsp;
                        {i.icone && <img src={i.icone} style={{height : '20px'}} alt={'scorer icone'}></img> }
                    </Box>
                    <Divider/>
                </Box> )}
        </Grid>
        {homeShoutouts.length || awayShoutouts.length ? (
            <>
                <Grid item xs={12} sx={{ paddingTop: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontSize: '.6em' }}>T.A.B</Typography>
                </Grid>

                <Grid item xs={6} sx={{ paddingTop: 1, display: 'flex', flexDirection: 'column' }}>
                {homeShoutouts &&
                    homeShoutouts.map((i, idx) => (
                    <Box sx={{ pl: 7, pr: 7 }} key={idx}>
                        <Box
                        key={idx}
                        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                        >
                        <img
                            src={i.isValid ? picto_shoutout_valid : picto_shoutout_invalid}
                            style={{ height: '10px' }}
                            alt={'scorer icone'}
                        ></img>
                        <Typography sx={{ fontSize: '0.8em', ml: 3 }} color="text.secondary">
                            {getPlayerById(i.player, match).lastname}
                        </Typography>
                        </Box>
                        <Divider />
                    </Box>
                    ))}
                </Grid>
                <Grid item xs={6} sx={{ paddingTop: 1, display: 'flex', flexDirection: 'column' }}>
                {awayShoutouts &&
                    awayShoutouts.map((i, idx) => (
                    <Box sx={{ pl: 7, pr: 7 }} key={idx}>
                        <Box
                        key={idx}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'end',
                        }}
                        >
                        <Typography sx={{ fontSize: '0.8em', mr: 3 }} color="text.secondary">
                            {getPlayerById(i.player, match).lastname}
                        </Typography>
                        <img
                            src={i.isValid ? picto_shoutout_valid : picto_shoutout_invalid}
                            style={{ height: '10px' }}
                            alt={'scorer icone'}
                        ></img>
                        </Box>
                        <Divider />
                    </Box>
                    ))}
                </Grid>
            </>
            ) : null}
            <Grid item pt={3}>
                <MatchTimerState match={match} />
            </Grid>
    </Grid>
      </>
      )

}

export default MatchHeader