import { useEffect, useState } from 'react';
import { getV2LiveDetails } from '../../api/index.js';
import { themes } from '../../v1/themes/themes.js';
import { useTheme } from '../../v1/ThemeContext.js';
import LiveDTO from '../DTO/LiveDTO.js';
import SearchFilterDTO from '../DTO/SearchFilterDTO.js';

export const useLiveResolver = (searchFilter) => {

    const { theme, changeTheme } = useTheme();

    const [live, setLive] = useState(undefined)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await getV2LiveDetails(searchFilter);
            const { data } = response
              setLive(new LiveDTO(data[0] ?? {}))
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, [searchFilter]);

    useEffect(() => {
      if(live)
        changeTheme(themes[live.provider] ?? themes['default']);
    }, [live])

      return [live]
}