import { useEffect, useState } from 'react';
import { getViewToken } from '../../../../api/index.js'; // Importez la fonction d'appel à l'API
import { useCallback } from 'react';

const TOKEN_KEY = 'token'; // Clé pour stocker la JWT dans le localStorage
const TOKEN_EXP = 'tokenExp'; // Clé pour stocker la JWT dans le localStorage

const useViewToken = () => {
  const [token, setToken] = useState(localStorage.getItem(TOKEN_KEY));
  const [expiresIn, setExpiresIn] = useState(null);

  useEffect(() => {
    // Vérifier si la JWT est expirée
    if (expiresIn && new Date().getTime() > expiresIn) {
      // La JWT a expiré, supprimez-la du localStorage
      localStorage.removeItem(TOKEN_KEY);
      setToken(null);
      setExpiresIn(null);
    }
  }, [,expiresIn]);

  const fetchToken = useCallback((clientId, clientSecret) => {
    getViewToken(clientId, clientSecret)
      .then((response) => {
        const { access_token, expires_in } = response.data;

        // Stockez la JWT dans le localStorage
        localStorage.setItem(TOKEN_KEY, access_token);
        localStorage.setItem(TOKEN_EXP, expires_in);
        setToken(access_token);

        // Stockez la durée de validité (timestamp de fin d'expiration)
        const expirationTimestamp = new Date().getTime() + expires_in * 1000;
        setExpiresIn(expirationTimestamp);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération de la JWT :', error);
      });
  }, []);

  useEffect(() => {
    if(!localStorage.getItem(TOKEN_KEY))
      fetchToken()
  }, [,fetchToken])

  return { token, expiresIn, fetchToken };
};

export default useViewToken;