import React, { useContext, useEffect, useState } from 'react';
import { getLiveDetails } from "../../../../api/index.js";
import { useDispatch, useSelector } from "react-redux";
import { GET_LIVE_DETAILS } from "../../../../constants/actionTypes.js";
import { ThemeContext, useTheme } from '../../../ThemeContext.js';
import { themes } from '../../../themes/themes.js';

export const useLiveResolver = (id, mode, date, competition) => {

    const dispatch = useDispatch()

    const { theme, changeTheme } = useTheme();

    const live = useSelector((state) => state.live);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await getLiveDetails(id, mode, date, competition);
            dispatch({ type: GET_LIVE_DETAILS, payload: response.data})
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, [id, date, competition, mode]);

    useEffect(() => {
      changeTheme(themes[live.provider] ?? themes['default']);
    }, [live])

      return [live]
}