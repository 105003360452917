import { Grid, Typography} from "@mui/material";
import React, {useMemo} from "react";
import ImageComponent from "../../dsasComponents/ImageComponent.js";
import { useScoreResolver } from "../../hooks/useScoreResolver.js";
import MatchStatusDetail from "../../match/fragments/MatchStatusDetail.js";
import { useMatchStatusResolver } from "../../hooks/useMatchStatusResolver.js";

const MatchDisplay = React.memo(({match}) => {

    const {homeTeam, awayTeam} = match

    const [homeScore, awayScore, , ,isHomeWinner, isAwayWinner] = useScoreResolver(match)

    const {isMatchStarted, isMatchEnded, isChronoRunning, isHalfTime} = useMatchStatusResolver(match)
    
    return (
      <Grid container alignItems="center" height={70}>
        {/* Équipe domicile */}
        <Grid item xs={3.5} textAlign={"center"}>
            <Typography variant={"p"} fontWeight={isMatchEnded && isHomeWinner ? 'bold' : 'normal'}>{homeTeam.name}</Typography>
        </Grid>
        {/* Score */}
        <Grid item xs={5} sx={{
            display : 'flex',
            flexDirection : 'row',
            justifyContent : 'space-between',
            alignItems : 'center',
            position : 'relative'
        }}>
            <ImageComponent src={homeTeam.logoUrl} alt={homeTeam.name} width={30} height={50} />
            <MatchStatusDetail match={match}/>
            <ImageComponent src={awayTeam.logoUrl} alt={awayTeam.name} width={30} height={50} />
        </Grid>

        {/* Équipe extérieure */}
        <Grid item xs={3.5} textAlign={"center"}>
            <Typography variant={"p"} fontWeight={isMatchEnded && isAwayWinner ? 'bold' : 'normal'}>{awayTeam.name}</Typography>
        </Grid>
      </Grid>
      );

}, (prevProps, nextProps) => {
    return prevProps.match.gameFacts.length === nextProps.match.gameFacts.length && prevProps.match.timer === nextProps.match.timer && prevProps.match.period === nextProps.match.period && prevProps.match.statusTimer === nextProps.match.statusTimer && prevProps.match.status === nextProps.match.status;
});

export default MatchDisplay