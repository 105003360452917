import { useEffect, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { getMatchDetail, getPaginedMatchDetail } from "../../../../api/index.js";
import { CircularProgress } from "@mui/material";
import MatchView from "./MatchView.js";
import { useDispatch } from "react-redux";
import { GET_MATCH_DETAILS } from "../../../../constants/actionTypes.js";

const MatchResolver = () => {

    const { match } = useParams();
    const dispatch = useDispatch()

    const {live, getIdMatchFromMatchName} = useOutletContext();

    const idMatch = useMemo(() => {
      return getIdMatchFromMatchName(match)

    }, [getIdMatchFromMatchName, match])

    const matchDetail = live.matchsDetails && live.matchsDetails.find(m => m.idMatch === idMatch);

    useEffect(() => {
      if(!idMatch || matchDetail.isComplete)
        return
        
        const fetchMatchDetail = async () => {
          try {
            const response = await getMatchDetail(idMatch);
            dispatch({ type: GET_MATCH_DETAILS, payload: response.data})
              
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchMatchDetail();
      }, [dispatch, idMatch, live]);

    const loadMore = async () => {

      try {
        const response = await getPaginedMatchDetail(matchDetail.idMatch, matchDetail.pagination)
        dispatch({ type: GET_MATCH_DETAILS, payload: response.data})
          
      } catch (error) {
        console.error(error);
      }
    }

    if (!matchDetail || !matchDetail.isComplete) {
        return <CircularProgress />;
    }

    return <MatchView match={matchDetail} loadMore={loadMore} />
    //return <MatchPreview match={matchDetail} />
}

export default MatchResolver