import { EDIT_COLLECTOR_TIMESTAMP } from '../constants/actionTypes.js'

export default (socket = {}, action) => {
    switch(action.type){
        case EDIT_COLLECTOR_TIMESTAMP :
            const collectorServerTime = action.payload.collectorServerTime

            return {...socket, collectorServerTime}
        default :
            return socket
    }
}