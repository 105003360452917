export const reasonListRugby = [
    {id : 'horsjeu', value : `Hors jeux`},
    {id : 'maulecroule', value : `Maul écroulé`},
    {id : 'meleeecroule', value : `Mêlée écroulée`},
    {id : 'plaquageanticipe', value : `Plaquage anticipé`},
    {id : 'tenirleballon', value : `Tenir le ballon`},
    {id : 'jeudeloyal', value : `Jeu déloyal`},
    {id : 'autres', value : `Autres`},
  ]
  
  export const reasonListSoccer = [
  {id : 'faute', value : `Faute`},
  {id : 'contestation', value : `Contestation`},
  {id : 'simulation', value : `Simulation`},
  {id : 'gaintemps', value : `Gain de temps`},
  {id : 'autres', value : `Autres`},
  ]
  
  export const reasonListSoccerRedCard = [
  {id : 'lastdef', value : `Dernier défenseur`},
  {id : 'dangerous', value : `Faute dangereuse`},
  {id : 'autres', value : `Autres`},
  ]
  
  export const reasonListSoccerReplacement = [
  {id : 'temporaire', value : `Temporaire`},
  {id : 'normal', value : `Normal`},
  {id : 'firstline', value : `Remplacement 1ère ligne`},
  ]
  
  export const typeShotListSoccer = [
    {id : 'cadre', value : `Cadré`},
    {id : 'nocadre', value : `Non cadré`},
    {id : 'poteau', value : `Poteaux`},
    {id : 'transversale', value : `Barre transversale`},
  ]