import { FETCH_ALL_LIVE, CREATE_LIVE, DELETE_LIVE, UPDATE_LIVE } from '../constants/actionTypes.js'

export default (lives = [], action) => {
    switch(action.type){
        case FETCH_ALL_LIVE:
            return action.payload
        case CREATE_LIVE:
            return [...lives, action.payload]
        case UPDATE_LIVE:
            return lives.map((live) => live._id === action.payload._id ? action.payload : live)
        case DELETE_LIVE:
            return lives.filter((live) => live._id !== action.payload)
        default :
            return lives
    }
}