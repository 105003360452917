import CompetitionDTO from "./CompetitionDTO.js"
import DisciplineDTO from "./DisciplineDTO.js"
import PhaseDTO from "./PhaseDTO.js"

class CompetitionDisciplineDTO {

    constructor(competitionDisciplineData){
        if(!competitionDisciplineData)
            return

        const {_id, provider, discipline, phases, competition} = competitionDisciplineData

        this._id = _id
        this.provider = provider
        this.discipline = new DisciplineDTO(discipline)

        this.phases = phases?.map(phase => new PhaseDTO(phase))

        this.competition = new CompetitionDTO(competition)
    }
}

export default CompetitionDisciplineDTO