import {makeStyles} from '@mui/styles'

export default makeStyles(theme => ({
terrainBlock: {
    [theme.breakpoints.up('xs')]: {
      position: 'static',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
    },
},
playerList: {
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  }
},
playerListMobile: {
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
},
player: {
    '&:hover': {
      background: "rgba(230,230,230,0.7)",
      cursor : 'pointer'
    }
  }
}));