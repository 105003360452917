import { FETCH_ALL_COMPETITION, CREATE_COMPETITION, DELETE_COMPETITION, UPDATE_COMPETITION } from '../constants/actionTypes.js'

export default (competitions = [], action) => {
    switch(action.type){
        case FETCH_ALL_COMPETITION:
            return action.payload
        case CREATE_COMPETITION:
            return [... competitions, action.payload]
        case UPDATE_COMPETITION:
            return competitions.map((competition) => competition._id === action.payload._id ? action.payload : competition)
        case DELETE_COMPETITION:
            return competitions.filter((competition) => competition._id !== action.payload)
        default :
            return competitions
    }
}