class CategoryDTO {

    constructor(categoryData){
        if(!categoryData)
            return

        const {_id, name, provider} = categoryData

        this._id = _id
        this.name = name

        this.provider = provider
    }
}

export default CategoryDTO