import { useMemo } from "react";

const buildCompetitionOrder = (orderedStructure, competitionMap) => {
  const sortedCompetitions = Object.entries(orderedStructure)
    .flatMap(([discipline, competitions]) =>
      Object.entries(competitions).map(([competitionId, days]) => ({
        competitionId,
        competitionName: competitionMap.get(competitionId).name
      }))
    )
    .sort((a, b) => a.competitionName.localeCompare(b.competitionName));

  return sortedCompetitions.map((competition) => competition.competitionId);
};

const getMatchDay = (dateTime) => {
  return new Date(dateTime).toLocaleDateString("fr-FR", {
    weekday: "long",
    day: "numeric",
    month: "long",
  })
}

export const useOrderedStructure = (live) => {

    const orderedStructure = useMemo(() => {
        const { competitionOrder, matchsDetails } = live;
        if (!matchsDetails) {
          return []
        }
      
        // Créer une structure pour stocker les matchs ordonnés
        const structure = {};
        const competitionMap = new Map();
      
        // Parcourir les matchs et les ranger dans la structure
        matchsDetails.forEach((match) => {
          const { discipline, competition, day, dateTime } = match;

          if(!competitionMap.get(competition._id))
            competitionMap.set(competition._id, competition)
      
          // Vérifier si la discipline existe déjà dans la structure
          if (!structure[discipline]) {
            structure[discipline] = {};
          }
      
          // Vérifier si la compétition existe déjà dans la discipline
          if (!structure[discipline][competition._id]) {
            structure[discipline][competition._id] = {};
          }
      
          // Vérifier si le jour existe déjà dans la compétition
          if (!structure[discipline][competition._id][day]) {
            structure[discipline][competition._id][day] = {};
          }

          // Vérifier si le date label existe déjà dans le day
          let dateLabel = getMatchDay(dateTime)
          if (!structure[discipline][competition._id][day][dateLabel]) {
            structure[discipline][competition._id][day][dateLabel] = [];
          }
      
          // Ajouter le match au jour correspondant de la compétition
          structure[discipline][competition._id][day][dateLabel].push(match);
        });
      
        const finalCompetitionOrder = (competitionOrder && competitionOrder.length) ? competitionOrder : buildCompetitionOrder(structure, competitionMap);
      
        // Ordonner les compétitions selon l'ordre spécifié dans competitionOrder
        const orderedStructure = {};
      
        finalCompetitionOrder.forEach((competitionId) => {
          Object.entries(structure).forEach(([discipline, competitions]) => {
            if (competitions[competitionId]) {
              if (!orderedStructure[discipline]) {
                orderedStructure[discipline] = {};
              }
      
              // Copier les jours de la compétition
              orderedStructure[discipline][competitionId] = { ...competitions[competitionId] };
            }
          });
        });
      
        return [orderedStructure, competitionMap];
      }, [live])

      return orderedStructure
}