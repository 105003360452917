export const GAME_FACT_START = 'generic_start'
export const GAME_FACT_SOCCER_STOP = 'soccer_stop'
export const GAME_FACT_RUGBY_STOP = 'rugby_stop'

export const GAME_FACT_ESSAI = 'rugby_essai'
export const GAME_FACT_TRANSFORMATION = 'rugby_transformation'
export const GAME_FACT_PENALITE = 'rugby_penalite'
export const GAME_FACT_SHOOTOUTPENALTY = 'rugby_shootoutpenalty'
export const GAME_FACT_DROP = 'rugby_drop'
export const GAME_FACT_FAULT = 'rugby_fault'
export const GAME_FACT_YELLOWCARD = 'rugby_yellowcard'
export const GAME_FACT_REDCARD = 'rugby_redcard'
export const GAME_FACT_REPLACEMENT = 'rugby_replacement'
export const GAME_FACT_REPLACEMENT_IN = 'rugby_replacement_in'
export const GAME_FACT_REPLACEMENT_OUT = 'rugby_replacement_out'
export const GAME_FACT_COMMENT = 'rugby_comment'
export const GAME_FACT_RUGBY_STAR = 'rugby_star'

export const GAME_FACT_SOCCER_BUT = 'soccer_but'
export const GAME_FACT_SOCCER_SHOT = 'soccer_shot'
export const GAME_FACT_SOCCER_PENALTY = 'soccer_penalty'
export const GAME_FACT_SOCCER_SHOOTOUTPENALTY = 'soccer_shootoutpenalty'
export const GAME_FACT_SOCCER_FAULT = 'soccer_fault'
export const GAME_FACT_SOCCER_YELLOWCARD = 'soccer_yellowcard'
export const GAME_FACT_SOCCER_SECONDYELLOWCARD = 'soccer_secondyellowcard'
export const GAME_FACT_SOCCER_REDCARD = 'soccer_redcard'
export const GAME_FACT_SOCCER_REPLACEMENT = 'soccer_replacement'
export const GAME_FACT_SOCCER_REPLACEMENT_IN = 'soccer_replacement_in'
export const GAME_FACT_SOCCER_REPLACEMENT_OUT = 'soccer_replacement_out'
export const GAME_FACT_SOCCER_COMMENT = 'soccer_comment'
export const GAME_FACT_SOCCER_STAR = 'soccer_star'

export const GAME_FACT_BASKET_TWOPOINTS = 'basket_two_points'
export const GAME_FACT_BASKET_THREEPOINTS = 'basket_three_points'
export const GAME_FACT_BASKET_FREETHROW = 'basket_free_throw'
export const GAME_FACT_BASKET_FAULT = 'basket_fault'
export const GAME_FACT_BASKET_TECHNICAL = 'basket_technical'
export const GAME_FACT_BASKET_TIMEOUT = 'basket_timeout'
export const GAME_FACT_BASKET_COMMENT = 'basket_comment'
export const GAME_FACT_BASKET_STOP = 'basket_stop'

//Handball
//Arrêt
export const GAME_FACT_HANDBALL_STOP = 'handball_stop'
//Tirs au But - 'jet de sept mètres'
export const GAME_FACT_HANDBALL_GOAL = 'handball_goal'
//Tir au but
export const GAME_FACT_HANDBALL_SHOOTOUT = 'handball_shootout'
//Penalty
export const GAME_FACT_HANDBALL_PENALTY = 'handball_penalty'
//Avertissement
export const GAME_FACT_HANDBALL_YELLOWCARD = 'handball_yellowcard'
export const GAME_FACT_HANDBALL_SECONDYELLOWCARD = 'handball_secondyellowcard'
//Exclusion temporaire
export const GAME_FACT_HANDBALL_TWOMIN_SUSPENSION = 'handball_two_min_suspension'
//Disqualification
export const GAME_FACT_HANDBALL_REDCARD = 'handball_redcard'
//Temps mort
export const GAME_FACT_HANDBALL_TIMEOUT = 'handball_timeout'
//Balle perdue
export const GAME_FACT_HANDBALL_TURNOVER = 'handball_turnover'
export const GAME_FACT_HANDBALL_COMMENT = 'handball_comment'