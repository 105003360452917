import { useEffect, useState } from "react"
import { GAME_FACT_BASKET_FAULT, GAME_FACT_BASKET_FREETHROW, GAME_FACT_BASKET_TECHNICAL, GAME_FACT_BASKET_THREEPOINTS, GAME_FACT_BASKET_TWOPOINTS, GAME_FACT_DROP, GAME_FACT_ESSAI, GAME_FACT_FAULT, GAME_FACT_HANDBALL_GOAL, GAME_FACT_HANDBALL_PENALTY, GAME_FACT_HANDBALL_REDCARD, GAME_FACT_HANDBALL_YELLOWCARD, GAME_FACT_PENALITE, GAME_FACT_REDCARD, GAME_FACT_REPLACEMENT, GAME_FACT_REPLACEMENT_IN, GAME_FACT_REPLACEMENT_OUT, GAME_FACT_SOCCER_BUT, GAME_FACT_SOCCER_FAULT, GAME_FACT_SOCCER_PENALTY, GAME_FACT_SOCCER_REDCARD, GAME_FACT_SOCCER_REPLACEMENT, GAME_FACT_SOCCER_YELLOWCARD, GAME_FACT_TRANSFORMATION, GAME_FACT_YELLOWCARD } from '../../../../v1/components/live/gamefacts/types.js'

import essai_reussi from '../../../../v1/components/live/gamefacts/icons/rugby/essai.png'
import drop_reussi from '../../../../v1/components/live/gamefacts/icons/rugby/drop_valide.png'
import transformation_reussi from '../../../../v1/components/live/gamefacts/icons/rugby/transformation_valide.png'
import penalite_reussi from '../../../../v1/components/live/gamefacts/icons/rugby/penality_valide.png'

import fault from '../../../../v1/components/live/gamefacts/icons/generic/faute.png'
import yellowcard from '../../../../v1/components/live/gamefacts/icons/generic/carton_jaune.png'
import redcard from '../../../../v1/components/live/gamefacts/icons/generic/carton_rouge.png'
import changement_in from '../../../../v1/components/live/gamefacts/icons/generic/joueur_entrant.png'
import changement_out from '../../../../v1/components/live/gamefacts/icons/generic/joueur_sortant.png'
import changement from '../../../../v1/components/live/gamefacts/icons/generic/changement.png'

import lancer_franc from '../../../../v1/components/live/gamefacts/icons/basket/picto_lancer_franc_1pts_basket.png'
import deux_points from '../../../../v1/components/live/gamefacts/icons/basket/picto_lancer_franc_2pts_basket.png'
import trois_points from '../../../../v1/components/live/gamefacts/icons/basket/picto_lancer_franc_3pts_basket.png'

import live_butvalide from '../../../../v1/components/live/gamefacts/icons/soccer/but_valide.png'
import live_penaltyreussi from '../../../../v1/components/live/gamefacts/icons/soccer/penalty_valide.png'

// handball
import picto_avertissement_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_avertissement_hand.png'
import picto_disqualification_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_disqualification_hand.png'
import picto_balle_perdue_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_balle_perdue_hand.png'
import picto_but_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_but_hand.png'
import picto_but_rate_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_but_rate_hand.png'

import picto_penalty_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_penalty_hand.png'
import picto_penalty_rater_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_penalty_rater_hand.png'
import picto_penalty_reussi_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_penalty_reussi_hand.png'

import picto_temps_mort_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_temps_mort_hand.png'
import picto_tir_au_but_rate_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_tir_au_but_rate_hand.png'
import picto_tir_au_but_reussi_hand from '../../../../v1/components/live/gamefacts/icons/handball/picto_tir_au_but_reussi_hand.png'


export function getIconeMap(){
    const iconeMap = new Map()
    iconeMap.set(GAME_FACT_ESSAI, essai_reussi)
    iconeMap.set(GAME_FACT_TRANSFORMATION, transformation_reussi)
    iconeMap.set(GAME_FACT_DROP, drop_reussi)
    iconeMap.set(GAME_FACT_PENALITE, penalite_reussi)
    iconeMap.set(GAME_FACT_FAULT, fault)
    iconeMap.set(GAME_FACT_YELLOWCARD, yellowcard)
    iconeMap.set(GAME_FACT_REDCARD, redcard)
    iconeMap.set(GAME_FACT_REPLACEMENT, changement)
    

    iconeMap.set(GAME_FACT_SOCCER_BUT, live_butvalide)
    iconeMap.set(GAME_FACT_SOCCER_PENALTY, live_penaltyreussi)
    iconeMap.set(GAME_FACT_SOCCER_FAULT, fault)
    iconeMap.set(GAME_FACT_SOCCER_YELLOWCARD, yellowcard)
    iconeMap.set(GAME_FACT_SOCCER_REDCARD, redcard)
    iconeMap.set(GAME_FACT_SOCCER_REPLACEMENT, changement)

    iconeMap.set(GAME_FACT_BASKET_FREETHROW, lancer_franc)
    iconeMap.set(GAME_FACT_BASKET_TWOPOINTS, deux_points)
    iconeMap.set(GAME_FACT_BASKET_THREEPOINTS, trois_points)
    iconeMap.set(GAME_FACT_BASKET_FAULT, fault)
    iconeMap.set(GAME_FACT_BASKET_TECHNICAL, fault)

    iconeMap.set(GAME_FACT_REPLACEMENT_IN, changement_in)
    iconeMap.set(GAME_FACT_REPLACEMENT_OUT, changement_out)

    iconeMap.set(GAME_FACT_HANDBALL_GOAL, picto_but_hand)
    iconeMap.set(GAME_FACT_HANDBALL_PENALTY, picto_penalty_reussi_hand)
    iconeMap.set(GAME_FACT_HANDBALL_YELLOWCARD, picto_avertissement_hand)
    iconeMap.set(GAME_FACT_HANDBALL_REDCARD, picto_disqualification_hand)
    // Manque tir au but ?
    //iconeMap.set(GAME_FACT_HANDBALL_GOAL, picto_tir_au_but_reussi_hand)

    return iconeMap
}

export function useInfoPlayer(player, live){
    const [isInfoVisible, setIsInfoVisible] = useState(false)

    const [gameFacts, setGameFacts] = useState(new Map())

    const iconeMap = getIconeMap()

    useEffect(() => {

        let playerGameFacts = live.gameFacts.reduce((acc, g) => {
            switch(g.type){
                case GAME_FACT_ESSAI:
                case GAME_FACT_SOCCER_BUT:
                case GAME_FACT_SOCCER_PENALTY:
                case GAME_FACT_HANDBALL_GOAL:
                case GAME_FACT_HANDBALL_PENALTY:
                    if(g.detail.buteur === player._id && (g.detail.butSucced===undefined || g.detail.butSucced===1)){
                        if(!acc.get(g.type))
                            acc.set(g.type, [])
                        acc.get(g.type).push(g)
                    }  
                break;
                case GAME_FACT_BASKET_FREETHROW:
                case GAME_FACT_BASKET_TWOPOINTS:
                case GAME_FACT_BASKET_THREEPOINTS:
                    if(g.detail.player === player._id){
                        if(!acc.get(g.type))
                            acc.set(g.type, [])
                        acc.get(g.type).push(g)
                    }  
                break;
                case GAME_FACT_TRANSFORMATION:
                    if(g.detail.transformer === player._id && (g.detail.transformSucced===undefined || g.detail.transformSucced===1)){
                        if(!acc.get(GAME_FACT_TRANSFORMATION))
                            acc.set(GAME_FACT_TRANSFORMATION, [])
                        acc.get(GAME_FACT_TRANSFORMATION).push(g)
                    }  
                break;
                case GAME_FACT_DROP:
                    if(g.detail.droper === player._id && (g.detail.dropSucced===undefined || g.detail.dropSucced===1)){
                        if(!acc.get(GAME_FACT_DROP))
                            acc.set(GAME_FACT_DROP, [])
                        acc.get(GAME_FACT_DROP).push(g)
                    }  
                break;
                case GAME_FACT_PENALITE:
                    if(g.detail.penaliter === player._id && (g.detail.penalitySucced===undefined || g.detail.penalitySucced===1)){
                        if(!acc.get(GAME_FACT_PENALITE))
                            acc.set(GAME_FACT_PENALITE, [])
                        acc.get(GAME_FACT_PENALITE).push(g)
                    }  
                break;
                case GAME_FACT_FAULT:
                case GAME_FACT_SOCCER_FAULT:
                case GAME_FACT_BASKET_FAULT:
                case GAME_FACT_BASKET_TECHNICAL:
                    if(g.detail.player === player._id){
                        if(!acc.get(g.type))
                            acc.set(g.type, [])
                        acc.get(g.type).push(g)
                    }  
                break;
                case GAME_FACT_YELLOWCARD:
                case GAME_FACT_SOCCER_YELLOWCARD:
                case GAME_FACT_HANDBALL_YELLOWCARD:
                    if(g.detail.player === player._id){
                        if(!acc.get(g.type))
                            acc.set(g.type, [])
                        acc.get(g.type).push(g)
                    }  
                break;
                case GAME_FACT_REDCARD:
                case GAME_FACT_SOCCER_REDCARD:
                case GAME_FACT_HANDBALL_REDCARD:
                    if(g.detail.player === player._id){
                        if(!acc.get(g.type))
                            acc.set(g.type, [])
                        acc.get(g.type).push(g)
                    }  
                break;
                case GAME_FACT_REPLACEMENT:
                case GAME_FACT_SOCCER_REPLACEMENT:
                    if(g.detail.inplayer === player._id){
                        if(!acc.get(GAME_FACT_REPLACEMENT_IN))
                            acc.set(GAME_FACT_REPLACEMENT_IN, [])
                        acc.get(GAME_FACT_REPLACEMENT_IN).push(g)
                    }else if(g.detail.outplayer === player._id){
                        if(!acc.get(GAME_FACT_REPLACEMENT_OUT))
                            acc.set(GAME_FACT_REPLACEMENT_OUT, [])
                        acc.get(GAME_FACT_REPLACEMENT_OUT).push(g)
                    }   
                break;
                default:
            }

            return acc
        }, new Map())

        for (const key of playerGameFacts.keys()) {
            let tabToSort = playerGameFacts.get(key)

            tabToSort.sort((a, b) => a.detail.timer - b.detail.timer)
          }

        setGameFacts(playerGameFacts)
    }, [player, live])

    return [isInfoVisible, setIsInfoVisible, gameFacts, iconeMap]
}