import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";
import { convertToHHMM } from "../../medias/utils.js";

const BoxMedia = ({media, children}) => {

    const {isPin} = media

    const theme = useTheme();

    return  <Box
              sx={{
                position : 'relative',
                padding: '16px',
                ...(isPin && {
                  borderRadius : '20px',
                  backgroundColor: `${theme.palette.pinBox.mainBackground}`,
                  margin: '0 20px',
                  marginBottom : '30px'
                })
              }}
            >
              {isPin && 
              <Box sx={{
                position : 'absolute', 
                top : '-15px', 
                right : '-15px',
                width: "40px",
                height : "40px",
                borderRadius: 40,
                border : `4px solid ${theme.palette.pinBox.mainBackground}`,
                background: `${theme.palette.media.lightgrey}`,
                transform: 'rotate(30deg)',

                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center'
              }}>
                <FontAwesomeIcon icon={faThumbtack}  />
              </Box>
              
              }
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                {!isPin && <React.Fragment>
                  <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faClock} />
                    </Box>
                    <Box
                      sx={{
                        padding: '8px',
                      }}
                    >
                      <Typography variant="h3" sx={{lineHeight : 1.5}}>{convertToHHMM(media.creation)}</Typography>
                    </Box>
                </React.Fragment>}
                  
                </Box>
                {children}
            </Box>
}

export default BoxMedia