import { CREATE_ARTICLE, DELETE_ARTICLE, UPDATE_ARTICLE } from '../constants/actionTypes.js'

export default (articles = [], action) => {
    switch(action.type){
        case CREATE_ARTICLE:
            return [...articles, action.payload]
        case UPDATE_ARTICLE:
            return articles.map(gf => gf._id === action.payload._id ? action.payload : gf)
        case DELETE_ARTICLE:
            return articles.filter(gf => gf._id !== action.payload)
        default :
            return articles
    }
}