import { useCallback, useEffect, useState } from "react"
import * as api from '../api'

export const useFetch = (url) => {

    const [state, setState] = useState({
        items : [],
        loading : true
    })

    const cleanDatas = useCallback(() => {
        setState(s => ({
            items : [],
            loading : false
        }))
    }, [])

    useEffect(() => {
        if(!url)
            return
            
        (async function () {
            const {data} = await url()
            const responseData = data

            if(responseData){
                setState({
                    items: responseData,
                    loading : false
                })
            } else {
                setState(s => ({
                    ...s,
                    loading : false
                }))
            }
        })()

    }, [url])

    return [state.items, state.loading, cleanDatas]
}