import { useMemo } from "react"
import { getPeriod } from "../sports/genericUtils.js"

export const useMatchStatusResolver = (match) => {

    const isMatchStarted = useMemo(() => {

        return match.timer !== 0 || match.period !== 0 || match.statusTimer === 'on'
    }, [match])

    const isMatchEnded = useMemo(() => {

        return (['soccer', 'rugby'].includes(match.discipline) && (match.timer === 0 && match.statusTimer === 'off' && (match.period === 2 || match.period === 6)))
        || match.status === 'end'

    }, [match])

    const isChronoRunning = useMemo(() => {

        return match.statusTimer === 'on'

    }, [match.statusTimer])

    const isTaB = useMemo(() => {
        return (['soccer', 'rugby'].includes(match.discipline) && match.period === 5)
        ||
        (['handball'].includes(match.discipline) && getPeriod(match.period, match.discipline).periodPhase === 'so')

    }, [match.statusTimer, match.period])

    const isHalfTime = useMemo(() => {

        return (['soccer', 'rugby'].includes(match.discipline) && match.statusTimer === 'off' && match.timer === 0 && (match.period === 1 || match.period === 4))
        || (['handball'].includes(match.discipline) && [2,6,8].includes(match.period))

    }, [match])

    const isQuartTime = useMemo(() => {

        return ['basket'].includes(match.discipline) && match.statusTimer === 'off' && match.timer === 0

    }, [match])

    const isEndReglementTime = useMemo(() => {
        return (['soccer', 'rugby'].includes(match.discipline) && match.statusTimer === 'off' && match.timer === 0 && (match.period === 3))
        || (['handball'].includes(match.discipline) && match.period === 4)
    })

    const isMatchReported = useMemo(() => {

        return match.status === 'reported'

    }, [match])

    return {    isMatchStarted, 
                isMatchEnded, 
                isChronoRunning, 
                isHalfTime, 
                isQuartTime,
                isMatchReported, 
                isEndReglementTime,
                isTaB
            }
}