import { Box } from "@mui/material"

const ImgShadow = ({src, width, height}) => {

    return <Box
    sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
    >
        <img src={src} alt="But Icon" style={{ width: `${width - 5}px`, height: `${height - 5}px`, boxShadow: 'none' }} />
    </Box>
}

export default ImgShadow