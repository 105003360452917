import CompetitionDisciplineDTO from "./CompetitionDisciplineDTO.js"
import DisciplineDTO from "./DisciplineDTO.js"
import PhaseDTO from "./PhaseDTO.js"
import SportEventDTO from "./SportEventDTO.js"

class CompetitionDTO {

    constructor(competitionData){
        if(!competitionData)
            return

        const {_id, provider, name, competitionsDisciplines} = competitionData

        this._id = _id
        this.provider = provider
        this.name = name

        this.competitionsDisciplines = competitionsDisciplines?.map(competitionDiscipline => new CompetitionDisciplineDTO(competitionDiscipline))
    }

}

export default CompetitionDTO