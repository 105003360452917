const CustomDivider = ({disabled = false}) => {

    return (<div style={{ 
        "background-image": "linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0.5) 0%)",
        ...(disabled && {"background-image": "none"}),
        "background-position": "center center",
        "background-size": "9px 1px",
        "background-repeat": "repeat-x",
        "padding" : "10px 50px",
        "margin" : "0 20px",
    }} />
    )
}

export default CustomDivider