import DisciplineDTO from "./DisciplineDTO.js"
import PlayerDTO from "./PlayerDTO.js"

class TeamDTO {

    constructor(teamData){
        if(!teamData)
            return

        const {_id, name, nationality, disciplines, players, provider} = teamData

        this._id = _id
        this.name = name
        this.nationality = nationality

        this.disciplines = disciplines?.map(discipline => new DisciplineDTO(discipline))

        this.players = players?.map(player => new PlayerDTO(player))

        this.provider = provider
    }
}

export default TeamDTO