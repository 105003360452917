import { getPlayerById, getTeamInfoById, getVisualTimer } from "../../../sports/genericUtils.js"
import GenericScore from "../generic/GenericScore.js";

const RugbyPenality = ({item, match, icon}) => {

    const {text, title, team, penaliter, penalitySucced, period, timer} = item.detail
    
    const visualTimer = getVisualTimer(timer, period, match.discipline)
    const teamDetail = getTeamInfoById(team, match)
    const playerDetail = getPlayerById(penaliter, match)

    return (
      <GenericScore 
      teamDetail={teamDetail} 
      playerDetail={playerDetail} 
      visualTimer={visualTimer} 
      icon={icon}
      title={title}
      text={text}
      succed={penalitySucced}
      />
      );
}

export default RugbyPenality