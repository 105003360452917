import { Typography } from "@mui/material"
import { useMatchStatusResolver } from "../../hooks/useMatchStatusResolver.js"
import { useMemo } from "react"
import { getTotalTimer } from "../../sports/genericUtils.js"

const MatchTimerState = ({match}) => {

    const {isMatchStarted, isMatchEnded, isChronoRunning, isHalfTime, isQuartTime, isMatchReported, isEndReglementTime, isTaB} = useMatchStatusResolver(match)

    const matchTimer = useMemo(() => {

      const totalTimer = getTotalTimer(match.period, match.timer, match.discipline)

      // 👇️ get number of full minutes
      const minutes = Math.floor(totalTimer / 60);

      return (minutes+1)+"'"

  }, [match.timer, match.period, match.statusTimer])

    const matchActualState = useMemo(() => {
      if(isMatchEnded)
        return 'Fin du match'

      if(isTaB)
        return 't.a.b'

      if(isMatchReported)
        return 'Reporté'
        
      if(isQuartTime)
        return 'Quart-temps'

      if(isHalfTime)
        return 'Mi-temps'

      if(isEndReglementTime)
        return 'Fin du temps réglementaire'

      return matchTimer
  }, [isHalfTime, isMatchEnded, matchTimer, isMatchReported, match.period, isEndReglementTime])

  const isMatchNavigable = (match) => {
    const homeTeamSubscription = match.homeTeam.subscription ?? 1
    const awayTeamSubscription = match.awayTeam.subscription ?? 1

    return homeTeamSubscription < 4 || awayTeamSubscription < 4
  }

  if(!isMatchNavigable(match))
        return null

    if(isMatchReported)
    return <Typography
      variant="p"
      component="div"
      sx={{
        bgcolor: "multiplex.mitemps",
        color : "white",
        p: "2px 5px",
      }}
    >
      {`${matchActualState}`}
    </Typography>

    if(isMatchStarted && !isMatchEnded)
        return <Typography
          variant="p"
          component="div"
          sx={{
            bgcolor: isChronoRunning ? "multiplex.chrono" : "multiplex.mitemps",
            color : "white",
            p: "2px 5px",
          }}
        >
          {`${matchActualState}`}
        </Typography>

    if(isMatchEnded)
      return <Typography
        variant="p"
        component="div"
        sx={{
          bgcolor: "multiplex.lightgrey",
          color : "multiplex.main",
          p: "2px 5px",
        }}
      >
        {`${matchActualState}`}
      </Typography>

      return ''

}

export default MatchTimerState