import { FETCH_ALL_TEAM, CREATE_TEAM, DELETE_TEAM, UPDATE_TEAM } from '../constants/actionTypes.js'

export default (teams = [], action) => {
    switch(action.type){
        case FETCH_ALL_TEAM:
            return action.payload
        case CREATE_TEAM:
            return [... teams, action.payload]
        case UPDATE_TEAM:
            return teams.map((team) => team._id === action.payload._id ? action.payload : team)
        case DELETE_TEAM:
            return teams.filter((team) => team._id !== action.payload)
        default :
            return teams
    }
}