export const getClosestDate = (dates) => {
    if(!dates || !dates.length)
        return ''

    const currentDate = new Date();
    const today = currentDate.toISOString().split('T')[0];
    
    const sortedDates = dates.sort();
    
    // Recherche de la date d'aujourd'hui
    if (dates.includes(today)) {
        return today;
    }
    
    // Recherche de la date supérieure la plus proche
    for (let i = 0; i < sortedDates.length; i++) {
        if (sortedDates[i] > today) {
        return sortedDates[i];
        }
    }
    
    // Aucune date supérieure, retourne la date inférieure la plus proche (dernière date du tableau)
    return sortedDates[sortedDates.length - 1];
}