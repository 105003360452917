import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import BoxMedia from "../../items/fragments/BoxMedia.js";
import { addVotes } from "../../../../../actions/survey.js";
import BarChart from "../../items/fragments/BarChart.js";
import EntrySelect from "../../items/fragments/EntrySelect.js";

const MediaSurvey = ({item, match}) => {

    const dispatch = useDispatch()
    const surveyVoted = useSelector((state) => state.survey)
    const [localVotes, setLocalVotes] = useState({})

    const {_id, creation, question, choices, nbVotes, options} = item
    const {validate, numberOfChoices} = options

    // CHECK VOTE
    function hasVotedFor(choiceId){
        return surveyVoted[_id+"_"+choiceId] !== undefined
    }

    function hasUniqueVotedFor(){

        return choices.map((c, idx) => {
            return surveyVoted[_id+"_"+idx] !== undefined
        }).some(s => s)
    }

    // VOTE
    const handleMultipleVote = (idxChoice, entryId) => {
      setLocalVotes(s => ({...s, [idxChoice] : entryId}))
    }

    const handleUniqueVote = (idxChoice, entryId) => {

        if(hasUniqueVotedFor())
          return

          if(localVotes[idxChoice]){
            delete localVotes[idxChoice]
            setLocalVotes(s => ({...localVotes}))
          }
          else
            setLocalVotes(s => ({...s, [idxChoice] : entryId}))
    }

    useEffect(() => {
        // Validation automatique
        if(!validate){
            if(choices[0].entries.length === 1 && !hasUniqueVotedFor())
              validateQuizz()
            if(choices[0].entries.length > 1 && !hasVotedFor(0))
              validateQuizz()
        }

    }, [localVotes])

    const isValidQuizz = () => Object.keys(localVotes).length === numberOfChoices

    const validateQuizz = () => {
        if(isValidQuizz()){
          const votes = Object.keys(localVotes).map(key => {
            return {idChoice : key, idEntry : localVotes[key]}
          })

          dispatch(addVotes(_id, votes))
        }    
    }

    if(choices[0].entries.length === 1)
      return (
          <BoxMedia media={item}>
            <Typography variant="mediaH2" component="div">{question}</Typography>

            <SingleQuestionQuizz quizz={item} isSelectedChoice={(choice, idx) => localVotes[idx] === choice.entries[0].id} localVotes={localVotes} hasVoted={hasUniqueVotedFor} handleVote={handleUniqueVote} isValidQuizz={isValidQuizz} validateQuizz={validateQuizz}/>
          </BoxMedia>
        );

    if(choices[0].entries.length > 1)
      return (
        <BoxMedia media={item}>
            <Typography variant="mediaH2" component="div">{question}</Typography>
            <MultipleQuestionQuizz quizz={item} hasVoted={() => hasVotedFor(0)} isSelectedChoice={(idx, entryId) => localVotes[idx] === entryId} handleVote={handleMultipleVote} isValidQuizz={isValidQuizz} validateQuizz={validateQuizz}/>
          </BoxMedia>
        );
}

const SingleQuestionQuizz = ({ quizz, isSelectedChoice, hasVoted, handleVote, isValidQuizz, validateQuizz }) => {
  const { _id, creation, question, choices, nbVotes, options } = quizz;
  const {validate, numberOfChoices } = options

  const isCorrectAnswer = (choice) => choice.entries[0].isCorrect;

  const useStyles = makeStyles((theme) => ({
    choiceContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      padding: '6px',
      borderRadius: '15px',
      maxWidth: '600px',
      border: `1px solid ${theme.palette.media.lightgrey}`,
      position: 'relative',
    },
    selectedChoice: {
      border: `1px solid ${theme.palette.media.darkblue}`,
    },
    correctChoice: {
      border: `1px solid ${theme.palette.media.green}`,
      color: theme.palette.media.green,
    },
    incorrectChoice: {
      border: `1px solid ${theme.palette.media.red}`,
      color: theme.palette.media.red,
    },
    disabledRadio: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    enabledRadio: {},
    checkIcon: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate(50%, -50%)',
      fontSize: '24px',
      backgroundColor: '#FFFFFF',
      padding: '4px',
      borderRadius: '50%',
    },
  }));

  const classes = useStyles();

  if(!hasVoted())
    return (
      <>
      <Box sx={{ marginTop: '16px', pl: 2 }}>
        <RadioGroup name={_id}>
          {choices.map((choice, idx) => (
            <Box
              key={idx}
              className={`${classes.choiceContainer} ${
                isSelectedChoice(choice, idx) ? classes.selectedChoice : ''}`}
            >
              <FormControlLabel
                checked={isSelectedChoice(choice, idx)}
                value={choice.entries[0].id}
                control={
                  <Radio
                    color='quizzdarkblue'
                    style={{ marginRight: '8px' }}
                    onClick={(e) => handleVote(idx, e.target.value)}
                  />
                }
                label={choice.entries[0].name}
                className={classes.enabledRadio}
              />

              {isSelectedChoice(choice, idx) && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={classes.checkIcon}
                  style={{
                    color: 'quizzdarkblue'
                  }}
                />
              )}
            </Box>
          ))}
        </RadioGroup>
      </Box>

      {validate && <ValidateButton isValid={isValidQuizz} handleValidate={validateQuizz} numberOfChoices={numberOfChoices}/>}
      </>
    );

  if(hasVoted())
    return (
      <>
      <Box sx={{ marginTop: '16px', pl: 2 }}>
        {choices.map((choice, idx) => (
          <Box
            key={idx}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection : 'column',
              mb: 3,
            }}
          >
            <Typography
              className={`${classes.choiceContainer} ${
                isSelectedChoice(choice, idx) ? classes.selectedChoice : ''
              }`}
              sx={{
                width: 'fit-content',
                marginBottom : "0px!important"
              }}
              component="div"
            >
              {choice.entries[0].name}
            </Typography>
            <BarChart value={choice.entries[0].percent} />
          </Box>
        ))}
      </Box>
      <Typography variant="mediaH4" color="text.secondary" component="div" textAlign={"center"}>{nbVotes/numberOfChoices} joueur.se(s)</Typography>
      </>
    );
};

const MultipleQuestionQuizz = ({ quizz, isSelectedChoice, hasVoted, handleVote, isValidQuizz, validateQuizz }) => {

  const { _id, creation, question, choices, nbVotes, options } = quizz;
  const { validate, numberOfChoices } = options

  const useStyles = makeStyles((theme) => ({
    choiceContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      padding: '6px',
      borderRadius: '15px',
      maxWidth: '600px',
      border: `1px solid ${theme.palette.media.lightgrey}`,
      position: 'relative',
    },
    selectedChoice: {
      border: `1px solid ${theme.palette.media.darkblue}`,
    },
    correctChoice: {
      border: `1px solid ${theme.palette.media.green}`,
      color: theme.palette.media.green,
    },
    incorrectChoice: {
      border: `1px solid ${theme.palette.media.red}`,
      color: theme.palette.media.red,
    },
    disabledRadio: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    enabledRadio: {},
    checkIcon: {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translate(50%, -50%)',
      fontSize: '24px',
      backgroundColor: '#FFFFFF',
      padding: '4px',
      borderRadius: '50%',
    },
  }));

  const classes = useStyles();

  if(!hasVoted())
    return <>
            <Box
              sx={{
                marginTop: '16px',
                pl : 2
              }}
            >
                  {choices.map((choice, idx) => (
                      <Box mb={3} sx={{display : 'flex', maxWidth : '600px'}}>
                          <EntrySelect choice={choice} handleChoice={(entryId) => handleVote(idx, entryId)}/>
                      </Box>
                      
                  ))}
            </Box>
            {validate && <ValidateButton isValid={isValidQuizz} handleValidate={validateQuizz} numberOfChoices={numberOfChoices} />}
    </>

  if(hasVoted())
  return <>
  <Box sx={{ marginTop: '16px', pl: 2 }}>
    {choices.map((choice, idx) => (
      <Box
        key={idx}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection : 'column',
          mb: 3,
        }}
      >
        <Typography
          component="div"
          variant="mediaH2"
          sx={{mb : 2}}
        >
          {choice.label}
        </Typography>
        {choice.entries.map((entry, entryIdx) => (<Box sx={{mb: 3}} key={entryIdx}>
          <Typography
          className={`${classes.choiceContainer} ${
            isSelectedChoice(idx, entry.id) ? classes.selectedChoice : ''
          }`}
          sx={{
            width: 'fit-content',
            marginBottom : "0px!important"
          }}
          component="div"
        >
          {entry.name}
        </Typography>
        <BarChart value={entry.percent} />
        </Box>
        ))}
      </Box>
    ))}
  </Box>
  <Typography variant="mediaH4" color="text.secondary" component="div" textAlign={"center"}>{nbVotes/numberOfChoices} joueur.se(s)</Typography>
  </>

}

const ValidateButton = ( {isValid, handleValidate, numberOfChoices}) => {

  return <Button variant="contained" color="primary" disabled={!isValid()} onClick={handleValidate}>{numberOfChoices > 1 ? 'Valider mes réponses' : 'Valider ma réponse'}</Button>
}

export default MediaSurvey