import { useCallback, useEffect, useState } from "react"
import { getPlayersPositions } from "../../../../api/index.js"
import { useFetch } from "../../../../api/useFetch.js"

export function usePositionPlayer(composition ,{width, height}, isHome, isHolders){
    
        // Dispatch pour récupérer les positions
        const URLgetPlayersPositions = useCallback(() => {

            return getPlayersPositions(composition, {width, height, isHome})

        }, [composition, height, isHome, width])

        const [playersPositions, loading, cleanDatas] = useFetch(URLgetPlayersPositions)

        useEffect(() => {
            cleanDatas()
        }, [cleanDatas, isHolders])
        
    return [playersPositions]
}