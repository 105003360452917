import {NOTE_RECEIVED, NOTE_PENDING } from '../constants/actionTypes.js'

const state = (notationsInfos = {notationsVoted : new Map(), notationsPending : new Map()}, action) => {
    switch(action.type){
        case NOTE_RECEIVED:
            var {idNotation, target, note} = action.payload
            notationsInfos.notationsVoted.set(idNotation+'_'+target, note)

            return {...notationsInfos}
        case NOTE_PENDING:
            var {idNotation, target, note} = action.payload
            notationsInfos.notationsPending.set(idNotation+'_'+target, note)

            return {...notationsInfos}
        default :
            return {...notationsInfos}
    }
}

export default state