import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import CalendarNavigation from "../navigation/CalendarNavigation.js";
import { useNavigateTo } from "../../hooks/useNavigateTo.js";
import MatchsDisplay from "../matchsDisplay/MatchsDisplay.js";
import { useMatchIdMapping } from "../../hooks/useMatchIdMapping.js";
import { useEffect } from "react";

const MultiplexCalendar = ({live, liveDates}) => {

    const navigateTo = useNavigateTo();
    const [matchIdMapping] = useMatchIdMapping(live)

    const { date } = useParams();

    const navigateToNewDate = (newDate) => {

        const newPath = window.location.pathname.replace(/\/calendar\/[^/]+/, `/calendar/${newDate}`);

        navigateTo(newPath);
    };

    useEffect(() => {
        if(live.matchs && liveDates.length === 1 && live.matchs.length === 1)
            navigateTo(`${matchIdMapping[live.matchs[0]]}`); // Ajouter l'ID du match à l'URL
    }, [live])

    return <Box>
        <CalendarNavigation liveDates={liveDates} activeDate={date} setActiveDate={navigateToNewDate} />
        <MatchsDisplay live={live}/>
    </Box>
}

export default MultiplexCalendar