import React, {useMemo, useState} from 'react'
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigateTo } from "../../../v1/components/live/hooks/useNavigateTo.js";
import SportEventList from "../SportEventList.js";
import { useLiveResolver } from "../../hooks/useLiveResolver.js";
import Filter from "../Filter.js";
import SearchFilterDTO from '../../DTO/SearchFilterDTO.js';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale'; // Importer le locale français pour les noms des jours

const ResultatDetail = () => {


    const navigate = useNavigate();

    const navigateTo = useNavigateTo();
    const { id } = useParams();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const queryParameters = new URLSearchParams(window.location.search)
    const sport = queryParameters.get("sport")
    const country = queryParameters.get("country")
    const department = queryParameters.get("department")

    const searchFilter = useMemo(() => {

        return new SearchFilterDTO({    global  : { _id : [id] }, 
                                        gamefact : { 
                                            idName : ['ranking', 'olympics_medal', "performance", "performance_points", "performance_distance"]
                                        },
                                        phase : { name : ["Match pour la 3e place","Matches pour les 3es places","Medal race","Finale"]},
                                        //sport : {idName : ['Sports nautiques']}
                                        cache:true
                                    })
    }, [id])

    const [live] = useLiveResolver(searchFilter)

    const filteredEvents = useMemo(() => {
        if(!live || !live.sportEvents)
            return null

        return live.sportEvents
                        .filter(event => 
                            (!sport || event.phase?.competitionDiscipline?.discipline?.sport?.label === sport) && 
                            (!country || ((event.players && event.players.find(p => p.nationality === country) || 
                            (event.teams && event.teams.find(t => t.nationality === country))))) &&
                            (!department || event.players && event.players.find(p => p.department === department))
                        )
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
    }, [live, sport, country, department])

    const handleSportChange = (event) => {

        const currentUrl = new URL(window.location);

        // Utiliser URLSearchParams pour travailler avec les paramètres de requête
        const searchParams = currentUrl.searchParams;

        // Définir ou changer le paramètre 'sport'
        searchParams.set('sport', event);

        // Mettre à jour l'objet searchParams sur l'objet URL
        currentUrl.search = searchParams.toString();

        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {sport : event});
    };

    const handleSportClear = () => {
        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {}, ['sport']);
    }

    const handleCountryChange = (event) => {

        const currentUrl = new URL(window.location);

        // Utiliser URLSearchParams pour travailler avec les paramètres de requête
        const searchParams = currentUrl.searchParams;

        // Définir ou changer le paramètre 'sport'
        searchParams.set('country', event);

        // Mettre à jour l'objet searchParams sur l'objet URL
        currentUrl.search = searchParams.toString();

        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {country : event}, ['department']);
    };

    const handleCountryClear = () => {
        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {}, ['country', 'department']);
    }

    const handleDepartmentChange = (event) => {

        const currentUrl = new URL(window.location);

        // Utiliser URLSearchParams pour travailler avec les paramètres de requête
        const searchParams = currentUrl.searchParams;

        // Définir ou changer le paramètre 'sport'
        searchParams.set('department', event);

        // Mettre à jour l'objet searchParams sur l'objet URL
        currentUrl.search = searchParams.toString();

        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {department : event});
    };

    const handleDepartmentClear = () => {
        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {}, ['department']);
    }

    const formatDate = (date) => {
        return format(new Date(date), "dd/MM 'à' HH:mm", { locale: fr });
    };
    

    if(!live)
        return <CircularProgress />

    /*if(!live.sportEvents || !live.sportEvents.length)
        return <Typography>Aucun événement</Typography>*/

    

    return <Box>
        {/*<Box sx={{ minWidth: '100%', display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', pt : 3, pb : 1 }}>
            <Typography sx={{fontSize : '1.6em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168"}}>résultats</Typography>
        </Box>*/}
        { live.sportEvents && live.sportEvents.length>0 &&  
        <Box sx={{ minWidth: '100%', display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', pt : 3, pb : 1 }}>
            {/*<Button onClick={navigateToCustomArticle} sx={{color : 'blue'}}>Accédez à notre direct du jour</Button>*/}
            <Box sx={{display : 'flex', flexDirection : 'column', alignItems : 'center', minHeight : 120, justifyContent : 'space-evenly'}}>
                <Typography sx={{fontStyle : 'italic', color : '#4A5168', mr : 1, fontSize : '0.8em'}}>Filtres</Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        minHeight: 60,
                        justifyContent: 'space-evenly'
                    }}
                    >

                    <Filter items={live.getSportsList()} selectedItem={sport} setSelectedItem={handleSportChange} clear={handleSportClear} label={"Tous les sports"}/>
                    <Filter items={live.getCountriesList()} selectedItem={country} setSelectedItem={handleCountryChange} clear={handleCountryClear} label={"Tous les pays"}/>

                    </Box>
            </Box>
            {country === 'France' && <Filter items={live.getDepartmentsList()} selectedItem={department} setSelectedItem={handleDepartmentChange} clear={handleDepartmentClear} label={"Tous les départements"}/>}
            
        </Box>}
        { live.sportEvents && live.sportEvents.length>0 && <SportEventList 
                events={filteredEvents}
                selectedSport={sport}
                formatDate={formatDate}
                groupedBySport={true}
        ></SportEventList>}
        { (!live.sportEvents || !live.sportEvents.length) && <Typography sx={{textAlign:'center'}}>Les résultats seront affichés dès que seront communiqués les premiers résultats officiels.</Typography>}
    </Box>
};

export default ResultatDetail