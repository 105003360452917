import { Box } from "@mui/material"
import { getTeamInfoById, getVisualTimer } from "../../../sports/genericUtils.js"

import BoxItem from "../../../items/fragments/BoxItem.js";
import TypoTimer from "../../../items/fragments/TypoTimer.js";
import TypoTitle from "../../../items/fragments/TypoTitle.js";
import TypoText from "../../../items/fragments/TypoText.js";


const GenericCommentaire = ({item, match, icon}) => {

    const {
        comment, title, 
        target, 
        period, timer
    } = item.detail

    const visualTimer = getVisualTimer(timer, period, match.discipline)
    const teamDetail = getTeamInfoById(target, match)

    return (
        <BoxItem
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TypoTimer timer={visualTimer} lisere={teamDetail ? teamDetail.color : '#4973AE'}/>
            <img src={icon} alt="Player Icon" style={{ width: `${40}px`, height: `${40}px` }} />
          </Box>
          
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '8px',
              position : 'relative'
            }}
          >
            <Box sx={{paddingLeft : "8px"}}>
              <TypoTitle title={title}/>
              <TypoText text={comment} />
            </Box>
          </Box>
        </BoxItem>
      );
}

export default GenericCommentaire