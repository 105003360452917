import { FETCH_ALL_MATCH, CREATE_MATCH, DELETE_MATCH, UPDATE_MATCH } from '../constants/actionTypes.js'

export default (matchs = [], action) => {
    switch(action.type){
        case FETCH_ALL_MATCH:
            return action.payload
        case CREATE_MATCH:
            return [...matchs, action.payload]
        case UPDATE_MATCH:
            return matchs.map((match) => match._id === action.payload._id ? action.payload : match)
        case DELETE_MATCH:
            return matchs.filter((match) => match._id !== action.payload)
        default :
            return matchs
    }
}