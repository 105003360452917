import { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/overridesquill.css'
import { formats } from './config.js';
import IframeBlot from './modules/IframeModule.js';
import TwitterBlot from './modules/TwitterModule.js';

const QuillEditor = ({content, onChange, readOnly = false, style}) => {

  const quillRef = useRef();

    Quill.register('formats/iframe', IframeBlot);
    Quill.register('formats/twitter', TwitterBlot);

    const font = Quill.import('attributors/style/font');
    font.whitelist = ['Poppins', 'Source Sans Pro', 'Roboto'];
    Quill.register(font, true);
    
    useEffect(() => {

      const quill = quillRef?.current?.getEditor();

      if (quill) {
        // Convertir le contenu HTML en Delta avant de l'appliquer à Quill
        const delta = quill.clipboard.convert(content);
        quill.setContents(delta);
      }
    }, []);

    const insertIframe = () => {
      const quill = quillRef?.current?.getEditor();
      if (!quill) return;

      const url = prompt('Veuillez saisir l\'URL de l\'iframe :');
      if (url) {
        const cursorPosition = quill.getSelection().index;
        quill.insertText(cursorPosition, '\n', 'user'); // Utilisez un saut de ligne au lieu d'un espace
        quill.insertEmbed(cursorPosition, 'iframe', {iframe : url}); 
      }
    }

    const insertTweet = () => {
      const quill = quillRef?.current?.getEditor();
      if (!quill) return;

      const url = prompt('Veuillez saisir l\'URL du twitt :');
      if (url) {
        const cursorPosition = quill.getSelection().index;
        quill.insertText(cursorPosition, '\n', 'user'); // Utilisez un saut de ligne au lieu d'un espace
        quill.insertEmbed(cursorPosition, 'twitter', {url}); 
      }
    }

    const handlers = {
      iframe: insertIframe,
      twitter: insertTweet
    }

    const modules = useMemo(() => ({
        toolbar : readOnly ? null : {
          container : "#toolbar-container",
          handlers : handlers,
        }
        
    }), [])

    return (
          <ReactQuill ref={quillRef} theme="snow"
          value={content}
          
          modules={modules}
          formats={formats}

          onChange={onChange}

          style={style}
          readOnly={readOnly}
          />
        
    )

}

export default QuillEditor