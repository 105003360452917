//import { GAME_FACT_DROP, GAME_FACT_ESSAI, GAME_FACT_PENALITE, GAME_FACT_SOCCER_BUT, GAME_FACT_SOCCER_PENALTY, GAME_FACT_TRANSFORMATION } from "../gameFact/constants.js"

import { GAME_FACT_DROP, GAME_FACT_ESSAI, GAME_FACT_PENALITE, GAME_FACT_SOCCER_BUT, GAME_FACT_SOCCER_PENALTY, GAME_FACT_TRANSFORMATION, GAME_FACT_SHOOTOUTPENALTY, GAME_FACT_SOCCER_SHOOTOUTPENALTY, GAME_FACT_BASKET_FREETHROW, GAME_FACT_BASKET_TWOPOINTS, GAME_FACT_BASKET_THREEPOINTS, GAME_FACT_HANDBALL_GOAL, GAME_FACT_HANDBALL_PENALTY, GAME_FACT_HANDBALL_SHOOTOUT } from "../gamefacts/types.js";
import { getNumericTimer, getVisualTimer } from "./genericUtils.js";

import but_reussi from '../gamefacts/icons/soccer/but_valide.png'
import penalty_reussi from '../gamefacts/icons/soccer/penalty_valide.png'

import essai_reussi from '../gamefacts/icons/rugby/essai.png'
import transformation_reussi from '../gamefacts/icons/rugby/transformation_valide.png'
import drop_reussi from '../gamefacts/icons/rugby/drop_valide.png'
import penality_reussi from '../gamefacts/icons/rugby/penality_valide.png'

import lancerfrancIcon from '../gamefacts/icons/basket/picto_lancer_franc_1pts_basket.png'
import deuxpointsIcon from '../gamefacts/icons/basket/picto_lancer_franc_2pts_basket.png'
import troispointsIcon from '../gamefacts/icons/basket/picto_lancer_franc_3pts_basket.png'

import picto_but_hand from '../gamefacts/icons/handball/picto_but_hand.png'
import picto_penalty_reussi_hand from '../gamefacts/icons/handball/picto_penalty_reussi_hand.png'
import picto_tir_au_but_reussi_hand from '../gamefacts/icons/handball/picto_tir_au_but_reussi_hand.png'


export function scoreResolver(match) {
    if (!match.gameFacts || !match.homeTeam) {
      return;
    }
  
    const homeTeamId = match.homeTeam._id;
    const awayTeamId = match.awayTeam._id;
  
    let homeScoreCalcul = 0;
    let awayScoreCalcul = 0;

    let homePlayerScoreCalcul = []
    let awayPlayerScoreCalcul = []

    let homePlayerShoutout = []
    let awayPlayerShoutout = []
  
    const scoreMappings = {
      [GAME_FACT_ESSAI]: { 
        points: (scorer) => scorer==='edp' ? 7 : 5, 
        scorerParam : 'buteur', 
        icone : essai_reussi
      },
      [GAME_FACT_TRANSFORMATION]: { 
        points: () => 2, 
        scorerParam : 'transformer', 
        icone : transformation_reussi, 
        validParam : 'transformSucced' 
      },
      [GAME_FACT_DROP]: { 
        points: () => 3, 
        scorerParam : 'droper', 
        icone : drop_reussi, 
        validParam : 'dropSucced' 
      },
      [GAME_FACT_PENALITE]: { 
        points: () => 3, 
        scorerParam : 'penaliter', 
        icone : penality_reussi, 
        validParam : 'penalitySucced' 
      },
      [GAME_FACT_SHOOTOUTPENALTY]: { 
        points: () => 0, 
        scorerParam : 'penaliter', 
        icone : penality_reussi, 
        validParam : 'penalitySucced' 
      },

      [GAME_FACT_SOCCER_BUT]: { 
        points: () => 1, 
        scorerParam : 'buteur', 
        icone : but_reussi, 
        validParam : 'butSucced' 
      },
      [GAME_FACT_SOCCER_PENALTY]: { 
        points: () => 1, 
        scorerParam : 'buteur', 
        icone : penalty_reussi, 
        validParam : 'butSucced' },
      [GAME_FACT_SOCCER_SHOOTOUTPENALTY]: { 
        points: () => 0, 
        scorerParam : 'buteur', 
        icone : penalty_reussi, 
        validParam : 'butSucced' 
      },

      [GAME_FACT_BASKET_FREETHROW]: { 
        points: () => 1, 
        scorerParam : 'player', 
        icone : lancerfrancIcon, 
        validParam : 'isSucceed'
      },
      [GAME_FACT_BASKET_TWOPOINTS]: { 
        points: () => 2, 
        scorerParam : 'player', 
        icone : deuxpointsIcon, 
        validParam : 'isSucceed'
      },
      [GAME_FACT_BASKET_THREEPOINTS]: { 
        points: () => 3, 
        scorerParam : 'player', 
        icone : troispointsIcon,
        validParam : 'isSucceed' 
      },

      [GAME_FACT_HANDBALL_GOAL]: { 
        points: () => 1, 
        scorerParam : 'buteur', 
        icone : picto_but_hand, 
        validParam : 'butSucced' 
      },
      [GAME_FACT_HANDBALL_PENALTY]: { 
        points: () => 1, 
        scorerParam : 'penaliter', 
        icone : picto_penalty_reussi_hand, 
        validParam : 'penalitySucced' 
      },
      [GAME_FACT_HANDBALL_SHOOTOUT]: { 
        points: () => 0, 
        scorerParam : 'buteur', 
        icone : penalty_reussi, 
        validParam : 'butSucced' 
      }
    };
  
    match.gameFacts.filter(gf => {
      const { type } = gf;

      return scoreMappings[type]
    }).sort((a, b) => {
      const timerComparison = getNumericTimer(a.detail.timer, a.detail.period, match.discipline) - getNumericTimer(b.detail.timer, b.detail.period, match.discipline);

      if(timerComparison === 0)
        return new Date(a.creation) - new Date(b.creation);

      return timerComparison
    }).forEach((gf) => {
      const { type, detail } = gf;

      const { points, scorerParam, validParam, icone} = scoreMappings[type];

      const { team, [scorerParam] : scorer, timer, period, [validParam] : isValid = 1 } = detail;

      if([GAME_FACT_SHOOTOUTPENALTY, GAME_FACT_SOCCER_SHOOTOUTPENALTY, GAME_FACT_HANDBALL_SHOOTOUT].includes(type)){
        if (team === homeTeamId) {
          homePlayerShoutout.push({player : scorer, isValid})
        }else if (team === awayTeamId) {
          awayPlayerShoutout.push({player : scorer, isValid})
        }

      }else if(isValid){
        if (team === homeTeamId) {
          homeScoreCalcul += points(scorer);
          if(match.discipline === 'basket' || match.discipline === 'handball'){
            let existingPlayer = homePlayerScoreCalcul.find(p => p.player === scorer)
            if(existingPlayer)
              existingPlayer.points += points(scorer)
            else{
              let cscLabel = scorer === 'csc' ? ' (csc)' : ""
              homePlayerScoreCalcul.push({player : scorer, points : points(scorer), suffixeLabel : match.discipline === 'basket' ? ' pt(s)'+cscLabel : ' but(s)'+cscLabel})
            }
              
          }else{
            homePlayerScoreCalcul.push({player : scorer, timer : getVisualTimer(timer, period, match.discipline), icone, type})
          }
          
        } else if (team === awayTeamId) {
          awayScoreCalcul += points(scorer);
          if(match.discipline === 'basket'|| match.discipline === 'handball'){
            let existingPlayer = awayPlayerScoreCalcul.find(p => p.player === scorer)
            if(existingPlayer)
              existingPlayer.points += points(scorer)
            else{
              let cscLabel = scorer === 'csc' ? ' (csc)' : ""
              awayPlayerScoreCalcul.push({player : scorer, points : points(scorer), suffixeLabel : match.discipline === 'basket' ? ' pt(s)'+cscLabel : ' but(s)'+cscLabel})
            }
              
          }else{
            awayPlayerScoreCalcul.push({player : scorer, timer : getVisualTimer(timer, period, match.discipline), icone, type})
          }
          
        }
      }
        
    });

    if(match.discipline === 'basket' || match.discipline === 'handball'){
      homePlayerScoreCalcul.sort((a,b) => b.points - a.points)
      awayPlayerScoreCalcul.sort((a,b) => b.points - a.points)
    }
  
    return [homeScoreCalcul, awayScoreCalcul, homePlayerScoreCalcul, awayPlayerScoreCalcul, homePlayerShoutout, awayPlayerShoutout];
  }