
import { CREATE_GAMEFACT, 
    DELETE_GAMEFACT, 
    EDIT_MATCH_IN_LIVE, 
    UPDATE_GAMEFACT, 
    UPDATE_TIMER, 
    DELETE_ARTICLE,
    UPDATE_ARTICLE,
    CREATE_ARTICLE, 
    DELETE_NOTATION,
    UPDATE_NOTATION,
    CREATE_NOTATION,
    DELETE_SURVEY,
    UPDATE_SURVEY,
    CREATE_SURVEY,
    CREATE_PLAYER,
    UPDATE_COMPOSITION,
    UPDATE_INFOMATCH,
    CREATE_QUIZZ,
    UPDATE_QUIZZ,
    DELETE_QUIZZ,
    AUTOSCORE_GAMEFACTS,
    UPDATE_INFOACTULIVE

} from '../constants/actionTypes.js'

// Action creator

export const resolveMessage = (match)  => async (dispatch, getState) => {
    if(!match.actionDetail){
        dispatch({ type: EDIT_MATCH_IN_LIVE, payload: match})
    }else{
        const {idMatch, actionDetail, collectorServerTime} = match
        const {action, payload} = actionDetail


        // On remet le collectorServertime à l'heure
        //dispatch({ type : EDIT_COLLECTOR_TIMESTAMP, payload : {collectorServerTime}})

        switch(action){
            case "addGameFact":
                dispatch({ type : CREATE_GAMEFACT, payload})
            break;
            case "autoScoreGameFact":
                dispatch({ type : AUTOSCORE_GAMEFACTS, payload})
            break;
            case "updateGameFact":
                dispatch({ type : UPDATE_GAMEFACT, payload})
            break;
            case "deleteGameFact":
                dispatch({ type : DELETE_GAMEFACT, payload})
            break;
            case "addNotation":
                dispatch({ type : CREATE_NOTATION, payload})
            break;
            case "updateNotation":
                dispatch({ type : UPDATE_NOTATION, payload})
            break;
            case "deleteNotation":
                dispatch({ type : DELETE_NOTATION, payload})
            break;
            case "addSurvey":
                dispatch({ type : CREATE_SURVEY, payload})
            break;
            case "updateSurvey":
                dispatch({ type : UPDATE_SURVEY, payload})
            break;
            case "deleteSurvey":
                dispatch({ type : DELETE_SURVEY, payload})
            break;
            case "addQuizz":
                dispatch({ type : CREATE_QUIZZ, payload})
            break;
            case "updateQuizz":
                dispatch({ type : UPDATE_QUIZZ, payload})
            break;
            case "deleteQuizz":
                dispatch({ type : DELETE_QUIZZ, payload})
            break;
            case "addArticle":
                dispatch({ type : CREATE_ARTICLE, payload})
            break;
            case "updateArticle":
                dispatch({ type : UPDATE_ARTICLE, payload})
            break;
            case "deleteArticle":
                dispatch({ type : DELETE_ARTICLE, payload})
            break;
            case "updateTimer":
                dispatch({ type : UPDATE_TIMER, payload})
            break;
            case "updateComposition":
                dispatch({ type : UPDATE_COMPOSITION, payload})
            break
            case "updateInfoMatch":
                dispatch({ type : UPDATE_INFOMATCH, payload})
            break
            case "createPlayer":
                dispatch({ type : CREATE_PLAYER, payload : {idMatch, player : payload}})
            break;
            case "updateActuLive":
                dispatch({type : UPDATE_INFOACTULIVE, payload})
            break;
            default :
                console.error("Action not managed for sockets => ", action)

        }
    }
}