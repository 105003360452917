import React, { useEffect, useState, useCallback } from 'react'

export const useURLSearchParams = () => {
    const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  
    const updateSearchParams = useCallback((params) => {
        const newSearchParams = new URLSearchParams();
        
        // Replicate existing params
        params.forEach((value, key) => {
            newSearchParams.set(key, value);
        });
  
        const url = new URL(window.location);
        url.search = newSearchParams.toString();

        window.history.pushState({}, '', url);
        setSearchParams(newSearchParams);
    }, []);
  
    const setParam = useCallback((key, value) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(key, value);
        updateSearchParams(newSearchParams);
    }, [searchParams, updateSearchParams]);
  
    const deleteParam = useCallback((key) => {

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(key);
        updateSearchParams(newSearchParams);
    }, [searchParams, updateSearchParams]);
  
    useEffect(() => {
        const handleLocationChange = () => {
            setSearchParams(new URLSearchParams(window.location.search));
        };
  
        window.addEventListener('popstate', handleLocationChange);
        // Note: `pushstate` and `replacestate` are not standard events, 
        // but you can dispatch them in your app if you use a polyfill.
        window.addEventListener('pushstate', handleLocationChange);
        window.addEventListener('replacestate', handleLocationChange);
  
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
            window.removeEventListener('pushstate', handleLocationChange);
            window.removeEventListener('replacestate', handleLocationChange);
        };
    }, []);
  
    return { searchParams, setParam, deleteParam };
  };