import React from 'react';
import { Container, Paper, Typography, CircularProgress } from '@mui/material';
import useViewLoader from '../hooks/useViewLoader.js';
import { getViewPlayer } from '../../../../api/index.js';
import useViewToken from '../hooks/useViewToken.js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PlayerProfileView = ({ id }) => {

  const { joueur } = useParams();

  const { token, expiresIn, fetchToken } = useViewToken()

  const { loading, data, error, loadData } = useViewLoader(getViewPlayer, joueur && joueur.split("_")[1]);

  useEffect(() => {
    // Appelez la fonction init() du bundle avec les données
    if(data)
      window.dsasViewProfilJoueur(data);
}, [data])

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <div id="dsas-view"></div>

        {loading && (
          <CircularProgress />
        )}

        <Typography variant="h4" component="h2" gutterBottom>
        </Typography>
      </Paper>
    </Container>
  );
};

export default PlayerProfileView;