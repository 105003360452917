import React, { useEffect, useMemo, useState } from "react"
import { GAME_FACT_SOCCER_SECONDYELLOWCARD, GAME_FACT_SOCCER_SHOT, GAME_FACT_SOCCER_YELLOWCARD } from "../gamefacts/types.js";
import { reasonListSoccer } from "../gamefacts/reasons.js";
import { getMediaNumericTimer, getNumericTimer, getPlayerById } from "../sports/genericUtils.js";
import { Box, Button, CircularProgress, Divider, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import CompositionPreview from "../../../../components/Live/preview/composition/CompositionPreview.js";
import ItemResolver from "../items/ItemResolver.js";
import MatchHeader from "./header/MatchHeader.js";
import { useNavigate } from "react-router-dom";
import CustomDivider from "../dsasComponents/CustomDivider.js";

const MatchView = ({ match, loadMore }) => {

    const navigate = useNavigate();

    const [pinItems, setPinItems] = useState([])

    const [items, setItems] = useState([])
    const [visibleItems, setVisibleItems] = useState(10);

    const [tab, setTab] = useState(0)

    // Reset des cartons jaunes
    const yellowCardSoccerPlayers = []

    useEffect(() => {

        if(!match.isComplete)
            return

        const getPlayer = (id) => getPlayerById(id, match);

          let buildGameFacts = match.gameFacts
            .sort((a, b) => {
                if (a.detail.period < b.detail.period) {
                    return -1;
                } else if (a.detail.period > b.detail.period) {
                    return 1;
                } else {
                    return a.detail.timer - b.detail.timer;
                }
            })

            // Perfectionner le rendu generique et apports des modifs sur le model
            buildGameFacts = buildGameFacts.map(gf => {
                switch(gf.type){
                    case GAME_FACT_SOCCER_SHOT : 
                        return {...gf, detail : {...gf.detail, player : gf.detail.shoter}}
                    case GAME_FACT_SOCCER_YELLOWCARD : 
                        if(yellowCardSoccerPlayers.includes(gf.detail.player)){
                            var player = getPlayer(gf.detail.player)
                            var reasonInfo = reasonListSoccer.find(r => r.id === gf.detail.reason)
                            let title = "CARTON ROUGE"
                            let text = `2eme carton jaune pour ${player.lastname}! Raison : ${reasonInfo.value}. C'est donc un carton rouge pour ${player.lastname} !`
                            // 2eme jaune
                            return {...gf, type : GAME_FACT_SOCCER_SECONDYELLOWCARD, detail : {...gf.detail, title, text}}
                        }else{
                            yellowCardSoccerPlayers.push(gf.detail.player)
                            return {...gf}
                        }
                    default : 
                        return {...gf}
                }

            })

            let buildMedias = match.quizzs ? match.quizzs.map(i => ({...i, type : 'quizz'})) : []
            let buildSurveys = match.surveys ? match.surveys.map(i => ({...i, type : 'survey'})) : []
            let buildNotations = match.notations ? match.notations.map(i => ({...i, type : 'notation'})) : []
            let buildArticles = match.articles ? match.articles.map(i => ({...i, type : 'article'})) : []

            const sortThings = (a, b) => {
                let bTimer = b.detail ? getNumericTimer(b.detail.timer, b.detail.period, match.discipline) : getMediaNumericTimer(b.timer, b.period, match.discipline)
                let aTimer = a.detail ? getNumericTimer(a.detail.timer, a.detail.period, match.discipline) : getMediaNumericTimer(a.timer, a.period, match.discipline)

                let bPeriod = b.detail ? b.detail.period : b.period
                let aPeriod = a.detail ? a.detail.period : a.period
    
                if(bTimer < aTimer)
                    if(bPeriod > aPeriod)
                        return 1
                    else
                        return -1
                if(bTimer > aTimer)
                    if(bPeriod < aPeriod)
                        return -1
                    else
                        return 1
                if(new Date(b.creation) < new Date(a.creation))
                    return -1
                if(new Date(b.creation) > new Date(a.creation))
                    return 1
            }

            setPinItems(buildMedias.filter(g => g.isPin).concat(buildSurveys.filter(g => g.isPin)).concat(buildNotations.filter(g => g.isPin)).concat(buildArticles.filter(g => g.isPin)).sort(sortThings))

            setItems((buildGameFacts.concat(buildMedias.filter(g => !g.isPin)).concat(buildSurveys.filter(g => !g.isPin)).concat(buildNotations.filter(g => !g.isPin)).concat(buildArticles.filter(g => !g.isPin))).sort(sortThings))
    }, [match])

    const handleShowMore = async () => {

        if(match.hasMore)
            await loadMore()

        const newVisibleItems = visibleItems + 10;
        setVisibleItems(newVisibleItems);
    };

    const disabledShowMore = useMemo(() => {
        return visibleItems >= items.length
        && !match.hasMore
    }, [match.hasMore, visibleItems, items])

    // TODO NO LOAD
    if(!match.isComplete)
        return <CircularProgress/>

    return (
        <Box>
            <Box sx={{display : 'flex', flexDirection : 'column', alignItems:'center', textAlign : 'center', marginBottom : 3, position : 'relative'}}>
                <Box sx={{position : 'fixed', width : '100%',  zIndex : 1000}}>
                    <Button variant={'contained'} onClick={() => navigate(-1)} sx={{float : 'left', ml : 5}}>&#x3008;
                    
                    </Button>
                </Box>
                {match.type==='match' && <MatchHeader match={match}/>}
            </Box>
    
            <Box sx={{ borderBottom: 1, borderTop : 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} aria-label="basic tabs example" centered>
                     <Tab label="Live" {...a11yProps(0)} />
                    <Tab label="Composition" {...a11yProps(1)} />
                </Tabs>
            </Box>
    
            <TabPanel value={tab} index={0}>
                {pinItems.length>0 && <Stack spacing={0}>
                    {pinItems.map((t, idx) =>
                        <React.Fragment key={idx}>
                            <ItemResolver item={t} match={match} />
                        </React.Fragment>
                    )}
                    <CustomDivider/>
                </Stack>}
                <Stack spacing={0}>
                {items.slice(0, visibleItems).map((t, idx) => 
                    <React.Fragment key={idx}>
                        <ItemResolver item={t} match={match} />
                        <CustomDivider disabled={true}/>
                    </React.Fragment>
                )}
                </Stack>
                {!disabledShowMore ? (
                    <Button sx={{width : '100%', mt : 2, bgcolor : "multiplex.darkblue"}} onClick={handleShowMore} variant="contained">
                    Voir plus
                    </Button>
                ) : (
                    <Button sx={{width : '100%', mt : 2}} variant="contained" disabled>
                    Début du live
                    </Button>
                )}
            </TabPanel>
            {match.type==='match' && <TabPanel value={tab} index={1}>
                <CompositionPreview match={match}/>
            </TabPanel>}
        
        </Box>
        )

}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export default MatchView