import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { getLiveCompetitions, getLiveDates } from '../../../api/index.js';
import { useParams } from 'react-router-dom';
import { getClosestDate } from './utils.js';
import { useNavigateTo } from './hooks/useNavigateTo.js';

import { DAY_MODE, CALENDAR_MODE } from './constants/MODE_LIST.js';

const LiveDatesResolver = () => {
    const navigateTo = useNavigateTo();
    const { id, mode, date } = useParams();

    const [liveCompetitionsDays, setLiveCompetitionsDays] = useState(null)
    const [liveDates, setLiveDates] = useState(null);

    useEffect(() => {
      // On doit récupérer la liste des competitions du live
      if(mode === DAY_MODE){
        const fetchData = async () => {
          try {
            const response = await getLiveCompetitions(id);
            setLiveCompetitionsDays(response.data);
          } catch (error) {
            console.error(error);
          }
        };

        fetchData();
      }

      if(mode === CALENDAR_MODE){
        const fetchData = async () => {
          try {
            const response = await getLiveDates(id);
            setLiveDates(response.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }

    }, [id, mode])

    useEffect(() => {
      if(mode === CALENDAR_MODE && liveDates && liveDates.length){
        
        // Resolve the date if it is not provided
        const resolvedDate = date ?? getClosestDate(liveDates);
    
        // Update the URL with the resolved date if necessary
        if(resolvedDate!==date)
          navigateTo(`${resolvedDate}`, {}, ['competition']);
      }

      if(mode === DAY_MODE && liveCompetitionsDays && liveCompetitionsDays.length){
        // On regarde le param competition
        const queryParameters = new URLSearchParams(window.location.search)
        const competition = queryParameters.get("competition")

        let resolvedCompetition = liveCompetitionsDays.find(c => c._id === competition)

        if(!resolvedCompetition)
          resolvedCompetition = liveCompetitionsDays[0]

        const resolvedDay = date ?? resolvedCompetition.activeDay
          
        if(resolvedDay!==date)
          navigateTo(`${resolvedDay}`, {competition : resolvedCompetition._id});
      }

    }, [id, date, liveCompetitionsDays, liveDates, mode, navigateTo])

    
    if (!liveDates && !liveCompetitionsDays) {
      return <CircularProgress />
    }

    return <Outlet context={{liveDates, liveCompetitionsDays}}/>;
};

export default LiveDatesResolver