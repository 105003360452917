class StadiumDTO {

    constructor(stadiumData){
        if(!stadiumData)
            return

        const {_id, name, location, capacity, provider} = stadiumData

        this._id = _id
        this.name = name
        this.location = location
        this.capacity = capacity

        this.provider = provider
    }
}

export default StadiumDTO