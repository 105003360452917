import {QUIZZ_VOTE_RECEIVED, QUIZZ_VOTES_RECEIVED } from '../constants/actionTypes.js'

const state = (quizzs = {}, action) => {
    switch(action.type){
        case QUIZZ_VOTE_RECEIVED:
            var {id, idChoice, idEntry} = action.payload
            quizzs[id+'_'+idChoice] = idEntry
            return {...quizzs}
        case QUIZZ_VOTES_RECEIVED:
            var {id, votes} = action.payload
            votes.forEach(vote => {
                quizzs[id+'_'+vote.idChoice] = vote.idEntry
            })
            
            return {...quizzs}
        default :
            return {...quizzs}
    }
}

export default state