import {Quill} from 'react-quill';
import { loadTwitterScript } from './twitter/loadTwitterScript';
import { cleanTweetURL } from './twitter/twitterUtils';

const BaseBlock = Quill.import('blots/embed');

class TwitterBlot extends BaseBlock {
	static create(data) {

		const node = super.create(data);

    const cleanedUrl = cleanTweetURL(data.url);

	  loadTwitterScript().then(() => {
			window.twttr.widgets.load(node.parentNode);
		  }).catch(error => console.error('Erreur de chargement du script Twitter:', error));

		  const innerHTML = `
		  <div style="display: flex; max-width: 100%;" contentEditable="false">
			<blockquote class="twitter-tweet">
			  <a href="${cleanedUrl}" tabIndex="-1">Voir le Tweet</a>
			</blockquote>
		  </div>
		`;
	
		node.innerHTML = innerHTML;
		// store data
		node.setAttribute('data-url', cleanedUrl);
		return node;
	}

	static value(domNode) {
		const { url } = domNode.dataset;
		return { url };
	}

	index() {
		return 1;
	}

}

TwitterBlot.blotName = 'twitter';
TwitterBlot.tagName = 'div';
TwitterBlot.className = 'ql-tweet';

Quill.register(TwitterBlot)
/*
// Importez la classe BlockBlot de Quill
const EmbedBlot = Quill.import('blots/embed');

// Créez une nouvelle classe Blot pour l'iframe
class IframeBlot extends EmbedBlot  {
  static create(value) {
    console.log("value", value)
    const node = super.create(value);
    node.setAttribute('src', value);
    node.setAttribute('frameborder', '0');
    node.setAttribute('allowfullscreen', true);
    node.setAttribute('width', '100%');
    node.setAttribute('height', '600px');
    node.setAttribute('style', 'display: block; overflow: auto; margin: auto; border-width: 0px;');
    node.setAttribute('scrolling', 'no');

    return node;
  }

  static value(node) {
    return {
      iframe: node.getAttribute('src'),
    };
  }

  static formats(node) {
    return node.getAttribute('iframe')
  }
}

IframeBlot.blotName = 'iframe'
IframeBlot.tagName = 'IFRAME'
IframeBlot.className = ''

Quill.register(IframeBlot)*/

export default TwitterBlot