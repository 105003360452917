import React from "react"
import { itemDetails } from "./itemDetails.js"

const ItemResolver = ({ item, match }) => {

    const itemDetail = itemDetails.find(d => d.type === item.type) ?? itemDetails.find(d => d.type === 'default')

    const {component, icon} = itemDetail

    return React.createElement(component, {item, match, icon})
}

export default ItemResolver