import { FETCH_ALL_PLAYER, CREATE_PLAYER, DELETE_PLAYER, UPDATE_PLAYER, CREATE_PLAYERS } from '../constants/actionTypes.js'

export default (players = [], action) => {
    switch(action.type){
        case FETCH_ALL_PLAYER:
            return action.payload
        case CREATE_PLAYER:
            return [...players, action.payload]
        case CREATE_PLAYERS:
            return [...players, ...action.payload]
        case UPDATE_PLAYER:
            return players.map((player) => player._id === action.payload._id ? action.payload : player)
        case DELETE_PLAYER:
            return players.filter((player) => player._id !== action.payload)
        default :
            return players
    }
}