import CategoryDTO from "./CategoryDTO.js"
import CompetitionDisciplineDTO from "./CompetitionDisciplineDTO.js"
import DisciplineDTO from "./DisciplineDTO.js"
import EpreuveDTO from "./EpreuveDTO.js"
import SportEventDTO from "./SportEventDTO.js"

class PhaseDTO {

    constructor(phaseData){
        if(!phaseData)
            return

        const {_id, provider, name, epreuve, category,  competitionDiscipline, sportEvents } = phaseData

        this._id = _id
        this.provider = provider

        this.name = name
        this.epreuve = new EpreuveDTO(epreuve)
        this.category = new CategoryDTO(category)

        this.sportEvents = sportEvents?.map(sportEvent => new SportEventDTO(sportEvent))

        this.competitionDiscipline = new CompetitionDisciplineDTO(competitionDiscipline)
    }
}

export default PhaseDTO