import React from 'react'
import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import { useOrderedStructure } from "../../hooks/useOrderedStructure.js"
import MatchDisplay from "./MatchDisplay.js"
import { useMatchIdMapping } from "../../hooks/useMatchIdMapping.js"
import { useNavigateTo } from "../../hooks/useNavigateTo.js"
import { useParams } from 'react-router-dom'
import { CALENDAR_MODE, DAY_MODE } from '../../constants/MODE_LIST.js'
import { discipline_all } from '../../../../../bdd/bddSTUB.js'

const MatchsDisplay = ({live}) => {

    const navigateTo = useNavigateTo()
    const [matchIdMapping] = useMatchIdMapping(live)

    const [orderedStructure, competitionMap] = useOrderedStructure(live)

    const navigateToMatch = (matchId) => {
        navigateTo(`${matchIdMapping[matchId]}`); // Ajouter l'ID du match à l'URL
    }

    const { mode } = useParams();

    if(mode === DAY_MODE)
        return <PerDay orderedStructure={orderedStructure} competitionMap={competitionMap} navigateToMatch={navigateToMatch}/>

    if(mode === CALENDAR_MODE)
        return <PerCalendar orderedStructure={orderedStructure} competitionMap={competitionMap} navigateToMatch={navigateToMatch}/>
}


const PerDay = ({orderedStructure, competitionMap, navigateToMatch}) => {

    const isAtLeastOneMatchNavigable = (matchs) => {
        return matchs.some(isMatchNavigable)
    }

    const isMatchNavigable = (match) => {
        const homeTeamSubscription = match.homeTeam.subscription ?? 1
        const awayTeamSubscription = match.awayTeam.subscription ?? 1

        return homeTeamSubscription < 4 || awayTeamSubscription < 4
    }


    return <Box>
            {orderedStructure && Object.entries(orderedStructure).map(([discipline, competitions]) => 
            <Box key={discipline} >
                <Typography
                    variant="h1"
                    color="multiplex.darkblue" 
                >
                        {Object.entries(orderedStructure).length > 1 && discipline_all.find(d => d._id === discipline).name}
                </Typography>
                <Box>
                    {Object.entries(competitions).map(([competitionId, days]) => {
                        return (
                            <React.Fragment key={competitionId}>
                                {days &&
                                    Object.entries(days).map(([day, dates]) => {
                                    return (
                                        <React.Fragment key={day}>
                                        <Typography 
                                            sx={{mt : 2}}
                                            variant="h2" 
                                            component="div" 
                                            color="multiplex.main">
                                            {competitionMap.get(competitionId).name} {competitionMap.get(competitionId).season} : {day}e Journée.
                                        </Typography>
                                            {dates && 
                                            Object.entries(dates)
                                            .sort(([dateA, matchsA], [dateB, matchsB]) => {
                                                const dateAValue = new Date(matchsA[0].dateTime);
                                                const dateBValue = new Date(matchsB[0].dateTime);
                                                return dateAValue - dateBValue;
                                              })
                                            .map(([date, matchs]) => {
                                                return (
                                                    <React.Fragment key={date}>
                                                    <Typography 
                                                        variant="daydetail" 
                                                        component="div" 
                                                        color="multiplex.main"
                                                        sx={{ 
                                                            paddingLeft: 1 ,
                                                            boxShadow: '20px 20px 20px rgba(0, 0, 0, 0.1)'
                                                        }}
                                                    >
                                                        {date}
                                                    </Typography>
                                                    {isAtLeastOneMatchNavigable(matchs) && <Typography variant="h4" color="multiplex.grey" component="div" p={4} textAlign={"center"}>
                                                        Cliquer sur un match pour accéder au live
                                                    </Typography>}
                                                    <Stack sx={{ display: 'flex', alignItems: 'center', mb : 2}}>
                                                    {matchs &&
                                                        matchs
                                                        .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
                                                        .map((match, index) => (
                                                            <>
                                                            {index === 0 && <><Divider orientation="horizontal" flexItem /><Divider orientation="horizontal" flexItem /></>}
                                                            {index !== 0 && <Divider orientation="horizontal" flexItem />}
                                                            <Box
                                                            key={match.idMatch}
                                                            onClick={() => isMatchNavigable(match) && navigateToMatch(match.idMatch)}
                                                            sx={{ 
                                                                display: 'flex', 
                                                                justifyContent: 'center', 
                                                                width : '100%',
                                                                "&:hover": {
                                                                    ...(isMatchNavigable(match) && {
                                                                        bgcolor: "multiplex.darkblue",
                                                                        color: "white",
                                                                        cursor: "pointer",
                                                                      }),
                                                                },
                                                            }}
                                                        >
                                                            <MatchDisplay match={match} />
                                                        </Box>
                                                        {index !== matchs.length - 1 && <Divider orientation="horizontal" flexItem />}
                                                        {index === matchs.length - 1 && 
                                                        <><Divider orientation="horizontal" flexItem /><Divider orientation="horizontal" flexItem /></>}
                                                        </>
                                                    ))}
                                                    </Stack>
                                                    </React.Fragment>
                                                )
                                                })
                                            }
                                        </React.Fragment>
                                        )
                                    })
                                }
                                </React.Fragment>
                                )
                            })}
                    </Box>
                </Box>
                )}
            </Box>
}

const PerCalendar = ({orderedStructure, competitionMap, navigateToMatch}) => {

    const isAtLeastOneMatchNavigable = (matchs) => {
        return matchs.some(isMatchNavigable)
    }

    const isMatchNavigable = (match) => {
        const homeTeamSubscription = match.homeTeam.subscription ?? 1
        const awayTeamSubscription = match.awayTeam.subscription ?? 1

        return homeTeamSubscription < 4 || awayTeamSubscription < 4
    }

    return <Box>
            {orderedStructure && Object.entries(orderedStructure).map(([discipline, competitions]) => 
            <Box key={discipline} >
                <Typography 
                    variant="h1"
                    color="multiplex.darkblue" 
                >
                        {Object.entries(orderedStructure).length > 1 && discipline_all.find(d => d._id === discipline).name}
                </Typography>
                <Box>
                    {Object.entries(competitions).map(([competitionId, days]) => {
                        return (
                            <React.Fragment key={competitionId}>
                                <Typography 
                                    sx={{mt : 2}}
                                    variant="h2" 
                                    component="div" 
                                    color="multiplex.main">
                                    {competitionMap.get(competitionId).name} {competitionMap.get(competitionId).season}
                                </Typography>
                                {days &&
                                    Object.entries(days).map(([day, dates]) => {
                                    return (
                                        <React.Fragment key={day}>
                                        <Typography 
                                            variant="daydetail" 
                                            component="div" 
                                            color="multiplex.main"
                                            sx={{ 
                                                paddingLeft: 1 ,
                                                boxShadow: '20px 20px 20px rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            {day}e Journée.
                                        </Typography>
                                            {dates && 
                                            Object.entries(dates).map(([date, matchs]) => {
                                                return (
                                                    <React.Fragment key={date}>
                                                    {isAtLeastOneMatchNavigable(matchs) && <Typography variant="h4" color="multiplex.grey" component="div" p={4} textAlign={"center"}>
                                                        Cliquer sur un match pour accéder au live
                                                    </Typography>}
                                                    <Stack sx={{ display: 'flex', alignItems: 'center', mb : 2}}>
                                                    {matchs &&
                                                        matchs
                                                        .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
                                                        .map((match, index) => (
                                                            <>
                                                            {index === 0 && <><Divider orientation="horizontal" flexItem /><Divider orientation="horizontal" flexItem /></>}
                                                            {index !== 0 && <Divider orientation="horizontal" flexItem />}
                                                            <Box
                                                            key={match.idMatch}
                                                            onClick={() => isMatchNavigable(match) && navigateToMatch(match.idMatch)}
                                                            sx={{ 
                                                                display: 'flex', 
                                                                justifyContent: 'center', 
                                                                width : '100%',
                                                                "&:hover": {
                                                                    ...(isMatchNavigable(match) && {
                                                                        bgcolor: "multiplex.darkblue",
                                                                        color: "white",
                                                                        cursor: "pointer",
                                                                      }),
                                                                },
                                                            }}
                                                        >
                                                            <MatchDisplay match={match} />
                                                        </Box>
                                                        {index !== matchs.length - 1 && <Divider orientation="horizontal" flexItem />}
                                                        {index === matchs.length - 1 && 
                                                        <><Divider orientation="horizontal" flexItem /><Divider orientation="horizontal" flexItem /></>}
                                                        </>
                                                    ))}
                                                    </Stack>
                                                    </React.Fragment>
                                                )
                                                })
                                            }
                                        </React.Fragment>
                                        )
                                    })
                                }
                                </React.Fragment>
                                )
                            })}
                    </Box>
                </Box>
                )}
            </Box>

}

export default MatchsDisplay