import SportDTO from "./SportDTO.js"

class DisciplineDTO {

    constructor(disciplineData){
        if(!disciplineData)
            return

        const {_id, idName, label, provider, sport} = disciplineData

        this._id = _id
        this.idName = idName
        this.label = label

        this.provider = provider

        this.sport = new SportDTO(sport)
    }
}

export default DisciplineDTO