import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";

const GenericNavigation = ({ liveDates, activeDate, setActiveDate, itemRenderer }) => {
    
  
  const [anchorEl, setAnchorEl] = useState(null);
    const [isPreviousMenu, setIsPreviousMenu] = useState(false);
  
    const handleClick = (date) => {
      setActiveDate(date);
    };
  
    const handleMenuOpen = (event, isPrevious) => {
      setAnchorEl(event.currentTarget);
      setIsPreviousMenu(isPrevious);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    const handleDateSelect = (date) => {
      setActiveDate(date);
      handleMenuClose();
    };
  
    const activeIndex = liveDates.findIndex((date) => date === activeDate);
    const previousDates = liveDates.slice(0, activeIndex).reverse().slice(0, 5).reverse();
    const nextDates = liveDates.slice(activeIndex + 1, activeIndex + 6);
  
    const visibleDates = liveDates.slice(
      Math.max(0, activeIndex - 1),
      activeIndex + 2
    );
  
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Box
          component="div"
          onClick={(event) => {
            if (activeIndex !== 0) {
              handleMenuOpen(event, true);
            }
          }}
          p={1}
          width={4}
          mr={.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: activeIndex === 0 ? "background.default" : "background.default",
            borderRadius: "10px 0 0 10px",
            "&:hover": {
              bgcolor: activeIndex === 0 ? 'background.default' : 'multiplex.darkblue',
              color: activeIndex === 0 ? 'inherit' : 'white',
              cursor: activeIndex === 0 ? 'default' : 'pointer',
            },
          }}
      >
        <Typography variant="subtitle2">
          <FontAwesomeIcon icon={anchorEl && isPreviousMenu ? faChevronDown : faChevronLeft} />
        </Typography>
      </Box>
  {visibleDates.map((date, index) => (
    <Box
      key={index}
      textAlign="center"
      cursor="pointer"
      bgcolor={date === activeDate ? "multiplex.darkblue" : "background.default"}
      color={date === activeDate ? "background.paper" : "text.primary"}
      borderRadius={index===0 ? "0 10px 10px 0" : index===2 ? "10px 0 0 10px" : "10px"}
      mr={.5}
      p={1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => handleClick(date)}
    >
      <Typography variant="subtitle2">{itemRenderer(date)}</Typography>
    </Box>
  ))}
  <Box
          component="div"
          disabled={activeIndex === liveDates.length - 1}
          onClick={(event) => {
            if (activeIndex !== liveDates.length - 1) {
              handleMenuOpen(event, false);
            }
          }}
          width={4}
          p={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: activeIndex === liveDates.length - 1 ? "background.default" : "background.default",
            borderRadius: "0 10px 10px 0",
            "&:hover": {
              bgcolor: activeIndex === liveDates.length - 1 ? 'background.default' : 'multiplex.darkblue',
              color: activeIndex === liveDates.length - 1 ? 'inherit' : 'white',
              cursor: activeIndex === liveDates.length - 1 ? 'default' : 'pointer',
            },
          }}
      >
        <Typography variant="subtitle2">
          <FontAwesomeIcon icon={anchorEl && !isPreviousMenu ? faChevronDown : faChevronRight} />
        </Typography>
      </Box>
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: isPreviousMenu ? "left" : "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: isPreviousMenu ? "left" : "right",
    }}
    sx={{
      "& .MuiMenu-paper": {
        minWidth: "unset",
        width: "fit-content",
      },
      "& .MuiMenuItem-root": {
        justifyContent: "center",
      },
    }}
  >
    {(isPreviousMenu ? previousDates : nextDates).map(
      (date) => (
        <MenuItem 
          key={date} 
          onClick={() => handleDateSelect(date)}
          sx={{
            bgcolor: "background.default",
            "&:hover": {
              bgcolor: "multiplex.lightgrey",
              cursor: "pointer",
            },
          }}
        >
          <Typography variant="subtitle2">{itemRenderer(date)}</Typography>
        </MenuItem>
      )
    )}
  </Menu>
</Box>
    );
  };

export default GenericNavigation