const images = require.context('../../images', true, /\.png$/);

export const getImage = (imageUrl, alt='column_logo', width = 20) => {

    try {
      const image = images(`./${imageUrl}`);
      return <img src={image} width={width} style={{maxWidth : '20px'}} alt={alt}></img>;
    } catch (e) {
      return null; // ou retourner une image par défaut
    }
  }