import React, { useEffect, useMemo, useState } from "react"
import { notation_custoquestion_all } from "../../../../../bdd/bddSTUB.js"
import { getPlayerById, getPlayerNumber, getTeamInfoById } from "../../sports/genericUtils.js"
import { Box, Button, Card, CardContent, CardMedia, Divider, Slider, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { addNote, pendingNote } from "../../../../../actions/notation.js"
import DoneIcon from '@mui/icons-material/Done';
import BoxMedia from "../../items/fragments/BoxMedia.js"
import BoxPlayer from "../../items/fragments/BoxPlayer.js"
import { styled } from '@mui/material/styles';

function valuetext(value) {
    return `${value}`;
}
const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    }
];

const MediaNotation = ({item, match}) => {

    const {_id, 
        creation, 
        details, 
        timer, 
        period, 
        customQuestion, 
        globalTarget, 
        preciseTarget, 
        exactTarget} = item


    const question = notation_custoquestion_all.find(c => c._id === customQuestion)
    const notedTeam = getTeamInfoById(globalTarget, match)
    const notedPlayers = exactTarget.map(id => getPlayerById(id, match))

    const getNotesTarget = (target) => {

        if(!target)
            return details[0] ? details[0].notes : []


        let existingDetail = details.find(d => d.target === target)

        if(existingDetail){
            return existingDetail.notes
        }
            
        return []
    }

    const notationLabel = () => {
        if(question){
            return question.name
        }else if(match.type === 'actu'){
            return customQuestion
        }else if(notedPlayers && notedPlayers.length > 0){
            return notedPlayers.length===1 ? "Quelle note attribuez-vous au joueur suivant ?" : "Quelle note attribuez-vous aux joueurs suivants ?"
        }else if(notedTeam){
            return "Quelle note attribuez-vous au match de "+notedTeam.name+" ?"
        }else{
            return "Quelle note attribuez-vous au match ?"
        }
    }

    const notationContent = () => {
        if(notedPlayers && notedPlayers.length > 0){
            return notedPlayers.map((p, idx) => <React.Fragment key={idx}><NotationPlayer player={p} team={notedTeam}/><NotationModule idNotation={_id} notes={getNotesTarget(p._id)} player={p} discipline={match.discipline}/>{idx+1<notedPlayers.length && <Divider sx={{margin : 3}} />}</React.Fragment>)
        }else{
            return <NotationModule idNotation={_id} notes={getNotesTarget()}/>
        }
    }

    

    return <BoxMedia media={item}>
                    <Typography variant="mediaH2"  component="div">
                        {notationLabel()}
                    </Typography>
                    {notationContent()}
    </BoxMedia>

    

}

const NotationModule = ({idNotation, notes : initialNotes = [], player = {_id : 'unique'}}) => {

    const getActualNote = () => {
        if(notationState.notationsVoted.get(idNotation+'_'+player._id)!==undefined)
            return notationState.notationsVoted.get(idNotation+'_'+player._id)
        
        if(notationState.notationsPending.get(idNotation+'_'+player._id)!==undefined)
            return notationState.notationsPending.get(idNotation+'_'+player._id)

        return 5
    }


    const dispatch = useDispatch()

    const notationState = useSelector((state) => state.notation)

    const [note, setNote] = useState(getActualNote())

    const [notes, setNotes] = useState(initialNotes)

    const [average, setAverage] = useState((initialNotes.reduce((a, b) => a + b, 0) / initialNotes.length).toFixed(1))

    const [voted, setVoted] = useState(false)

    useEffect(() => {
        setVoted(notationState.notationsVoted.get(idNotation+'_'+player._id)!==undefined)
    }, [notationState])


    const handleChange = (_, newValue) => {
        setNote(newValue)
        dispatch(pendingNote(idNotation, player, newValue))
    }

    const handleVote = () => {
        dispatch(addNote(idNotation, player, note))
        setAverage(((notes.reduce((a, b) => a + b, 0) + note) / (notes.length+1)).toFixed(1))
        setNotes(s => [...s, note])
    }

    return voted ? <Box sx={{ textAlign: 'center', marginTop : 2}}>
    <Box sx={{display : 'flex', justifyContent : 'space-between'}}>
        <Typography variant="subtitle1" color="text.secondary" component="div">{notes.length} vote(s)</Typography>
        <Typography component="div" variant="h6">Note des participants : {average}/10</Typography>
        <Typography variant="subtitle1" color="black" component="div">Votre note : {note}/10</Typography>
    </Box>
    
</Box>
: <Box sx={{ textAlign: 'center'}}>
        <CustomizedSlider 
            onChange={handleChange}
            aria-label="Small steps"
            getAriaValueText={valuetext}
            value={note}
            step={1}
            marks={marks}
            min={0}
            max={10}
            valueLabelDisplay="auto"
        />
        <Button disabled={notationState.notationsPending.get(idNotation+'_'+player._id)===undefined} onClick={handleVote} variant="contained" endIcon={<DoneIcon />}>
            Valider
        </Button>
    </Box>
}

const NotationPlayer = ({player, discipline, team}) => {
    return <BoxPlayer player={player} width={40} height={40} color={team.color}/>
}

// Définir un style personnalisé pour le Slider
const CustomSlider = styled(Slider)({
    // Styles du curseur
    '& .MuiSlider-thumb': {
      width: "45px", // Longueur personnalisée du curseur
      height: "10px", // Hauteur personnalisée du curseur
      borderRadius : '10px',
      boxShadow: 'none', // Supprimer l'ombre au survol
    },
    '& .MuiSlider-mark': {
        marginTop: 8, // Décalage vertical pour descendre les marqueurs
    },
  });

// Composant Slider personnalisé
const CustomizedSlider = ({
    onChange,ariaLabel,
    getAriaValueText,
    value,
    step,
    marks,
    min,
    max,
    valueLabelDisplay
}) => {
    return (
      <CustomSlider
        onChange={onChange}
        aria-label={ariaLabel}
        getAriaValueText={getAriaValueText}
        value={value}
        step={step}
        marks={marks}
        min={min}
        max={max}
        valueLabelDisplay={valueLabelDisplay}
      />
    );
  };



export default MediaNotation