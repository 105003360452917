import { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ActuView from "./ActuView.js";
import { getPaginedMatchDetail } from "../../../../api/index.js";
import { GET_MATCH_DETAILS } from "../../../../constants/actionTypes.js";

const ActuResolver = () => {

    const { actu } = useParams();
    const dispatch = useDispatch()

    const {live, getIdActuFromActuName} = useOutletContext();

    const idActu = useMemo(() => {
      return getIdActuFromActuName(actu)

    }, [getIdActuFromActuName, actu])

    const actuDetail = useSelector((state) => state.live.actualitesDetails && state.live.actualitesDetails[0]);

    useEffect(() => {
      if(!idActu)
        return
        
        /*const fetchMatchDetail = async () => {
          try {
            const response = await getMatchDetail(idMatch);
            dispatch({ type: GET_MATCH_DETAILS, payload: response.data})
              
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchMatchDetail();*/
      }, [dispatch, idActu]);

      const loadMore = async () => {

        try {
          const response = await getPaginedMatchDetail(actuDetail.idActu, actuDetail.pagination)
          dispatch({ type: GET_MATCH_DETAILS, payload: response.data})
            
        } catch (error) {
          console.error(error);
        }
      }

    if (!actuDetail) {
        return <CircularProgress />;
    }

    return <ActuView actu={actuDetail} loadMore={loadMore} />
    //return <MatchPreview match={matchDetail} />
}

export default ActuResolver