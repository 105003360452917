import { Box } from "@mui/material"

const BoxItem = ({color = "#E53E3E", lisiere = false, children}) => {

    return <Box
        sx={{
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        borderLeft: lisiere ? '5px solid '+color : 'none', // Liseré rouge
        }}
    > {children}
  </Box>

}

export default BoxItem