import { Typography } from "@mui/material"
import { useMatchStatusResolver } from "../../hooks/useMatchStatusResolver.js"
import { useScoreResolver } from "../../hooks/useScoreResolver.js"
import { useMemo } from "react"

const MatchScore = ({match}) => {

    const [homeScore, awayScore] = useScoreResolver(match)
    const {isMatchStarted, isMatchEnded, isChronoRunning, isHalfTime} = useMatchStatusResolver(match)

    const isMatchNoScore = () => !homeScore && !awayScore

    const matchHour = useMemo(() => {
        const dateObject = new Date(match.dateTime);

        // Obtenir l'heure et les minutes de l'objet de date dans le fuseau horaire du client
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();

        // Formater l'heure et les minutes en utilisant des zéros de remplissage si nécessaire
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        return formattedTime;
    }, [match.dateTime])

    if((isMatchStarted || !isMatchNoScore()) && !isMatchEnded)
        return (<Typography variant="h3"
        sx={{
            color: isChronoRunning ? "multiplex.chrono" : "multiplex.mitemps",
          }}
        >{`${homeScore} - ${awayScore}`}</Typography>)

    if(isMatchEnded)
        return (<Typography variant="h3">{`${homeScore} - ${awayScore}`}</Typography>)

    return <Typography variant="h3">{matchHour}</Typography>
}

export default MatchScore