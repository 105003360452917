import { getPlayerById, getTeamInfoById, getVisualTimer } from "../../../sports/genericUtils.js"
import GenericScore from "../generic/GenericScore.js";


const SoccerBut = ({item, match, icon}) => {

    const {text, title, team, buteur, period, timer, passeur, butSucced} = item.detail
    
    const visualTimer = getVisualTimer(timer, period, match.discipline)
    const teamDetail = getTeamInfoById(team, match)
    const playerDetail = buteur === 'csc' ? 'csc' : getPlayerById(buteur, match)
    const passeurDetail = getPlayerById(passeur, match)

    return (
      <GenericScore 
        teamDetail={teamDetail} 
        playerDetail={playerDetail} 
        visualTimer={visualTimer} 
        icon={icon}
        title={title}
        text={text}
        succed={butSucced}
      />
      );
}

export default SoccerBut