import React from 'react';
import { Container, Paper, Typography, CircularProgress } from '@mui/material';
import useViewLoader from '../hooks/useViewLoader.js';
import { getViewCompetition, getViewToken } from '../../../../api/index.js';
import useViewToken from '../hooks/useViewToken.js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const CompetitionView = ({ }) => {

  const { competition, journee } = useParams();

  const { token, expiresIn, fetchToken } = useViewToken()

  const { loading, data, error, loadData } = useViewLoader(getViewCompetition, competition && competition.split("_")[1], journee && journee.split("-")[1]);

  useEffect(() => {
      // Appelez la fonction init() du bundle avec les données
      if(data)
        window.dsasViewClassementResultat(data);
  }, [data])

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <div id="dsas-view"></div>

        {loading && (
          <CircularProgress />
        )}

        <Typography variant="h4" component="h2" gutterBottom>
        </Typography>
      </Paper>
    </Container>
  );
};

export default CompetitionView;