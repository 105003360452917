import { useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import useViewToken from './useViewToken.js';

const useViewLoader = (apiFunction, params) => {

  const { token, expiresIn, fetchToken } = useViewToken()

  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState('');
  const [error, setError] = useState(null);

  const loadData = async (params) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiFunction(params);
      const apiContent = response.data;
      setData(apiContent);

    } catch (error) {
      localStorage.removeItem('token')
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData(params)
  }, [, token]);

  const FormCredential = ({fetchToken}) => {
    const [credentials, setCredentials] = useState({})

    const handleChange = (e) => {
      const {name, value} = e.target
  
      setCredentials(c => ({...c, [name] : value}))
    }
    return (
      <Box sx={{display : 'flex', flexDirection : 'row', alignItems : 'center', justifyContent : 'center'}}>
          <TextField name="clientId" label="ID" onChange={handleChange} value={credentials.clientId}/>
          <TextField name="clientSecret" label="SECRET" onChange={handleChange} value={credentials.clientSecret}/>
          <Button variant={'contained'} onClick={() => fetchToken(credentials.clientId, credentials.clientSecret)}>GO</Button>
      </Box>
    )
}

  return { loading, data, error, loadData, formCredentials : <FormCredential fetchToken={fetchToken}/> };
};

export default useViewLoader;