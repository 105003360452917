import React, {useState} from 'react';
import { Box, Button, Typography, List, ListItem, ListItemText, Popover } from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';

export default function Filter({selectedItem = "", setSelectedItem, items, clear, label = "Tous"}) {

  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleChange = (event) => {
    handleClose();

    if(!event){
      clear()
      return
    }
    
    setSelectedItem(event);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{display : 'flex', alignItems : 'center', width : '100%', justifyContent : 'center', padding:'0.5em'}}>
      
      <Button
        onClick={handleClick}
        sx={{
          p : 0,
          borderRadius : '20px',
          textTransform: 'none',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          bgcolor: '#E5E7E9', 
          boxShadow : '4px 4px 10px rgb(200,200, 200)',
          color : '#4A5168',
          boxSizing: 'initial',
        }}
      >
        <Typography sx={{bgcolor : 'white', p : 1, m : 0, height : 20, borderRadius : '10px 0 0 10px ', display: 'flex', alignItems: 'center', boxSizing: 'initial', minWidth: 'max-content' }}>{selectedItem || label}</Typography>
        <Box sx={{ width: 5 }} /> {/* Ajoute un espace de 16px */}
        <Typography sx={{bgcolor : 'white', p : 1, m : 0, height : 20, borderRadius : '0 10px 10px 0', display: 'flex', alignItems: 'center', boxSizing: 'initial' }}><ArrowDropDown sx={{p : 0}} /></Typography>
        
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem button key={-1} onClick={() => handleChange(undefined)}>
              <ListItemText primary={label} />
            </ListItem>
          {items.map((option, index) => (
            <ListItem button key={index} onClick={() => handleChange(option)}>
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
}
