import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

export const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'iframe',
    'twitter',
    'width',
    'code',
    'color',
    'background',
    'code-block',
    'align',
    'float',
    'margin',
    'display',
    "font",
  ];

  const COLORS = [
    "#000000", "#e60000", "#ff9900", "#ffff00", "#008A00", "#0066cc", "#9933ff",
    "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
    "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
    "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
    "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"
  ];

export const Toolbar = ({readOnly}) => {
  if(readOnly)
    return ''
  
  return <div id="toolbar-container">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>

          <button class="ql-blockquote"></button>
          <button class="ql-code-block"></button>

          <select className="ql-header">
            <option value="1"></option>
            <option value="2"></option>
            <option value="3"></option>
            <option value="4"></option>
            <option value="5"></option>
            <option value="6"></option>
            <option value="false"></option>
          </select>

          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>

          <button class="ql-indent" value="-1"></button>
          <button class="ql-indent" value="+1"></button>

          <button class="ql-direction" value="rtl"></button>

          <select className="ql-color">
            <option value="rgb(0, 0, 0)"></option>
            <option value="rgb(230, 0, 0)"></option>
            <option value="rgb(255, 153, 0)"></option>
            <option value="rgb(255, 255, 0)"></option>
            <option value="rgb(0, 138, 0)"></option>
            <option value="rgb(0, 102, 204)"></option>
            <option value="rgb(153, 51, 255)"></option>
            <option value="rgb(255, 255, 255)"></option>
            <option value="rgb(250, 204, 204)"></option>
            <option value="rgb(255, 235, 204)"></option>
            <option value="rgb(255, 214, 153)"></option>
            <option value="rgb(255, 224, 204)"></option>
            <option value="rgb(255, 204, 204)"></option>
            <option value="rgb(204, 224, 245)"></option>
            <option value="rgb(235, 214, 255)"></option>
            <option value="rgb(187, 187, 187)"></option>
            <option value="rgb(102, 185, 102)"></option>
            <option value="rgb(0, 102, 204)"></option>
            <option value="rgb(204, 204, 255)"></option>
          </select>

          <select className="ql-background">
            <option value="rgb(0, 0, 0)"></option>
            <option value="rgb(230, 0, 0)"></option>
            <option value="rgb(255, 153, 0)"></option>
            <option value="rgb(255, 255, 0)"></option>
            <option value="rgb(0, 138, 0)"></option>
            <option value="rgb(0, 102, 204)"></option>
            <option value="rgb(153, 51, 255)"></option>
            <option value="rgb(255, 255, 255)"></option>
            <option value="rgb(250, 204, 204)"></option>
            <option value="rgb(255, 235, 204)"></option>
            <option value="rgb(255, 214, 153)"></option>
            <option value="rgb(255, 224, 204)"></option>
            <option value="rgb(255, 204, 204)"></option>
            <option value="rgb(204, 224, 245)"></option>
            <option value="rgb(235, 214, 255)"></option>
            <option value="rgb(187, 187, 187)"></option>
            <option value="rgb(102, 185, 102)"></option>
            <option value="rgb(0, 102, 204)"></option>
            <option value="rgb(204, 204, 255)"></option>
          </select>

          <select className="ql-font">
            <option value='Poppins, sans-serif'></option>
            <option value="Georgia"></option>
            <option value="Impact"></option>
            <option value="Tahoma"></option>
            <option value="Verdana"></option>
          </select>

          <select className="ql-align">
            <option value=""></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>

          <button class="ql-link"></button>
          <button class="ql-image"></button>
          {/** ce bouton fonctionne comme le link mais créée une iframe par défaut avec le lien saisi */}
          <button class="custom-button ql-iframe">Ifr</button>

          <button class="custom-button ql-twitter">Twit</button>

          <button class="ql-clean"></button>
        </div>
}

export const container = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],    // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
    // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean'] ,                                        // remove formatting button
    ['link', 'image', 'iframe'] // Ajouter le bouton d'insertion d'image
]