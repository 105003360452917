export const notation_custoquestion_all = [
    { _id : '01', name : 'Sur une échelle de 0 à 10, de 0 - aucune difficulté à 10 - énormément de difficulté, avez-vous des difficultés à comprendre le sujet traité ?'}, 
    { _id : '02', name : 'Sur une échelle de 0 à 10, de 0 - aucune connaissances à 10 - énormément de connaissances, avez-vous des connaissances sur le sujet traité ?'},
]

export const discipline_all = [
    { _id : 'rugby', name : 'Rugby'}, 
    { _id : 'soccer', name : 'Football'},
    { _id : 'basket', name : 'Basketball'},
    { _id : 'handball', name : 'Handball'},
]

export const discipline_actu_all = [
    { _id : 'economie', name : 'Économie'},
    { _id : 'environnement', name : 'Environnement'},
    { _id : 'education', name : 'Éducation'},
    { _id : 'sport', name : 'Sport'},
    { _id : 'societe', name : 'Société'},
    { _id : 'science', name : 'Science'},
    { _id : 'international', name : 'International'},
    { _id : 'culture', name : 'Culture'},
    { _id : 'santé', name : 'Santé'},
    { _id : 'politique', name : 'Politique'},
    { _id : 'technologie', name : 'Technologie'},
]


export const seasons_all = [
    { _id : '2020/2021', name : '2020/2021'}, 
    { _id : '2021/2022', name : '2021/2022'}, 
    { _id : '2022/2023', name : '2022/2023'}, 
]


export const getDays = (nbDays = 25, nbTeamFinalPhase = 0) => {
    const days = []

    if(nbTeamFinalPhase>1){
        days.unshift({ _id : 'final', name : 'final'})
    }

    if(nbTeamFinalPhase>2){
        days.unshift({ _id : 'semi-final', name : 'semi-final'})
    }

    if(nbTeamFinalPhase>4){
        days.unshift({ _id : 'quarter-final', name : 'quarter-final'})
    }

    if(nbTeamFinalPhase>8){
        days.unshift({ _id : 'eighth-final', name : 'eighth-final'})
    }

    for(var i = nbDays ; i >= 1 ; i--){
        days.unshift({ _id : i, name : i})
    }

    return days
}
