export const loadTwitterScript = () => {
  return new Promise((resolve, reject) => {
    if (window.twttr && window.twttr.widgets) {
      resolve(true);
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.onload = () => {
      setTimeout(() => {
        if (window.twttr && window.twttr.widgets) {
          resolve(true);
        } else {
          reject(new Error('Twitter widgets script loaded, but window.twttr.widgets is undefined.'));
        }
      }, 100);
    };
    script.onerror = () => reject(new Error('Failed to load Twitter script'));
    document.head.appendChild(script);
  });
};