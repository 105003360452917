import { combineReducers } from 'redux'

import players from './players.js'
import competitions from './competitions.js'
import categories from './categories.js'
import teams from './teams.js'
import matchs from './matchs.js'
import actulives from './actulives.js'
import live from './live.js'
import matchDetail from './matchDetail.js'
import lives from './lives.js'
import gameFacts from './gameFacts.js'
import notation from './notation.js'
import survey from './survey.js'
import quizz from './quizz.js'
import article from './article.js'
import socket from './socket.js'

export default combineReducers({
    players,
    competitions,
    categories,
    teams,
    matchs,
    actulives,
    live,
    matchDetail,
    lives,
    gameFacts,
    notation,
    survey,
    quizz,
    article,
    socket
})