//TODO MOVE INTO CONSTANT FILE
const DEBUT = 'Début';
const FIN = 'Fin';
const NON_DEMARRE = 'Non-démarré';
const MI_TEMPS_1 = '1ère période';
const MI_TEMPS_2 = '2ème période';
const PROLONGATION = 'Prolongation';
const PERIODE_1 = '1ère prolongation';
const PERIODE_2 = '2ème prolongation';
const TIRS_AU_BUT = 'Tirs au but';
const TERMINE = 'Match terminé';

const TYPE_MAIN = 'main';
const TYPE_SUB = 'sub';
const TYPE_OTHER = 'other';

const COMPO_CONSTANTS = {
    BASKET: {name:'basket5',holdersNumber:5},
    HANDBALL: {name:'handball7',holdersNumber:7},
    SOCCER: {name:'442',holdersNumber:11},
    RUGBY: {name:'rugby15',holdersNumber:15}
};

/**
 * Model for Match's Period
 * @param {string} name : Period's name
 * @param {string} periodType : Period's type (/ex: main, sub)
 * @param {int} minDuration : Period's default duration in minutes at period's start
 * @param {boolean} isPlayable : is Period a playable or technical period in game (ex: playable period:1st Half, or technical period: 1st Half end)
 * @param {int} playedStepInGame : Period's step in game for Period played 
 * @param {int} matchPeriod : Period's step in match (all steps)
 * @param {int} periodPhase : Period's phase in game
 * 
 *  
 * 
 * Usage: 
 * - let firstHalf = new PeriodModel('1st Half', 45, true, false); --> period creation
 * - firstHalf.display(); --> Outputs the details of the period
 * - firstHalf.updatePeriod('1st Half Extended', 60, true, false); --> Updating the period information
 * 
 */
class PeriodModel {
    constructor(name, periodType, minDuration, isPlayable, playedStepInGame, matchPeriod, periodPhase) {
        this.name = name;
        this.minDuration = minDuration;
        this.isPlayable = isPlayable;
        this.playedStepInGame = playedStepInGame;
        this.periodType = periodType;
        this.matchPeriod = matchPeriod;
        this.periodPhase = periodPhase;
    }

    updatePeriod(name, periodType, minDuration, isPlayable, playedStepInGame, matchPeriod, periodPhase) {
        this.name = name;
        this.minDuration = minDuration;
        this.isPlayable = isPlayable;
        this.playedStepInGame = playedStepInGame;
        this.periodType = periodType;
        this.matchPeriod = matchPeriod;
        this.periodPhase = periodPhase;
    }

    display() {
        console.log(`Period: ${this.name}, periodType: ${this.periodType}, minDuration: ${this.minDuration} minutes, isPlayable: ${this.isPlayable}, playedStepInGame: ${this.playedStepInGame} matchPeriod: ${this.matchPeriod}, periodPhase:${this.periodPhase}`);
    }
}

export const rugbyRules = {
    periodDuration : {
        main : 40,
        sub : 10,
    },
    getMinTotalDuration : function(period){ 
        return period <= 2 ? 
            (period) * this.periodDuration.main : 
            2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub
    },
    getActualPeriodDuration : function(period){ 
        return period <= 2 ? 
            this.periodDuration.main : 
            this.periodDuration.sub
    },
    getTotalTimer : function(period, timer){
        const minutes = timer / 60;
        const totalMin = period <= 2 ? 
            period * this.periodDuration.main + minutes :
            2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub + minutes
        return totalMin * 60
    },
    getTimerList : function(period, maxTimer){
        const minTimer = period < 2 ? period * this.periodDuration.main : 2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub

        let timerList = []

        if(period === 2)
            timerList.push({id : 0, value : this.periodDuration.main * 2})
        else if(period === 5 || period === 6)
            timerList.push({id : 0, value : this.periodDuration.main * 2 + this.periodDuration.sub * 2})
        else
            for(var i = 0 ; i <= maxTimer ; i++){
                timerList.push({id : i, value : i + minTimer})
            }

        return timerList
    }
}

export const soccerRules = {
    periodDuration : {
        main : 45,
        sub : 15,
    },
    getMinTotalDuration : function(period){ 
        return period <= 2 ? 
            (period) * this.periodDuration.main : 
            2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub
    },
    getActualPeriodDuration : function(period){ 
        return period <= 2 ? 
            this.periodDuration.main : 
            this.periodDuration.sub
    },
    getTotalTimer : function(period, timer){
        const minutes = timer / 60;
        const totalMin = period <= 2 ? 
            period * this.periodDuration.main + minutes :
            2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub + minutes
        return totalMin * 60
    },
    getTimerList : function(period, maxTimer){
        const minTimer = period < 2 ? period * this.periodDuration.main : 2 * this.periodDuration.main + (period - 3) * this.periodDuration.sub

        let timerList = []

        if(period === 2)
            timerList.push({id : 0, value : this.periodDuration.main * 2})
        else if(period === 5 || period === 6)
            timerList.push({id : 0, value : this.periodDuration.main * 2 + this.periodDuration.sub * 2})
        else
            for(var i = 0 ; i <= maxTimer ; i++){
                timerList.push({id : i, value : i + minTimer})
            }

        return timerList
    }
}

export const basketRules = {
    periodDuration : {
        main : 10,
    },
    getMinTotalDuration : function(period){ 
        return period * this.periodDuration.main
    },
    getActualPeriodDuration : function(period){ 
        return this.periodDuration.main
    },
    getTotalTimer : function(period, timer){
        const minutes = timer / 60;
        const totalMin = period * this.periodDuration.main + minutes
        return totalMin * 60
    },
    getTimerList : function(period, maxTimer){
        const minTimer = period * this.periodDuration.main

        let timerList = []

        for(var i = 0 ; i <= maxTimer ; i++){
            timerList.push({id : i, value : i + minTimer})
        }

        return timerList
    }
}

export const handballRules = {
    periodDuration: {
        main: 30,  // Principale : durée de chaque mi-temps (minutes)
        sub: 10, 
        other: 0   // Prolongations: durée de chaque
    },

    getHandballPeriods: function() {
        return new Map([
        // Period: ${this.name}, periodType: ${this.periodType}, minDuration: ${this.minDuration} minutes, isPlayable: ${this.isPlayable}, 
        //playedStepInGame: ${this.playedStepInGame} matchPeriod: ${this.matchPeriod}, periodPhase:${this.periodPhase}
        [0, new PeriodModel(NON_DEMARRE, TYPE_OTHER, 0, false, null,0,'start')],
        [1, new PeriodModel(MI_TEMPS_1, TYPE_MAIN, 0, true, 1, 1,'mt')],
        [2, new PeriodModel(FIN + ' ' + MI_TEMPS_1, TYPE_OTHER, this.periodDuration.main, false, null, 2,'mt_end')],
        [3, new PeriodModel(MI_TEMPS_2, TYPE_MAIN, this.periodDuration.main, true, 2, 3,'mt2')],
        [4, new PeriodModel(FIN + ' ' + MI_TEMPS_2, TYPE_OTHER, this.periodDuration.main * 2, false, null, 4,'mt2_end')],
        [5, new PeriodModel(PERIODE_1, TYPE_SUB, this.periodDuration.main * 2, true, 3, 5,'ot')],
        [6, new PeriodModel(FIN + ' ' + PERIODE_1, TYPE_OTHER, this.periodDuration.main * 2 + this.periodDuration.sub, false, null, 6,'ot_end')],
        [7, new PeriodModel(PERIODE_2, TYPE_SUB, this.periodDuration.main * 2 + this.periodDuration.sub, true, 4, 7,'ot2')],
        [8, new PeriodModel(FIN + PERIODE_2, TYPE_OTHER, this.periodDuration.main * 2 + this.periodDuration.sub * 2, false, null, 8,'ot2_end')],
        [9, new PeriodModel(TIRS_AU_BUT, TYPE_OTHER, this.periodDuration.main * 2 + this.periodDuration.sub * 2, true, 5, 9,'so')],
        [10, new PeriodModel(TERMINE, TYPE_OTHER, this.periodDuration.main * 2 + this.periodDuration.sub * 2, false, null, 10,'end')]
    ])},

    getMinTotalDuration: function(period){ 
       //TODO nullable
       return this.getHandballPeriods().get(period).minDuration;
    },
    getActualPeriodDuration: function(period){ 
        //TODO nullable
        let periodType = this.getHandballPeriods().get(period).periodType;
        let duration = this.periodDuration[periodType];
        return duration;
    },
    getTotalTimer: function(period, timer){
        // Temps total en secondes (avec timer en cours)
        const minutes = timer / 60;
        const totalMin = this.getMinTotalDuration(period) + minutes;
        return totalMin * 60;
    },
    getTimerList: function(period, maxTimer){
        // Génère une liste de valeurs de timer pour la période donnée
        let minTimer = this.getMinTotalDuration(period);

        let timerList = [];

        for(var i = 0; i <= maxTimer; i++){
            timerList.push({id: i, value: i + minTimer});
        }

        return timerList;
    },

    getPeriodByMatchStep: function(step){
        return this.getHandballPeriods().get(step);
    },

    getPeriodByType: function(type){
        //TODO nullable
        const periods = this.getHandballPeriods();

        for (const [key, periodModel] of periods) {
            if (periodModel.periodPhase === type) {
                return periodModel;
            }
        }
    },

    getPlayedGamesPeriods: function() {
        const allPeriods = this.getHandballPeriods();
        let playablePeriods = new Map();
    
        // Iterate over all periods and add the playable ones to the playablePeriods array
        for (const [key, periodModel] of allPeriods) {
            if (periodModel.isPlayable) {
                playablePeriods.set(key, periodModel);
            }
        }
    
        // Logging the count for debugging purpose
       // console.log('Playable Periods Count = ', playablePeriods.length);
    
        return playablePeriods;
    },

    getSurchagePeriod: function(currentPeriod) {

        const allPlayablePeriods = this.getPlayedGamesPeriods();
        const allPeriods = this.getHandballPeriods();

        if (allPeriods.get(currentPeriod).isPlayable){
            return allPeriods.get(currentPeriod);
        }

        for (const [key, value] of allPlayablePeriods) {
            if (key > currentPeriod) {
                return value;
                }
            }
        }

}