import {Quill} from 'react-quill';

// Importez la classe BlockBlot de Quill
const EmbedBlot = Quill.import('blots/embed');

// Créez une nouvelle classe Blot pour l'iframe
class IframeBlot extends EmbedBlot  {
  static create(value) {
    window.buildIframeScripts()
    const node = super.create(value);
    node.setAttribute('src', value.iframe);
    node.setAttribute('style', 'display: block; width: 100%; overflow: auto; margin: auto; border-width: 0px;');
    node.setAttribute('scrolling', 'no');

    return node;
  }

  static value(node) {
    return {
      iframe: node.getAttribute('src'),
    };
  }

  static formats(node) {
    return node.getAttribute('iframe')
  }
}

IframeBlot.blotName = 'iframe'
IframeBlot.tagName = 'IFRAME'

Quill.register(IframeBlot)

export default IframeBlot