import { CREATE_GAMEFACT, DELETE_GAMEFACT, UPDATE_GAMEFACT } from '../constants/actionTypes.js'

export default (gameFacts = [], action) => {
    switch(action.type){
        case CREATE_GAMEFACT:
            return [...gameFacts, action.payload]
        case UPDATE_GAMEFACT:
            return gameFacts.map(gf => gf._id === action.payload._id ? action.payload : gf)
        case DELETE_GAMEFACT:
            return gameFacts.filter(gf => gf._id !== action.payload)
        default :
            return gameFacts
    }
}