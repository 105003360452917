import React, { createContext, useState, useContext } from 'react';
import { createTheme } from '@mui/material';
import { themes } from './themes/themes.js';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(createTheme(themes['default']));

  const changeTheme = (newTheme) => {
    setTheme(createTheme(newTheme));
  };

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };