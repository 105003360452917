import { getPlayerById, getTeamInfoById, getVisualTimer } from "../../../sports/genericUtils.js"
import GenericScore from "../generic/GenericScore.js";

const RugbyTransformation = ({item, match, icon}) => {

    const {text, title, team, transformer, transformSucced, period, timer} = item.detail
    
    const visualTimer = getVisualTimer(timer, period, match.discipline)
    const teamDetail = getTeamInfoById(team, match)
    const playerDetail = getPlayerById(transformer, match)

    return (
      <GenericScore 
      teamDetail={teamDetail} 
      playerDetail={playerDetail} 
      visualTimer={visualTimer} 
      icon={icon}
      title={title}
      text={text}
      succed={transformSucced}
      />
      );
}

export default RugbyTransformation