import { useEffect, useState } from 'react';
import { useNavigateTo } from '../../../v1/components/live/hooks/useNavigateTo.js';
import { getClosestDate } from '../../../v1/components/live/utils.js';
import { Outlet, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getV2LiveDates } from '../../../api/index.js';

const CalendarResolver = () => {
  
  const navigateTo = useNavigateTo();
  const { id, date } = useParams();

  const [liveCompetitionsDays, setLiveCompetitionsDays] = useState(null)
  const [liveDates, setLiveDates] = useState(null);

  useEffect(() => {
    // On doit récupérer la liste des competitions du live
      const fetchData = async () => {
        try {
          const response = await getV2LiveDates(id);
          const { data } = response
          const { eventsDates } = data

          setLiveDates(eventsDates);
          //setLiveDates(["2024-07-24","2024-07-25","2024-07-27","2024-07-28","2024-07-29","2024-07-30","2024-07-31","2024-08-01","2024-08-02","2024-08-03","2024-08-04"])
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchData();

  }, [])

  useEffect(() => {
    if(liveDates && liveDates.length){
      
      // Resolve the date if it is not provided
      const resolvedDate = date ?? getClosestDate(liveDates);
  
      // Update the URL with the resolved date if necessary
      if(resolvedDate!==date)
        navigateTo(`${resolvedDate}`, {}, ['competition']);
    }

  }, [id, date, liveCompetitionsDays, liveDates, navigateTo])

  
  if (!liveDates && !liveCompetitionsDays) {
    return <CircularProgress />
  }

  return <Outlet context={{liveDates, liveCompetitionsDays}}/>;
}

export default CalendarResolver