import { Divider, Grid, List, ListItem, ListItemText, Box, Button, Typography } from "@mui/material";
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from "react";
import { getCountryFlag } from "./utils/flags.js";
import SearchFilterDTO from "../DTO/SearchFilterDTO.js";
import { getAllV2Gamefacts } from "../../api/index.js";

import medal_gold from '../images/gamefacts/medal/medal_gold.png'
import medal_silver from '../images/gamefacts/medal/medal_silver.png'
import medal_bronze from '../images/gamefacts/medal/medal_bronze.png'


const SportEventList = ({ events, selectedSport, formatDate = (date) => {
                                                                          const options = { hour: '2-digit', minute: '2-digit' };
                                                                          return new Date(date).toLocaleTimeString([], options);
                                                                        }, groupedBySport = false
                        }) => 

{
  
  const getPrimary = (event) => {

    if(event.phase?.competitionDiscipline?.discipline?.label === event.phase?.competitionDiscipline?.discipline?.sport?.label){
      return `${event.phase?.competitionDiscipline?.discipline?.sport?.label}`
    }

    let primary = `${!selectedSport ? `${event.phase?.competitionDiscipline?.discipline?.sport?.label} (${event.phase?.competitionDiscipline?.discipline?.label})` : `${event.phase?.competitionDiscipline?.discipline?.label}`}`
  
    return primary
  }

  const getSecondary = (event) => {

    if(event.phase?.epreuve?.name === event.phase?.name){
      return `${event.phase?.category?.name} - ${event.phase?.name}`
    }

    let secondary = `${event.phase?.category?.name} - ${event.phase?.epreuve?.name} - ${event.phase?.name}`
  
    return secondary
  }

  if(!events || !events.length)
    return <Typography>Aucun évenement</Typography>

  if(!groupedBySport)
  return (
    <Box style={{ maxWidth: 600, margin: 'auto'}}>
      <List>
        <Divider />
        {events.map((event, index) => (
          <React.Fragment key={event._id}>
            <ListItem sx={{ display: 'block', padding: 2 }}>

              <Grid container wrap="nowrap" alignItems='center' sx={{ display: 'block'}}>
                <Grid>
                  <ListItemText
                    sx={{marginTop : '8px'}}
                    primary={formatDate(event.date)}
                    primaryTypographyProps={{
                      fontSize: '0.9rem',
                      fontWeight : 'bold',
                      color :  "#4A5168"
                    }}
                  >
                  </ListItemText>
                </Grid>

                <Grid>
                  <ListItemText
                    sx={{marginTop : '3px'}}
                    primary={getPrimary(event)}
                    primaryTypographyProps={{
                      fontSize: '1.15rem',
                      fontWeight : 'bold',
                      color :  "#4A5168"
                    }}
                  />
                </Grid>

                <Grid>
                  <ListItemText
                    sx={{marginTop : '3px'}}
                    secondary={getSecondary(event)}
                    secondaryTypographyProps={{
                      fontSize: '1rem',
                      color :  "#4A5168"
                    }}
                  />
                </Grid>

                <Grid>
                    <Actors event={event} />  
                </Grid>
              </Grid>
            </ListItem>

            
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );


  const groupedEvents = events.reduce((acc, event) => {
    const sport = event.phase?.competitionDiscipline?.discipline?.sport?.label || 'Autres';
    if (!acc[sport]) {
      acc[sport] = [];
    }
    acc[sport].push(event);
    return acc;
  }, {});

  return (
    <Box style={{ maxWidth: 600, margin: 'auto'}}>
      {Object.keys(groupedEvents).map((sport, index) => (
        <React.Fragment key={index}>
          <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10, fontWeight: 'bold' }}>{sport}</Typography>
          <List>
            <Divider />
            {groupedEvents[sport].map((event, eventIndex) => (
              <React.Fragment key={event._id}>
                <ListItem sx={{ display: 'block', padding: 2}}>
                  <Grid container wrap="nowrap" alignItems='center' sx={{ display: 'block'}} >
                    <Box>
                      <ListItemText
                        primary={formatDate(event.date)}
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                          fontWeight: 'bold',
                          color: "#4A5168"
                        }}
                      />
                    </Box>
                      
                    <Grid>
                      <ListItemText
                        sx={{marginTop: '3px'}}
                        secondary={getSecondary(event)}
                        primaryTypographyProps={{
                          fontSize: '1.15rem',
                          fontWeight: 'bold',
                          color: "#4A5168"
                        }}
                        secondaryTypographyProps={{
                          fontSize: '1rem',
                          color: "#4A5168"
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Actors event={event} toggleAble={false} />
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Box>
  );

};

const Actors = ({event, toggleAble = true}) => {

  const participants = useMemo(() => {
    if(event.type === 'player')
      return event.players.sort((a, b) => a.lastname.localeCompare(b.lastname)).map(player => ({_id : player._id, name : player.firstname[0] + '.' + player.lastname, nationality : player.nationality, gamefacts : event.gamefacts && event.gamefacts.filter(g => (g.details.actor.player && g.details.actor.player._id === player._id))}))
    
    if(event.type === 'team')
      return event.teams.sort((a, b) => a.name.localeCompare(b.name)).map(team => ({_id : team._id, name : team.name, nationality : team.nationality, gamefacts : event.gamefacts && event.gamefacts.filter(g => (g.details.actor.team && g.details.actor.team._id === team._id))}))
  }, [event])

  // Fonction pour obtenir la priorité de tri en fonction de la médaille
  const getRankingPriority = (participant) => {
    const gamefact = participant.gamefacts && participant.gamefacts.find(g => g.idName === 'ranking')
    if (!gamefact) return 100; // Pas de ranking, priorité la plus basse

    return gamefact.type.value
    switch (gamefact.type.value) {
      case 'gold': return 0;
      case 'silver': return 1;
      case 'bronze': return 2;
      default: return 3; // Cas par défaut si le type n'est pas reconnu
    }
  };

  // Tri des participants en fonction de la médaille et de l'ordre alphabétique
  const sortedParticipants = participants.sort((a, b) => {
    const priorityA = getRankingPriority(a);
    const priorityB = getRankingPriority(b);

    if (priorityA !== priorityB) {
      // Si les priorités diffèrent, trier par priorité de médaille
      return priorityA - priorityB;
    }
    // Si les priorités sont égales, trier par ordre alphabétique
    return a.name.localeCompare(b.name);
  });

  if(toggleAble){
    if(participants.length <= 2)
      return <ToggleParticipants  label={event.type === 'team' ? 'équipes' : 'athlètes'} participants={sortedParticipants} showDefault={true}/>
      
    return <ToggleParticipants  label={event.type === 'team' ? 'équipes' : 'athlètes'} participants={sortedParticipants}/>
  }
    

    return <ListParticipants participants={sortedParticipants} versusEnable={false}/>

}

const ToggleParticipants = ({ label, participants, showDefault = false }) => {
  const [showParticipants, setShowParticipants] = useState(showDefault);

  const handleToggle = () => {
    setShowParticipants(!showParticipants);
  };

  if(!participants || !participants.length)
    return null

  return (
    <div>
      <Button
        endIcon={showParticipants ? <ExpandLess /> : <ExpandMore />}
        onClick={handleToggle}
        sx={{ 
          textTransform: 'none', 
          justifyContent: 'space-between', 
          width: '100%',
          height : '20px',
          bgcolor : '#f2f2f2',
          color : '#47464B',
          mb : 1,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

        }}
      >
        {showParticipants ? 'Masquer' : 'Afficher'} les {label}
      </Button>
      {showParticipants && <List sx={{
        width: '100%', 
        bgcolor: '#f8f8f8', 
        boxShadow : '4px 4px 10px rgb(200,200, 200)'}}><ListParticipants participants={participants}/></List>}
    </div>
  );
};

const ListParticipants = ({ participants, versusEnable = true }) => {
  // Fonction pour vérifier si on doit utiliser le mode versus
  const isVersusMode = versusEnable && participants.length === 2;

  const getMedalGamefact = (participant) => {
    return participant.gamefacts?.find(g => g.idName === 'olympics_medal')
  }

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
  
    let result = "";
    if (h > 0) {
      result += `${h}h`;
    }
    if (m > 0 || h > 0) {
      result += `${m}'`;
    }
    result += `${s}"`;
  
    return result;
  };

  const formatDistance = (cm) => {
    const meters = (cm / 100).toFixed(2);
    return `${meters}m`;
  };

  const getPerformanceGamefactValue = (participant) => {
    const gamefact = participant.gamefacts?.find(g => ["performance", "performance_points", "performance_distance"].includes(g.idName))

    if(!gamefact)
      return ''

    switch (gamefact.idName) {
      case "performance":
        return formatTime(gamefact.type.value);
      case "performance_points":
        return gamefact.type.value;
      case "performance_distance":
        return formatDistance(gamefact.type.value);
      default:
        return gamefact.type.value;
    }
  }

  const getRankingGamefactValue = (participant) => {
    const gamefact = participant.gamefacts?.find(g => ["ranking"].includes(g.idName))

    if(!gamefact)
      return ''

    return gamefact.type.value + '.'
  }



  if (isVersusMode) {
    return (
      <ListItem sx={{ padding: "1px", margin: 0 }}>
        <Grid container justifyContent="center" alignItems="center" width={'100%'}>
          {/* Participant 1 */}
          <Grid item xs={5.5} container direction="row" justifyContent="flex-start" alignItems="center" >
            <Grid item xs={2} sx={{textAlign : 'center'}}>{getCountryFlag(participants[0].nationality, '100%')}</Grid>
            <Grid item xs={6} p={1}>
              <ListItemText 
                sx={{ margin: 0, textAlign: 'center', '.MuiListItemText-primary': commonStyles }}
                primary={participants[0].name}
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign : 'center'}}>{getMedalImage(getMedalGamefact(participants[0]))}</Grid>
            <Grid item xs={2} sx={{textAlign : 'center'}}><Typography fontSize='0.9rem'>{getPerformanceGamefactValue(participants[0])}</Typography></Grid>
          </Grid>
          <Grid item xs={1} sx={{textAlign : 'center'}}>-</Grid>
          {/* Participant 2 */}
          <Grid item xs={5.5} container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={2} sx={{textAlign : 'center'}}><Typography fontSize='0.9rem'>{getPerformanceGamefactValue(participants[1])}</Typography></Grid>
            <Grid item xs={2} sx={{textAlign : 'center'}}>{getMedalImage(getMedalGamefact(participants[1]))}</Grid>
            <Grid item xs={6} p={1}>
              <ListItemText 
                sx={{ margin: 0, textAlign: 'center', '.MuiListItemText-primary': commonStyles }}
                primary={participants[1].name}
              />
            </Grid>
            <Grid item xs={2} sx={{textAlign : 'center'}}>{getCountryFlag(participants[1].nationality, '100%')}</Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  // Fallback pour le mode normal avec plus ou moins de deux participants
  return (
    participants.map((participant, index) => (
      <ListItem key={participant._id} sx={{padding: "1px", margin: 0}}>
        <Grid container sx={{width : '100%', alignItems : 'center'}}>
          <Grid item xs={1} sx={{textAlign : 'center', alignItems : 'center'}}><Typography fontSize='0.9rem'>{getRankingGamefactValue(participant)}</Typography></Grid>
          <Grid item xs={1} sx={centerFlex}>{getCountryFlag(participant.nationality, '100%')}</Grid>
          <Grid item xs={6} p={1}><ListItemText 
            sx={{
              margin: 0,
              '.MuiListItemText-primary': commonStyles
            }}
            primary={participant.name} 
          /></Grid>
          <Grid item xs={1} sx={centerFlex}>{getMedalImage(getMedalGamefact(participant))}</Grid>
          <Grid item xs={3} sx={{textAlign : 'center'}}><Typography fontSize='0.9rem'>{getPerformanceGamefactValue(participant)}</Typography></Grid>
        </Grid>
      </ListItem>
    ))
  );
};

// Styles communs pour les textes
const commonStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.9rem',
  color: '#47464B',
};

// Style pour le centrage flex
const centerFlex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const getMedalImage = (gamefact, width = 10) => {
  if (!gamefact)
    return null; // Retourner null pour un rendu conditionnel en React

  let imagePath;
  switch (gamefact.type.value) {
    case 'gold':
      imagePath = medal_gold; // Utiliser l'import statique pour l'exemple
    break;
    case 'silver':
      imagePath = medal_silver; // Utiliser l'import statique pour l'exemple
    break;
    case 'bronze':
      imagePath = medal_bronze; // Utiliser l'import statique pour l'exemple
    break;    // Ajoutez d'autres cas selon vos types et imports
    default:
      return null; // Si le type n'est pas géré, retourne null
  }

  return <img src={imagePath} alt={`Medal - ${gamefact.type}`} width={width} />; // Notez la fermeture correcte de la balise
}

export default SportEventList