import SportEventDTO from "./SportEventDTO.js"

class LiveDTO {

    constructor(liveData){
        if(!liveData)
            return

        const {_id, provider, date, name, sportEvents} = liveData

        this._id = _id
        this.provider = provider

        this.name = name
        this.date = date

        this.sportEvents = sportEvents?.map(sportEvent => new SportEventDTO(sportEvent))

    }

    getSportsList(){
        const sports = this.sportEvents && this.sportEvents.map(event => event.phase?.competitionDiscipline?.discipline?.sport?.label)
        return [...new Set(sports)].sort((a, b) => a.localeCompare(b));
    }

    getCountriesList(){
        const contries = this.sportEvents && this.sportEvents.flatMap(event => {
            if(event.type === 'team'){
                return event.teams.map(t => t.nationality)
            }

            if(event.type === 'player'){
                return event.players.map(p => p.nationality)
            }

            return [];
        })

        return [...new Set(contries)].sort((a, b) => a.localeCompare(b));
    }

    getDepartmentsList(){
        const departments = this.sportEvents && this.sportEvents.flatMap(event => {
            if(event.type === 'player'){
                return event.players.filter(p => p.department).map(p => p.department)
            }

            return [];
        })

        return [...new Set(departments)].sort((a, b) => a.localeCompare(b));
    }

}

export default LiveDTO