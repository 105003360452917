import GenericNavigation from "./GenericNavigation.js";

const CalendarNavigation = ({ liveDates, activeDate, setActiveDate }) => {

    return (
      <GenericNavigation liveDates={liveDates.sort((a, b) => new Date(a) - new Date(b))} activeDate={activeDate} setActiveDate={setActiveDate} itemRenderer={(date) => {return new Date(date).toLocaleDateString("fr-FR", {
        weekday: "long",
        day: "numeric",
        month: "long",
      })}}/>
    );
}

export default CalendarNavigation