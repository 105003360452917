import { getPeriodDuration } from "./disciplineUtils.js";
import { rugbyRules, soccerRules, basketRules, handballRules } from "./rules.js";

export const EMPTY_PLAYER = {lastname : 'Equipe', firstname : '', number : 0, numero : 0, empty : true}

export const getPlayerById = (id, match) => {
    // Le but ici est de récupérer 'numero' de la feuille de match
    const playerInFDM = allFeuilledematchPlayers(match).find(p => p._id === id) ?? {}

    const player = allPlayers(match).find(p => p._id === id)

    if(!player)
        return EMPTY_PLAYER

    return {...player, numero : playerInFDM.numero ? playerInFDM.numero : player.numero}
}

export const getTeamInfoById = (id, match) => [match.homeTeam, match.awayTeam].find(t => t && t._id === id);

export const allPlayers = (match) => match.type!=='actu' ? [...match.homeTeam.players, ...match.awayTeam.players] : []

export const allFeuilledematchPlayers = (match) => match.type!=='actu' ? [...match.homeHolders, ...match.awayHolders, ...match.homeSubstitutes, ...match.awaySubstitutes] : []

export const homePlayers = (match) => match.homeTeam.players

export const awayPlayers = (match) => match.awayTeam.players

export const getPlayerNumber = (player) => {
    return player.numero || player.number
}

const getMaxTotalDuration = (period, discipline) => {
    const rules = discipline === 'rugby' ? rugbyRules : soccerRules

    return period < 2 ? (period + 1) * rules.periodDuration.main : 2 * rules.periodDuration.main + (period - 2) * rules.periodDuration.sub
}

const getMinTotalDuration = (period, discipline) => {
    switch(discipline){
        case 'soccer':
            return soccerRules.getMinTotalDuration(period)
        case 'rugby' : 
            return rugbyRules.getMinTotalDuration(period)
        case 'basket' :
            return basketRules.getMinTotalDuration(period)
        case 'handball' :
            return handballRules.getMinTotalDuration(period)
    }
}

const getActualPeriodDuration = (period, discipline) => {
    switch(discipline){
        case 'soccer':
            return soccerRules.getActualPeriodDuration(period)
        case 'rugby' : 
            return rugbyRules.getActualPeriodDuration(period)
        case 'basket' :
            return basketRules.getActualPeriodDuration(period)
        case 'handball' :
            return handballRules.getActualPeriodDuration(period)
    }
}

export const getPeriod = (period, discipline) => {

    const errtxt = "Discipline non implémentée getPeriod pour :" + discipline;

    switch(discipline){
        case 'soccer':
            console.error(errtxt);
            return 
        case 'rugby' : 
            console.error(errtxt);
            return 
        case 'basket' :
            console.error(errtxt);
            return 
        case 'handball':
            return handballRules.getPeriodByMatchStep(period);
        default:
            console.error(errtxt);
            return [];
    }

}

export const getVisualTimer = (timer, period, discipline) => {
    if(['soccer', 'rugby'].includes(discipline)){
        if(period === 2 || period === 6)
            return ''
    
        if(period === 5)
            return 't.a.b'
    }

    if(['handball'].includes(discipline)){
        const periodObject = getPeriod(period, discipline)
        if(periodObject.periodPhase === 'so'){
            return 't.a.b'
        }
    }
    
    
    const minTotalDuration = getMinTotalDuration(period, discipline)
    const actualPeriodDuration = getActualPeriodDuration(period, discipline)

    let leftPartTimer = Math.min(timer, actualPeriodDuration) + minTotalDuration

    let rightPartTimer = timer - leftPartTimer + minTotalDuration
    
    return rightPartTimer ? leftPartTimer+"'(+"+rightPartTimer+")" : leftPartTimer+"'"
}

export const getTotalTimer = (period, timer, discipline) => {

    switch(discipline){
        case 'soccer':
            return soccerRules.getTotalTimer(period, timer)
        case 'rugby' : 
            return rugbyRules.getTotalTimer(period, timer)
        case 'basket' :
            return basketRules.getTotalTimer(period, timer)
        case 'handball' :
            return handballRules.getTotalTimer(period, timer)
    }
}

export const getNumericTimer = (timer, period, discipline) => {

    const periodDuration = getPeriodDuration(discipline)

    return parseFloat(timer) + (periodDuration*period)
}

export const getMediaNumericTimer = (timer, period, discipline) => {
    const minutes = Math.floor(timer);
    const seconds = timer % 60;
    
    const convertTimer = parseFloat(minutes+"."+seconds)

    const periodDuration = getPeriodDuration(discipline)

    return convertTimer + (periodDuration*period)
}