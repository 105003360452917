import { Image } from "@mui/icons-material";
import { Box } from "@mui/material";

const ImageComponent = ({ src, alt, width, height }) => {
  if (!src) {
    return <Box sx={{ width, height, bgcolor: "multiplex.lightgrey" }} />;
  }

  return <Box sx={{ maxWidth: '100%',display: 'flex',
  alignItems: 'center', }} width={width} height={height}>
  <img
    src={src}
    alt={alt}
    style={{
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    }}
  />
</Box>
};

export default ImageComponent;