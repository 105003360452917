import DisciplineDTO from "./DisciplineDTO.js"

class SportDTO {

    constructor(sportData){
        if(!sportData)
            return

        const {_id, idName, label,  provider, disciplines} = sportData

        this._id = _id
        this.idName = idName
        this.label = label

        this.provider = provider

        this.disciplines = disciplines?.map(discipline => new DisciplineDTO(discipline))
    }
}

export default SportDTO