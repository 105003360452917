import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useLiveResolver } from "./hooks/useLiveResolver.js";
import { useMatchIdMapping } from "./hooks/useMatchIdMapping.js";
import { CircularProgress } from "@mui/material";
import { CALENDAR_MODE } from "./constants/MODE_LIST.js";
import { useActuIdMapping } from "./hooks/useActuIdMapping.js";
import { useEffect } from "react";
import { useNavigateTo } from "./hooks/useNavigateTo.js";

const LiveActuDetailResolver = () => {

    const { id } = useParams();
    const navigateTo = useNavigateTo()
    const [live] = useLiveResolver(id, CALENDAR_MODE, 0, undefined)

    const [actuIdMapping, getIdActuFromActuName] = useActuIdMapping(live)

    useEffect(() => {
        if (live && live.actualitesDetails && live.actualitesDetails.length === 1) {
            const actuId = live.actualites[0]; // Récupérer l'ID du seul match disponible
            navigateTo(`${actuIdMapping[actuId]}`); // Ajouter l'ID du match à l'URL
        }
    }, [navigateTo, live, actuIdMapping]);
  
    if(!live)
        return <CircularProgress/>

    return <Outlet context={{live, actuIdMapping, getIdActuFromActuName}}/>;
};

export default LiveActuDetailResolver