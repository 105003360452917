export default {
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize : '8em',
      },
      mediaH1: {
        fontSize : '1.8em',
        fontFamily: 'Poppins, sans-serif',
      },
      h2: {
        fontWeight: 'bold',
        fontSize : '1.3em',
        padding : "10px"
      },
      mediaH2: {
        fontSize : '1.3em',
        fontFamily: 'Poppins, sans-serif',
      },
      h3: {
        fontWeight : 'bold',
        fontSize : '1em',
      },
      mediaH3: {
        fontSize : '1em',
        fontFamily: 'Poppins, sans-serif',
      },
      h4: {
        fontWeight : 'bold',
        fontSize : '1em',
        fontStyle : 'italic'
      },
      mediaH4: {
        fontSize : '.8em',
        fontFamily: 'Poppins, sans-serif',
      },
      p: {
        fontSize : '.9em',
        fontFamily: 'Poppins, sans-serif',
      },
      daydetail: {
        fontSize : '1.1em',
        padding : "10px",
        textIndent : 15,
        fontFamily: 'Poppins, sans-serif',
      },
    },
    palette: {
      primary: {
        main: '#3b3b3b',
        darkgrey: '#595959',
        blue: '#1777b7',
        green: '#02bd92',
        red: '#e46567',
      },
      multiplex : {
        main : '#47464B',
        grey : '#6D6E73',
        lightgrey : '#E5E7E9',
        darkblue : '#4A5168',
        classement : '#458BCA',
        chrono : '#018C38',
        mitemps : '#E7401E',
        forfait : '#47464B',
        reported : '#969696'
      },
      media : {
        main : '#47464B',
        darkgrey : '#6d6e73',
        lightgrey : '#e5e7e9',
        darkblue : '#4a5168',
        blue : '#4a587c',
        green : '#5cae31',
        red : '#e05b37'
      },
      quizzdarkblue : {
        main : '#4a5168'
      },
      quizzgreen : {
        main : '#5cae31'
      },
      quizzred : {
        main : '#e05b37'
      },
      background: {
        default: 'white',
      },
      text: {
        primary: '#3b3b3b',
      },
    },
  }