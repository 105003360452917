import React, { useEffect, useState } from "react";
import { getCompetitionClassement } from "../../../../api/index.js";
import { Box, CircularProgress, Hidden } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Divider } from '@mui/material';

const thStyle = {minWidth : '35px', textAlign : 'center', padding : '5px', fontWeight : '400', textTransform : 'uppercase'}
const darkMain = '#4A5168'
const lightGrey = '#E5E7E9'
const whiteAlmost = 'rgb(255,255,255)'

const Classement = ({discipline}) => {

    const queryParameters = new URLSearchParams(window.location.search)
    const competition = queryParameters.get("competition")

    const [teams, setTeams] = useState()

    useEffect(() => {

        const fetchClassement = async () => {
            try {
              const response = await getCompetitionClassement(competition);

              setTeams(response.data.teams)
            } catch (error) {
              console.error(error);
            }
          };
      
          fetchClassement();
        
    }, [competition])

    useEffect(() => {
        setTeams()

    }, [competition])

    if(!teams)
        return <Box sx={{display : 'flex', alignItems : 'center', flexDirection : 'column', mt : 3}}><CircularProgress/></Box>

        return (
            <Box sx={{display : 'flex', alignItems : 'center', flexDirection : 'column', mt : 3}}>
                <Typography variant="h1" sx={{fontSize : '1.1em'}}>CLASSEMENT</Typography>

                <Box sx={{maxWidth : '100%', overflowX : 'auto', mt : 1}}>
                    <table style={{borderCollapse : 'collapse', tableLayout : 'fixed'}}>
                        <thead>
                        <tr style={{backgroundColor : darkMain, color : whiteAlmost}}>
                            <th style={{
                                backgroundColor : darkMain, 
                                position: "sticky", 
                                left: 0, 
                                minWidth : '100px', 
                                textAlign : 'center', 
                                padding : '5px'}}><Typography sx={{fontSize : '1em'}}>Équipe</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>Pts</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>J.</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>G.</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>N.</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>P.</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>Bp</Typography></th>
                            <th style={thStyle}><Typography sx={{fontSize : '1em'}}>Bc</Typography></th>
                            {discipline === 'rugby' && <th style={thStyle}>BO</th>}
                            {discipline === 'rugby' && <th style={thStyle}>BD</th>}
                            <th style={thStyle}>+/-</th>
                        </tr>
                        </thead>
                        <tbody>
                        {teams.map((team, index) => (
                            <tr style={{backgroundColor : index % 2 === 0 ? lightGrey : whiteAlmost}}>
                                <td style={{
                                    backgroundColor : index % 2 === 0 ? lightGrey : whiteAlmost, 
                                    position: "sticky", 
                                    left: 0, 
                                    minWidth : '100px', 
                                    textAlign : 'left', 
                                    padding : '5px'}}><Typography sx={{fontSize : '.8em'}}>{team.name}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.points}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.matches.length}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.matchWon}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.matchNul}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.matchLoss}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.goalsScored}</Typography></td>
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.goalsConceded}</Typography></td>
                                {discipline === 'rugby' && <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.BO}</Typography></td>}
                                {discipline === 'rugby' && <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.BD}</Typography></td>}
                                <td style={thStyle}><Typography sx={{fontSize : '.8em'}}>{team.goalsScored - team.goalsConceded}</Typography></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
          );
};

export default Classement
