import { CREATE_GAMEFACT, DELETE_GAMEFACT, GET_LIVE_DETAILS, INCREMENT_LIVE_TIMER, UPDATE_GAMEFACT, CREATE_NOTATION, DELETE_NOTATION,UPDATE_NOTATION, CREATE_ARTICLE, DELETE_ARTICLE, UPDATE_ARTICLE, INCREMENT_PERIOD, GET_MATCH_DETAILS } from '../constants/actionTypes.js'

export default (matchDetail = {}, action) => {
    switch(action.type){
        case GET_MATCH_DETAILS:
            return {...action.payload}
        case CREATE_GAMEFACT:
            if(!matchDetail.gameFacts)
                return matchDetail 
            if(action.payload.idMatch === matchDetail.idMatch)
                return {...matchDetail, gameFacts : [...matchDetail.gameFacts, action.payload]}
            return matchDetail
        case UPDATE_GAMEFACT:
            if(!matchDetail.gameFacts)
                return matchDetail 
            if(action.payload.idMatch === matchDetail.idMatch)
                return {...matchDetail, gameFacts : matchDetail.gameFacts.map(gf => gf._id === action.payload._id ? action.payload : gf)}
            return matchDetail
        case DELETE_GAMEFACT:
            if(!matchDetail.gameFacts)
                return matchDetail 
            return {...matchDetail, gameFacts : matchDetail.gameFacts.filter(gf => gf._id !== action.payload)}
        default :
            return matchDetail
    }
}