import { Avatar, Box, Button, CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GAME_FACT_DROP, GAME_FACT_ESSAI, GAME_FACT_FAULT, GAME_FACT_PENALITE, GAME_FACT_REDCARD, GAME_FACT_REPLACEMENT, GAME_FACT_REPLACEMENT_IN, GAME_FACT_REPLACEMENT_OUT, GAME_FACT_SOCCER_BUT, GAME_FACT_SOCCER_FAULT, GAME_FACT_SOCCER_PENALTY, GAME_FACT_SOCCER_REDCARD, GAME_FACT_SOCCER_REPLACEMENT, GAME_FACT_SOCCER_YELLOWCARD, GAME_FACT_TRANSFORMATION, GAME_FACT_YELLOWCARD } from '../../../../v1/components/live/gamefacts/types.js'
import useStyles from './styles.js'
import { useInfoPlayer } from './useInfoPlayer.js'
import { usePositionPlayer } from './usePositionPlayer.js'

import { getIconeMap } from './useInfoPlayer.js'
import { EMPTY_PLAYER } from '../utils/helpers.js'
import { getVisualTimer } from '../../../../v1/components/live/sports/genericUtils.js'



const PlayerListContent = ({match}) => {

    const getRemplacements = useCallback((player) => {
        // On retourne les remplacements de ce joueur
        let remplacementGF = match.gameFacts.filter(g => [GAME_FACT_REPLACEMENT, GAME_FACT_SOCCER_REPLACEMENT].includes(g.type) && g.detail.outplayer===player._id)

        return remplacementGF.length ? <Typography component='div' sx={{padding : 0, margin : 0, color : 'rgb(130,130,130)', lineHeight : 1}} variant="subtitle2">{remplacementGF.sort((a,b)=> a.detail.timer - b.detail.timer).map(g => {
            // Le joueur entre
            if(g.detail.outplayer !== player._id){
                let concernedPlayer = match.homeHolders.concat(match.homeSubstitutes).concat(match.awayHolders).concat(match.awaySubstitutes).find(p => p._id === g.detail.outplayer) ?? EMPTY_PLAYER
                return <div>( {getVisualTimer(g.detail.timer, g.detail.period, match.discipline)}' : {concernedPlayer.firstname[0]}. {concernedPlayer.lastname})</div>
            }
                
            // Le joueur sort
            if(g.detail.inplayer !== player._id){
                let concernedPlayer = match.homeHolders.concat(match.homeSubstitutes).concat(match.awayHolders).concat(match.awaySubstitutes).find(p => p._id === g.detail.inplayer) ?? EMPTY_PLAYER
                return <div>( {getVisualTimer(g.detail.timer, g.detail.period, match.discipline)} : {concernedPlayer.firstname[0]}. {concernedPlayer.lastname})</div>
            }
        })}</Typography> : undefined
    }, [match])


    const getIcones = useCallback((player) => {

        const icones = getIconeMap()

        let gf = match.gameFacts.filter(g => ([GAME_FACT_REPLACEMENT, GAME_FACT_SOCCER_REPLACEMENT].includes(g.type) && 
        (g.detail.outplayer===player._id || g.detail.inplayer===player._id)) || 
        ([GAME_FACT_YELLOWCARD, GAME_FACT_REDCARD, GAME_FACT_SOCCER_YELLOWCARD, GAME_FACT_SOCCER_REDCARD].includes(g.type) && g.detail.player===player._id))

        // on ordonne
        gf.sort((a, b) => a.detail.timer - b.detail.timer)

        let iconesGF = []
        gf.forEach(g => {
            switch(g.type){
                case GAME_FACT_REPLACEMENT :
                case GAME_FACT_SOCCER_REPLACEMENT :
                    if(g.detail.inplayer === player._id)
                        iconesGF.push(icones.get(GAME_FACT_REPLACEMENT_IN))
                    else if(g.detail.outplayer === player._id)
                        iconesGF.push(icones.get(GAME_FACT_REPLACEMENT_OUT))
                break
                default :
                    iconesGF.push(icones.get(g.type))
            }
        })

        return iconesGF

    }, [match])


    const getReducedName = (player) => {

        if(!player || player.empty)
            return "..."

        if(!player.firstname)
            return player.lastname

        return player.firstname[0]+'. '+player.lastname
    }

    return <><Box sx={{display : "flex", flexDirection : "column"}}>
                <Typography sx={{backgroundColor : match.homeTeam.color, color : match.homeTeam.textColor, padding : 2, textAlign : 'center'}}>{match.homeTeam.name.toUpperCase()}</Typography>
                {match.homeCoach && <><Typography sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">ENTRAÎNEUR</Typography>
                <Stack spacing={1}>
                    <Typography noWrap variant="body2">{match.homeCoach}</Typography>
                </Stack></>}
                <Typography sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">TITULAIRES</Typography>
                <Stack spacing={1}>
                    {match.homeHolders.sort((a,b) => a.numero - b.numero).map(p => <div key={p._id}><Typography noWrap variant="body2"><Typography variant="overline">{p.numero}</Typography> {getReducedName(p)} {getIcones(p).map(i => <><img src={i}  style={{height : '15px'}}/> </>)} {getRemplacements(p)}</Typography></div> )}
                </Stack>
                <Divider sx={{margin : 2}}/>
                <Typography  sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">REMPLAÇANTS</Typography>
                <Stack spacing={1}>
                    {match.homeSubstitutes.sort((a,b) => a.numero - b.numero).map(p => <div key={p._id}><Typography noWrap variant="body2"><Typography variant="overline">{p.numero}</Typography> {getReducedName(p)} {getIcones(p).map(i => <><img src={i}  style={{height : '15px'}}/> </>)} {getRemplacements(p)}</Typography></div>)}
                </Stack>
            </Box>
            <Box sx={{display : "flex", flexDirection : "column", alignItems : 'right'}}>
                <Typography sx={{backgroundColor : match.awayTeam.color, color : match.awayTeam.textColor, padding : 2, textAlign : 'center'}}>{match.awayTeam.name.toUpperCase()}</Typography>
                {match.awayCoach && <><Typography sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">ENTRAÎNEUR</Typography>
                <Stack spacing={1}>
                    <Typography noWrap variant="body2">{match.awayCoach}</Typography>
                </Stack></>}
                <Typography sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">TITULAIRES</Typography>
                <Stack spacing={1}>
                    {match.awayHolders.sort((a,b) => a.numero - b.numero).map(p => <div key={p._id}><Typography noWrap variant="body2" textAlign={'right'}> {getIcones(p).map(i => <><img src={i}  style={{height : '15px'}}/> </>)} {getReducedName(p)} <Typography variant="overline">{p.numero}</Typography>{getRemplacements(p)}</Typography></div>)}
                </Stack>
                <Divider sx={{margin : 2}}/>
                <Typography sx={{margin : 2, textAlign : 'center'}} variant="subtitle2">REMPLAÇANTS</Typography>
                <Stack spacing={1}>
                    {match.awaySubstitutes.sort((a,b) => a.numero - b.numero).map(p => <div key={p._id}><Typography noWrap variant="body2" textAlign={'right'}> {getIcones(p).map(i => <><img src={i}  style={{height : '15px'}}/> </>)} {getReducedName(p)} <Typography variant="overline">{p.numero}</Typography>{getRemplacements(p)}</Typography></div>)}
                </Stack>
            </Box></>
}

const CompositionPreview = ({match}) => {

    const classes = useStyles();

    const isSmall = window.innerWidth < 600;


    const [isHolders, setIsHolders] = useState(true)

    return match.homeTeam ? <Box sx={{position : 'relative'}}>
        <Box sx={{flexDirection : "row", justifyContent : "space-between", zIndex:100}} className={classes.playerList}>
            <PlayerListContent match={match}/>
        </Box>
        <Box sx={{top : 0, width : '100%', textAlign : 'center', zIndex:1}}  className={classes.terrainBlock}>
            <Box>
                <Button variant="outlined" sx={{ 
                    zIndex : 100, 
                    width : '380px',
                    display : 'block', 
                    left: '50%', 
                    transform : 'translateX(-50%)'}} onClick={() => setIsHolders(h => !h)}>
                    {isHolders ? 'BANC' : 'TERRAIN'}
                </Button>
                <Terrain isHolders={isHolders} match={match} isSmall={isSmall}/>
                <Box sx={{width : '100%', textAlign : 'center', zIndex:1, flexDirection : 'column', display : 'flex'}}>
                    {match.referee && <Typography component="div" sx={{fontWeight : 700, fontSize : '0.7em', color : 'black', display : 'inline', textAlign : 'center'}}>Arbitre: <Typography component="span" sx={{fontSize : '1.2em'}}>{match.referee}</Typography></Typography>}
                    {match.stade && <Typography component="div" sx={{fontWeight : 700, fontSize : '0.7em', color : 'black', display : 'inline', textAlign : 'center'}}>Stade: <Typography component="span" sx={{fontSize : '1.2em'}}>{match.stade}</Typography></Typography>}
                    {match.nbSpec!==null && match.nbSpec!==undefined && <Typography component="div" sx={{fontWeight : 700, fontSize : '0.7em', color : 'black', display : 'inline', textAlign : 'center'}}><Typography component="span" sx={{fontSize : '1.2em'}}>{match.nbSpec} spectateur{match.nbSpec > 1 && 's'}</Typography></Typography>}
                </Box>
            </Box>
            
        </Box>
        
        <Box sx={{flexDirection : "row", justifyContent : "space-between", zIndex:100}} className={classes.playerListMobile}>
            <PlayerListContent match={match}/>
        </Box>
    </Box> : <CircularProgress/>
}


const Terrain = ({isHolders, match, isSmall}) => {

    const myTerrain = React.useRef()

    const getSize = useCallback(() => {

        if(match.discipline === 'rugby'){
            let width = isSmall ? 350 : 450
            let height = 1.981981981981 * width

            return {width, height}
        }else if(match.discipline === 'soccer'){
            let width = isSmall ? 350 : 450
            let height = 1.448 * width

            return {width, height}
        }else if(match.discipline === 'basket'){
            let width = isSmall ? 350 : 450
            let height = 1.981981981981 * width

            return {width, height}
        }
        else if(match.discipline === 'handball'){
            let width = isSmall ? 350 : 450
            let height = 1.981981981981 * width

            return {width, height}
        }
    }, [match, isSmall])

    const getClassName  = useCallback(() => {
        let className = ''
        if(match.discipline === 'rugby'){
            className += 'terrain_feuillematch_rugby'
        }else if(match.discipline === 'soccer'){
            className += 'terrain_feuillematch'
        }else if(match.discipline === 'basket'){
            className += 'terrain_feuillematch_basket'
        }else if(match.discipline === 'handball'){
            className += 'terrain_feuillematch_handball'
        }

        if(isHolders){
            className += '_holders'
        }

        return className
    }, [match, isHolders])

    

    return <Box ref={myTerrain} className={getClassName()}  width={getSize().width} height={getSize().height} sx={{position : 'relative', display : 'block', left: '50%', 
    transform : 'translateX(-50%)'}}>
            <PositionResolver isSmall={isSmall} match={match} fullWidth={getSize().width} fullHeight={getSize().height} terainDom={myTerrain.current} isHolders={isHolders} home={isHolders ? match.homeHolders : match.homeSubstitutes} away={isHolders ? match.awayHolders : match.awaySubstitutes} homeComposition={match.homeComposition} awayComposition={match.awayComposition}/>
    </Box>
}

const PositionResolver = ({fullWidth, fullHeight, home, away, homeComposition, awayComposition, isHolders, match, terainDom}) => {

    const left = 0
    const top = 0.06 * fullHeight
    const width = fullWidth
    const height = match.discipline === 'basket' ? 0.72 * fullHeight : 0.88 * fullHeight

    const homeCompToUse = useMemo(() => {
        return isHolders ? homeComposition : match.discipline === 'rugby' ?  'isRugbyHolders' : 'isSoccerHolders'
    }, [homeComposition, isHolders, match.discipline])
    
    const awayCompToUse = useMemo(() => {
        return isHolders ? awayComposition : match.discipline === 'rugby' ?  'isRugbyHolders' : 'isSoccerHolders'
    }, [awayComposition, isHolders, match.discipline])


    const [homePlayersPositions] = usePositionPlayer(homeCompToUse, {width, height}, true, isHolders)
    const [awayPlayersPositions] = usePositionPlayer(awayCompToUse, {width, height}, false, isHolders)

    const getHomePlayerInHolders = useCallback((idx) => {
        let playerInData =  home.find(d => d && d.position === idx)

        return playerInData
    }, [home])

    const getAwayPlayerInHolders = useCallback((idx) => {
        let playerInData =  away.find(d => d && d.position === idx)

        return playerInData
    }, [away])

    return match.isComplete ? <Box sx={{position : 'absolute', top, left, width, height, textAlign : 'center'}}>
            {!homePlayersPositions.length && <Typography sx={{position :'relative', fontSize: '1.2em', top : height/4}} component={'span'}>Pas de composition pour {match.homeTeam.name}</Typography>}
            {homePlayersPositions.map((player, idx) => 
                getHomePlayerInHolders(idx) && <PlayerOnField key={idx}  player={{...getHomePlayerInHolders(idx), ...player}} color={match.homeTeam.color} match={match} textColor={match.homeTeam.textColor} terainDom={terainDom}/>
            )}

            {!awayPlayersPositions.length && <Typography sx={{position :'relative', fontSize: '1.2em', top : 3* height/4}}>Pas de composition pour {match.awayTeam.name}</Typography>}
            {awayPlayersPositions.map((player, idx) => 
                getAwayPlayerInHolders(idx) && <PlayerOnField key={idx} player={{...getAwayPlayerInHolders(idx), ...player}} color={match.awayTeam.color} match={match} textColor={match.awayTeam.textColor} terainDom={terainDom}/>
            )}
    </Box> : <CircularProgress/>
}

const PlayerOnField = ({player, color, match, textColor, terainDom}) => {

    const classes = useStyles();

    const {lastname, numero, position = {x :0 , y : 0}, isCpt} = player

    const [isInfoVisible, setIsInfoVisible, gameFacts, icones] = useInfoPlayer(player, match)


    const onClick = (e) => {
        setIsInfoVisible(i => !i) 
    }

    return  <><Box sx={{position : 'absolute', display : 'flex', flexDirection : 'column', alignItems : 'center', textOverflow: "ellipsis",
    left : position.x-40, top : position.y-15, width : 80, padding : 0, margin : 0}}
    onClick={onClick} className={classes.player}>
                {isCpt && <Avatar sx={{position : 'absolute', top : -15, backgroundColor: '#e3e3e3', width : 15, height : 15, color : 'black', fontSize : '0.8em'}}>c</Avatar>}
                <Avatar sx={{ backgroundColor: color, width : 25, height : 25, color : textColor, fontSize : '0.8em'}}>{numero}</Avatar>
                <Typography noWrap component="div" sx={{width : '100%', fontWeight : 700, fontSize : '0.7em', color : 'black', display : 'inline', textAlign : 'center'}}>{lastname}</Typography>
                <PlayerDetailHover match={match} isInfoVisible={isInfoVisible} gameFacts={gameFacts} icones={icones} player={player} color={color} textColor={textColor} terainDom={terainDom} periodDuration={match.discipline === 'soccer' ? 45 : 40}/>
            </Box>
            
            </>

}

const PlayerDetailHover = ({player, color, isInfoVisible, gameFacts=new Map(), icones, textColor, terainDom, periodDuration, match}) => {
    const myRef = useRef()

    useEffect(() => {
        if(!myRef.current || !terainDom || !isInfoVisible)
            return

        const terrainRect = terainDom.getBoundingClientRect()
        const hoverRect = myRef.current.getBoundingClientRect()

        if(terrainRect.left > hoverRect.left){
            myRef.current.style.left = 0 + 'px'
        }

        if(terrainRect.right < hoverRect.right){
            myRef.current.style.right = 0 + 'px'
        }

        if(hoverRect.top - hoverRect.height < terrainRect.top){
            myRef.current.style.bottom = 'calc(-100% - 5px)'
        }

    }, [myRef, isInfoVisible])

    return <Paper ref={myRef} style={{width : 200, position : 'absolute', background : 'white', bottom : '100%', zIndex : 1000, display : isInfoVisible ? 'block' : 'none', padding : 5}}>
        <Typography variant="h6" sx={{background : color, color: textColor, marginBottom : 1}}>{player.firstname} {player.lastname}</Typography>
        {gameFacts.get(GAME_FACT_ESSAI) && <Typography><img src={icones.get(GAME_FACT_ESSAI)} style={{height : '15px'}}></img> Essai : {gameFacts.get(GAME_FACT_ESSAI).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_TRANSFORMATION) && <Typography><img src={icones.get(GAME_FACT_TRANSFORMATION)} style={{height : '15px'}}></img> Transformation : {gameFacts.get(GAME_FACT_TRANSFORMATION).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_DROP) && <Typography><img src={icones.get(GAME_FACT_DROP)} style={{height : '15px'}}></img> Drop : {gameFacts.get(GAME_FACT_DROP).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_PENALITE) && <Typography><img src={icones.get(GAME_FACT_PENALITE)} style={{height : '15px'}}></img> Penalite : {gameFacts.get(GAME_FACT_PENALITE).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_FAULT) && <Typography><img src={icones.get(GAME_FACT_FAULT)} style={{height : '15px'}}></img> Faute : {gameFacts.get(GAME_FACT_FAULT).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_YELLOWCARD) && <Typography><img src={icones.get(GAME_FACT_YELLOWCARD)} style={{height : '15px'}}></img> Carton jaune : {gameFacts.get(GAME_FACT_YELLOWCARD).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_REDCARD) && <Typography><img src={icones.get(GAME_FACT_REDCARD)} style={{height : '15px'}}></img> Carton rouge : {gameFacts.get(GAME_FACT_REDCARD).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        
        {gameFacts.get(GAME_FACT_SOCCER_BUT) && <Typography><img src={icones.get(GAME_FACT_SOCCER_BUT)} style={{height : '15px'}}></img> But : {gameFacts.get(GAME_FACT_SOCCER_BUT).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_SOCCER_PENALTY) && <Typography><img src={icones.get(GAME_FACT_SOCCER_PENALTY)} style={{height : '15px'}}></img> Penalty : {gameFacts.get(GAME_FACT_SOCCER_PENALTY).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_SOCCER_FAULT) && <Typography><img src={icones.get(GAME_FACT_SOCCER_FAULT)} style={{height : '15px'}}></img> Faute : {gameFacts.get(GAME_FACT_SOCCER_FAULT).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_SOCCER_YELLOWCARD) && <Typography><img src={icones.get(GAME_FACT_SOCCER_YELLOWCARD)} style={{height : '15px'}}></img> Carton jaune : {gameFacts.get(GAME_FACT_SOCCER_YELLOWCARD).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_SOCCER_REDCARD) && <Typography><img src={icones.get(GAME_FACT_SOCCER_REDCARD)} style={{height : '15px'}}></img> Carton rouge : {gameFacts.get(GAME_FACT_SOCCER_REDCARD).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        
        {gameFacts.get(GAME_FACT_REPLACEMENT_OUT) && <Typography><img src={icones.get(GAME_FACT_REPLACEMENT_OUT)} style={{height : '15px'}}></img> Sortie : {gameFacts.get(GAME_FACT_REPLACEMENT_OUT).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
        {gameFacts.get(GAME_FACT_REPLACEMENT_IN) && <Typography><img src={icones.get(GAME_FACT_REPLACEMENT_IN)} style={{height : '15px'}}></img> Entrée : {gameFacts.get(GAME_FACT_REPLACEMENT_IN).map((g, idx) => (idx===0?"":", ")+getVisualTimer(g.detail.timer, g.detail.period, match.discipline))}</Typography>}
    </Paper>
}

export default CompositionPreview