import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { useLiveResolver } from "./hooks/useLiveResolver.js";
import { useMatchIdMapping } from "./hooks/useMatchIdMapping.js";
import { CircularProgress } from "@mui/material";

const LiveDetailResolver = () => {

    const { id, mode, date } = useParams();
    const queryParameters = new URLSearchParams(window.location.search)
    const competition = queryParameters.get("competition")

    const {liveDates, liveCompetitionsDays} = useOutletContext();

    const [live] = useLiveResolver(id, mode, date, competition)

    const [matchIdMapping, getIdMatchFromMatchName] = useMatchIdMapping(live)
  
    if(!live)
        return <CircularProgress />

    return <Outlet context={{live, liveDates, liveCompetitionsDays, matchIdMapping, getIdMatchFromMatchName}}/>;
};

export default LiveDetailResolver