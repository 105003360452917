import PlayerDTO from "./PlayerDTO.js"
import SportEventDTO from "./SportEventDTO.js"
import TeamDTO from "./TeamDTO.js"

class GameFactDTO {

    constructor(gamefactData){
        if(!gamefactData)
            return

        const {_id, idName, name, provider, event, details, type} = gamefactData

        this._id = _id
        this.idName = idName
        this.name = name

        this.type = new TypeDTO(type)
        this.details = new DetailsDTO(details)
        this.event = new SportEventDTO(event)

        this.provider = provider
    }
}

class TypeDTO {
    constructor(typeData){
        if(!typeData)
            return

        const {name, category, format, value, description} = typeData

        this.name = name

        this.category = category
        this.format = format
        this.value = value
        this.description = description
    }
}

class DetailsDTO {
    constructor(detailsData){
        if(!detailsData)
            return

        const {actor, time, period, success} = detailsData

        // playerId, teamId
        this.actor = new ActorDTO(actor)
        this.time = time
        this.period = period
        this.success = success
    }
}

class ActorDTO {
    constructor(actorData){
        if(!actorData)
            return

        const {player, team} = actorData

        // playerId, teamId
        this.player = player && new PlayerDTO(player)
        this.team = team && new TeamDTO(team)
    }
}

export default GameFactDTO