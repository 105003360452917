import { Box, Typography } from "@mui/material"
import playerIcon from '../../gamefacts/icons/generic/joueur.png'
import { getPlayerNumber } from "../../sports/genericUtils.js"


function getPlayerPhoto(player){
  if(!player || !player.photoUrl)
      return playerIcon

  return player.photoUrl
}

const BoxPlayer = ({player, width = 40, height = 40, color = '#E53E3E', icon}) => {
    if(player.empty || player === 'csc')
        return ''

    return (
        <Box
            sx={{
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              padding: '8px',
              marginTop: '16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
                sx={{
                    backgroundColor: '#FFFFFF',
                    width: `${width}px`,
                    height: `${height}px`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '8px',
                    position: 'relative', // Ajout de position relative pour positionner le triangle
                }}
                >
                {/* Remplacez l'icone du joueur par l'icone réelle */}
                <img src={getPlayerPhoto(player)} alt="Player Icon" style={{ width: `${width}px`, height: `${height}px` }} />

                {/* Triangle */}
                <div
                    style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: 0,
                    height: 0,
                    borderLeft: '12px solid transparent',
                    borderBottom: '12px solid ' + color,
                    }}
                />
            </Box>
            <Box>
              <Typography variant="subtitle1">{player.lastname} {player.firstname}</Typography>
              <Typography variant="body2">Joueur n°{getPlayerNumber(player)}</Typography>
            </Box>
            {icon && <Box sx={{ marginLeft: 'auto' }}>
              <img src={icon} alt="Change Icon" style={{ width: `${width}px`, height: `${height}px` }} />
            </Box>}
          </Box>
    )
}

export default BoxPlayer