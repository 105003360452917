export const CREATE_PLAYER = 'CREATE_PLAYER'
export const CREATE_PLAYERS = 'CREATE_PLAYERS'
export const UPDATE_PLAYER = 'UPDATE_PLAYER'
export const DELETE_PLAYER = 'DELETE_PLAYER'
export const FETCH_ALL_PLAYER = 'FETCH_ALL_PLAYER'

export const CREATE_COMPETITION = 'CREATE_COMPETITION'
export const UPDATE_COMPETITION = 'UPDATE_COMPETITION'
export const DELETE_COMPETITION = 'DELETE_COMPETITION'
export const FETCH_ALL_COMPETITION = 'FETCH_ALL_COMPETITION'

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const FETCH_ALL_CATEGORY = 'FETCH_ALL_CATEGORY'

export const CREATE_TEAM = 'CREATE_TEAM'
export const UPDATE_TEAM = 'UPDATE_TEAM'
export const DELETE_TEAM = 'DELETE_TEAM'
export const FETCH_ALL_TEAM = 'FETCH_ALL_TEAM'

export const CREATE_MATCH = 'CREATE_MATCH'
export const UPDATE_MATCH = 'UPDATE_MATCH'
export const DELETE_MATCH = 'DELETE_MATCH'
export const FETCH_ALL_MATCH = 'FETCH_ALL_MATCH'

export const CREATE_LIVE = 'CREATE_LIVE'
export const UPDATE_LIVE = 'UPDATE_LIVE'
export const DELETE_LIVE = 'DELETE_LIVE'
export const FETCH_ALL_LIVE = 'FETCH_ALL_LIVE'

export const CREATE_ACTULIVE = 'CREATE_ACTULIVE'
export const UPDATE_ACTULIVE = 'UPDATE_ACTULIVE'
export const DELETE_ACTULIVE = 'DELETE_ACTULIVE'
export const FETCH_ALL_ACTULIVE = 'FETCH_ALL_ACTULIVE'

export const GET_LIVE_DETAILS = 'GET_LIVE_DETAILS'
export const EDIT_MATCH_IN_LIVE = 'EDIT_MATCH_IN_LIVE'
export const INCREMENT_LIVE_TIMER = 'INCREMENT_LIVE_TIMER'
export const INCREMENT_PERIOD = 'INCREMENT_PERIOD'

export const GET_MATCH_DETAILS = 'GET_MATCH_DETAILS'

export const CREATE_GAMEFACT = 'CREATE_GAMEFACT'
export const AUTOSCORE_GAMEFACTS = 'AUTOSCORE_GAMEFACTS'
export const UPDATE_GAMEFACT = 'UPDATE_GAMEFACT'
export const DELETE_GAMEFACT = 'DELETE_GAMEFACT'

export const CREATE_NOTATION = 'CREATE_NOTATION'
export const UPDATE_NOTATION = 'UPDATE_NOTATION'
export const DELETE_NOTATION = 'DELETE_NOTATION'
export const NOTE_RECEIVED = 'NOTE_RECEIVED'
export const NOTE_PENDING = 'NOTE_PENDING'

export const CREATE_SURVEY = 'CREATE_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const DELETE_SURVEY = 'DELETE_SURVEY'
export const SURVEY_VOTE_RECEIVED = 'SURVEY_VOTE_RECEIVED'
export const SURVEY_VOTES_RECEIVED = 'SURVEY_VOTES_RECEIVED'
export const SURVEY_VOTE_PENDING = 'SURVEY_VOTE_PENDING'

export const CREATE_QUIZZ = 'CREATE_QUIZZ'
export const UPDATE_QUIZZ = 'UPDATE_QUIZZ'
export const DELETE_QUIZZ = 'DELETE_QUIZZ'
export const QUIZZ_VOTE_RECEIVED = 'QUIZZ_VOTE_RECEIVED'
export const QUIZZ_VOTES_RECEIVED = 'QUIZZ_VOTES_RECEIVED'
export const QUIZZ_VOTE_PENDING = 'QUIZZ_VOTE_PENDING'

export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const DELETE_ARTICLE = 'DELETE_ARTICLE'

export const UPDATE_TIMER = 'UPDATE_TIMER'
export const UPDATE_COMPOSITION = 'UPDATE_COMPOSITION'
export const UPDATE_INFOMATCH = 'UPDATE_INFOMATCH'

export const UPDATE_INFOACTULIVE = 'UPDATE_INFOACTULIVE'

export const EDIT_COLLECTOR_TIMESTAMP = "EDIT_COLLECTOR_TIMESTAMP"