class EpreuveDTO {

    constructor(epreuveData){
        if(!epreuveData)
            return

        const {_id, name, provider} = epreuveData

        this._id = _id
        this.name = name

        this.provider = provider
    }
}

export default EpreuveDTO