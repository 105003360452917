import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useNavigateTo = () => {
    const navigate = useNavigate();

    const navigateTo = useCallback((url, paramsToAdd = {}, paramsToRemove = []) => {
        const currentSearchParams = new URLSearchParams(window.location.search);
        const paramsString = currentSearchParams.toString(); // Convertit les paramètres existants en chaîne de requête (par exemple : "param1=value1&param2=value2")
      
        const newSearchParams = new URLSearchParams(paramsString);
      
        // Ajoute ou remplace les nouveaux paramètres fournis
        Object.entries(paramsToAdd).forEach(([key, value]) => {
          newSearchParams.set(key, value);
        });
      
        // Supprime les paramètres spécifiés
        paramsToRemove.forEach((param) => {
          newSearchParams.delete(param);
        });
      
        navigate(`${url}?${newSearchParams.toString()}`);
      }, [navigate]);

    return navigateTo
}