import { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const useViewLoader = (apiFunction, initialId = undefined, date = undefined) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState('');
  const [error, setError] = useState(null);

  const loadData = async (id) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiFunction(id, date);
      const apiContent = response.data;
      setData(apiContent);

    } catch (error) {
      localStorage.removeItem('token')
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
      loadData(initialId);
  }, []);

  return { loading, data, error, loadData };
};

export default useViewLoader;