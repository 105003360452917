import React from 'react';
import { Container, Paper, Typography, CircularProgress } from '@mui/material';
import useViewLoader from '../hooks/useViewV2Loader.js';
import { getClassementV2 } from '../../../../api/index.js';
import useViewToken from '../hooks/useViewToken.js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const ClassementV2View = () => {

  const { liveid, competitionid } = useParams();

  const baseurl = "/dsas-api/"+liveid+"/"+competitionid

  const { loading, data, error, loadData, formCredentials } = useViewLoader(getClassementV2, {idlive : liveid, idcompetition : competitionid, baseurl });

  useEffect(() => {
    // Appelez la fonction init() du bundle avec les données
    if(data)
      window.dsasApiClassement(data, 'dsas-view');
}, [data])

  return (
    <Container>
      {formCredentials}
      <Paper elevation={3} style={{ padding: '16px' }}>
        <div id="dsas-view"></div>

        {loading && (
          <CircularProgress />
        )}

        <Typography variant="h4" component="h2" gutterBottom>
        </Typography>
      </Paper>
    </Container>
  );
};

export default ClassementV2View