import React, { useEffect, useState, useMemo } from "react"
import { scoreResolver } from "../sports/scoreResolver.js"
import { GAME_FACT_HANDBALL_SHOOTOUT, GAME_FACT_SHOOTOUTPENALTY, GAME_FACT_SOCCER_SHOOTOUTPENALTY } from "../gamefacts/types.js"

export function useScoreResolver(match){

    const [homeScore, setHomeScore] = useState(0)
    const [awayScore, setAwayScore] = useState(0)

    const [homeScorers, setHomeScorers] = useState([])
    const [awayScorers, setAwayScorers] = useState([])

    const [homeShoutouts, setHomeShoutouts] = useState([])
    const [awayShoutouts, setAwayShoutouts] = useState([])

    const [homeShoutoutSucceed, awayShoutoutSucceed] = useMemo(() => {
        if(!match.gameFacts || !match.gameFacts.length)
            return [[], []]

        // On conserve uniquement les penalty
        let shoutoutGameFacts = match.gameFacts.filter(g => [GAME_FACT_SHOOTOUTPENALTY, GAME_FACT_SOCCER_SHOOTOUTPENALTY, GAME_FACT_HANDBALL_SHOOTOUT].includes(g.type))

        const homeTeamId = match.homeTeam._id;
        const awayTeamId = match.awayTeam._id;

        const homeShoutout = shoutoutGameFacts.filter(g => g.detail.team === homeTeamId && (g.detail.butSucced || g.detail.penalitySucced))
        const awayShoutout = shoutoutGameFacts.filter(g => g.detail.team === awayTeamId && (g.detail.butSucced || g.detail.penalitySucced))

        return [homeShoutout, awayShoutout]
    }, [match])

    const isHomeWinner = useMemo(() => {
        return (homeScore === awayScore && homeShoutoutSucceed > awayShoutoutSucceed) || homeScore > awayScore
    }, [awayScore, homeScore, homeShoutoutSucceed, awayShoutoutSucceed])

    const isAwayWinner = useMemo(() => {
        return (homeScore === awayScore && homeShoutoutSucceed < awayShoutoutSucceed) || homeScore < awayScore
    }, [awayScore, homeScore, homeShoutoutSucceed, awayShoutoutSucceed])


    useEffect(() => {
        if(!match.gameFacts || !match.homeTeam)
            return

        const [homeScoreCalcul, awayScoreCalcul, homePlayerScoreCalcul, awayPlayerScoreCalcul, homePlayerShoutout, awayPlayerShoutout] = scoreResolver(match)

        setHomeScore(homeScoreCalcul)
        setAwayScore(awayScoreCalcul)

        setHomeScorers(homePlayerScoreCalcul)
        setAwayScorers(awayPlayerScoreCalcul)

        setHomeShoutouts(homePlayerShoutout)
        setAwayShoutouts(awayPlayerShoutout)
    }, [match, match.gameFacts])

    return [homeScore, awayScore, homeScorers, awayScorers, isHomeWinner, isAwayWinner, homeShoutouts, awayShoutouts]
}