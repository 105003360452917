import { CREATE_GAMEFACT, DELETE_GAMEFACT, GET_LIVE_DETAILS, EDIT_MATCH_IN_LIVE, UPDATE_GAMEFACT, CREATE_NOTATION, DELETE_NOTATION,UPDATE_NOTATION, CREATE_ARTICLE, DELETE_ARTICLE, UPDATE_ARTICLE, INCREMENT_PERIOD, UPDATE_TIMER, CREATE_PLAYER, UPDATE_COMPOSITION, UPDATE_INFOMATCH, CREATE_SURVEY, UPDATE_SURVEY, DELETE_SURVEY, GET_MATCH_DETAILS, CREATE_QUIZZ, UPDATE_QUIZZ, DELETE_QUIZZ, AUTOSCORE_GAMEFACTS, UPDATE_INFOACTULIVE } from '../constants/actionTypes.js'

function buildComposition(payload, players){

    let result = payload.map(p => ({...players.find(player => player._id === p._id), position : p.position, numero : p.numero, isCpt : p.isCpt}))

    result = result.filter(p => p._id)

    return result
}

export default (live = {}, action) => {
    switch(action.type){
        case CREATE_PLAYER : 
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    if(action.payload.player.teams.includes(match.homeTeam._id))
                        return {... match, homeTeam : {...match.homeTeam, players : [...match.homeTeam.players, action.payload.player]}}
                    if(action.payload.player.teams.includes(match.awayTeam._id))
                        return {... match, awayTeam : {...match.awayTeam, players : [...match.awayTeam.players, action.payload.player]}}
                    return match
                }
            })}
        case EDIT_MATCH_IN_LIVE :
            const matchEdited = action.payload

            let newMatchsDetails = [...live.matchsDetails]
            newMatchsDetails = newMatchsDetails.map(match => {
                if(match.idMatch === matchEdited.idMatch){
                    return {...match, ...matchEdited}
                }

                return match
            })
            return {...live, matchsDetails : newMatchsDetails}
        case GET_LIVE_DETAILS:
            return {...action.payload}
        case GET_MATCH_DETAILS:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    let allArticles = (match.articles || []).concat(action.payload.articles || []);
                    let allSurveys = (match.surveys || []).concat(action.payload.surveys || []);
                    let allQuizzs = (match.quizzs || []).concat(action.payload.quizzs || []);
                    let allNotations = (match.notations || []).concat(action.payload.notations || []);
                    return {...match, ...action.payload, articles : allArticles, surveys : allSurveys, quizzs : allQuizzs, notations : allNotations}
                }
                    
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else{
                    let allArticles = (actu.articles || []).concat(action.payload.articles || []);
                    let allSurveys = (actu.surveys || []).concat(action.payload.surveys || []);
                    let allQuizzs = (actu.quizzs || []).concat(action.payload.quizzs || []);
                    let allNotations = (actu.notations || []).concat(action.payload.notations || []);
                    return {...actu, ...action.payload, articles : allArticles, surveys : allSurveys, quizzs : allQuizzs, notations : allNotations}
                }
            })}
        case CREATE_GAMEFACT:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else
                    return {...match, gameFacts : [...match.gameFacts, action.payload]}
            })}
        case AUTOSCORE_GAMEFACTS:
            const {deletedGameFacts, createdGameFacts, idMatch} = action.payload
            if(!live.matchsDetails)
                return live

            const matchDetail = live.matchsDetails.find(m => m.idMatch === idMatch)
            let newGameFacts = matchDetail.gameFacts.filter(g => !deletedGameFacts.includes(g._id))
            newGameFacts = newGameFacts.concat(createdGameFacts)

            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== idMatch)
                    return match
                else
                    return {...match, gameFacts : newGameFacts}
            })}
        case UPDATE_GAMEFACT:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else
                    return {...match, gameFacts : match.gameFacts.map(gf => gf._id === action.payload._id ? action.payload : gf)}
            })}
        case DELETE_GAMEFACT:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(!match.gameFacts.find(gf => gf._id === action.payload))
                    return match
                else
                    return {...match, gameFacts : match.gameFacts.filter(gf => gf._id !== action.payload)} 
            })}
        case CREATE_NOTATION:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else
                    return {...match, notations : [...match.notations, action.payload], pagination : match.pagination + 1}
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else
                    return {...actu, notations : [...actu.notations, action.payload], pagination : actu.pagination + 1}
            })}
        case UPDATE_NOTATION:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else
                    return {...match, notations : match.notations.map(gf => gf._id === action.payload._id ? (function(){ gf.details=action.payload.details ; return gf})() : gf)}
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else
                    return {...actu, notations : actu.notations.map(gf => gf._id === action.payload._id ? action.payload : gf)}
            })}
        case DELETE_NOTATION:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(!match.notations.find(n => n._id === action.payload))
                    return match
                else
                    return {...match, notations : match.notations.filter(gf => gf._id !== action.payload), pagination : match.pagination - 1} 
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(!actu.notations.find(n => n._id === action.payload))
                    return actu
                else
                    return {...actu, notations : actu.notations.filter(gf => gf._id !== action.payload), pagination : actu.pagination - 1} 
            })}
            case CREATE_SURVEY:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(match.idMatch !== action.payload.idMatch)
                        return match
                    else
                        return {...match, surveys : [...match.surveys, action.payload], pagination : match.pagination + 1}
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(actu.idActu !== action.payload.idMatch)
                        return actu
                    else
                        return {...actu, surveys : [...actu.surveys, action.payload], pagination : actu.pagination + 1}
                })}
            case UPDATE_SURVEY:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(match.idMatch !== action.payload.idMatch)
                        return match
                    else
                        return {...match, surveys : match.surveys.map(gf => {return gf._id === action.payload._id ? action.payload : gf})}
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(actu.idActu !== action.payload.idMatch)
                        return actu
                    else
                        return {...actu, surveys : actu.surveys.map(gf => gf._id === action.payload._id ? action.payload : gf)}
                })}
            case DELETE_SURVEY:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(!match.surveys.find(n => n._id === action.payload))
                        return match
                    else
                        return {...match, surveys : match.surveys.filter(gf => gf._id !== action.payload), pagination : match.pagination - 1} 
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(!actu.surveys.find(n => n._id === action.payload))
                        return actu
                    else
                        return {...actu, surveys : actu.surveys.filter(gf => gf._id !== action.payload), pagination : actu.pagination - 1} 
                })}
                case CREATE_QUIZZ:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(match.idMatch !== action.payload.idMatch)
                        return match
                    else
                        return {...match, quizzs : [...match.quizzs, action.payload], pagination : match.pagination + 1}
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(actu.idActu !== action.payload.idMatch)
                        return actu
                    else
                        return {...actu, quizzs : [...actu.quizzs, action.payload], pagination : actu.pagination + 1}
                })}
            case UPDATE_QUIZZ:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(match.idMatch !== action.payload.idMatch)
                        return match
                    else
                        return {...match, quizzs : match.quizzs.map(gf => gf._id === action.payload._id ? action.payload : gf)}
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(actu.idActu !== action.payload.idMatch)
                        return actu
                    else
                        return {...actu, quizzs : actu.quizzs.map(gf => gf._id === action.payload._id ? action.payload : gf)}
                })}
            case DELETE_QUIZZ:
                if(!live.matchsDetails)
                    return live
                return {...live, matchsDetails : live.matchsDetails.map(match => {
                    if(!match.quizzs.find(n => n._id === action.payload))
                        return match
                    else
                        return {...match, quizzs : match.quizzs.filter(gf => gf._id !== action.payload), pagination : match.pagination - 1} 
                }), actualitesDetails : live.actualitesDetails.map(actu => {
                    if(!actu.quizzs.find(n => n._id === action.payload))
                        return actu
                    else
                        return {...actu, quizzs : actu.quizzs.filter(gf => gf._id !== action.payload), pagination : actu.pagination - 1} 
                })}
        case CREATE_ARTICLE:
            
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    return {...match, articles : [...match.articles, action.payload], pagination : match.pagination + 1}
                }  
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else
                    return {...actu, articles : [...actu.articles, action.payload], pagination : actu.pagination + 1}
            })}
        case UPDATE_ARTICLE:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else
                    return {...match, articles : match.articles.map(gf => gf._id === action.payload._id ? action.payload : gf)}
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else
                    return {...actu, articles : actu.articles.map(gf => gf._id === action.payload._id ? action.payload : gf)}
            })}
        case DELETE_ARTICLE:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(!match.articles.find(a => a._id === action.payload))
                    return match
                else
                    return {...match, articles : match.articles.filter(gf => gf._id !== action.payload), pagination : match.pagination - 1} 
            }), actualitesDetails : live.actualitesDetails.map(actu => {
                if(!actu.articles.find(a => a._id === action.payload))
                    return actu
                else
                    return {...actu, articles : actu.articles.filter(gf => gf._id !== action.payload), pagination : actu.pagination - 1} 
            })}
        case UPDATE_TIMER:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    return {...match, ...action.payload}
                }
                    
            })}
        case UPDATE_COMPOSITION:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    // On doit construire homeHolers, homeSub, awayHolder, awaySub à l'aide du payload et des players de chaque team
                    // payload : homeHolders = [{_id, position, numero}]
                    // players : players = [{tout ce qu'on veut sauf position et numero}]
                    return {...match, ...action.payload,
                        homeHolders : buildComposition(action.payload.homeHolders, match.homeTeam.players), 
                        awayHolders : buildComposition(action.payload.awayHolders, match.awayTeam.players),
                        homeSubstitutes : buildComposition(action.payload.homeSubstitutes, match.homeTeam.players), 
                        awaySubstitutes : buildComposition(action.payload.awaySubstitutes, match.awayTeam.players)
                    }
                }
            })}
        case UPDATE_INFOMATCH:
            if(!live.matchsDetails)
                return live
            return {...live, matchsDetails : live.matchsDetails.map(match => {
                if(match.idMatch !== action.payload.idMatch)
                    return match
                else{
                    // On doit construire homeHolers, homeSub, awayHolder, awaySub à l'aide du payload et des players de chaque team
                    // payload : homeHolders = [{_id, position, numero}]
                    // players : players = [{tout ce qu'on veut sauf position et numero}]
                    return {...match, 
                        ...action.payload,
                    }
                }
            })}
        case UPDATE_INFOACTULIVE:
            if(!live.actualitesDetails)
                return live
            return {...live, actualitesDetails : live.actualitesDetails.map(actu => {
                if(actu.idActu !== action.payload.idMatch)
                    return actu
                else{
                    return {...actu, 
                        ...action.payload,
                    }
                }
            })}
        default :
            return live
    }
}


