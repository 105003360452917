import React from 'react';
import { Box } from '@mui/material';

import { ReactSVG } from 'react-svg';

import optaLogo from '../../images/pro/opta.png'
import dsasLogo from '../../images/pro/dsas.png'
function Footer({ isOpta = false, isDSAS = true, DSASLogoSize, DSASLogoDisplay}) {

    let dsasLogoSize = 'scale(1.5)';
    let dsasLogoAlign = DSASLogoDisplay ? DSASLogoDisplay : 'center';

    if (DSASLogoSize){
        if (DSASLogoSize === 'xs'){
            dsasLogoSize = 'scale(1)';
        }
        if (DSASLogoSize === 's'){
            dsasLogoSize = 'scale(1.25)';
        }
        if (DSASLogoSize === 'm'){
            dsasLogoSize = 'scale(1.5)';
        }
        if (DSASLogoSize === 'l'){
            dsasLogoSize = 'scale(1.75)';
        }
    }

    if(isOpta)
        return <Box sx={{backgroundColor : 'white', height : 50, mt : 1, display : 'flex', justifyContent : 'space-between', pl : 2, pr : 2}}>
            <ReactSVG src="path/to/your.svg" />
            <ReactSVG src="path/to/your.svg" />
            
            <img src={optaLogo} width={60}></img>
            <img src={dsasLogo} style={{ transform: 'scale(1.5)' }}></img>
        </Box>
if (isDSAS)
return <Box sx={{backgroundColor : 'white', height : 50, mt : 1, display : 'flex', justifyContent : dsasLogoAlign, pl : 2, pr : 2}}>
            <img src={dsasLogo} style={{ transform: dsasLogoSize }}></img>
        </Box>

return <Box></Box>
    
}

export default Footer