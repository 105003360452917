import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';
import { useNavigateTo } from './hooks/useNavigateTo.js';

import {DAY_MODE, CALENDAR_MODE} from './constants/MODE_LIST.js'
import { useDispatch } from 'react-redux';
import { resolveMessage } from '../../../actions/socket.js';

const LiveSocketManager = () => {

    const dispatch = useDispatch()

    const navigateTo = useNavigateTo();
    const { id, mode } = useParams();

    const [isConnected, setIsConnected] = useState(false);

  
    useEffect(() => {
      const url = process.env.NODE_ENV === 'development' ? 'ws://localhost:5000' : process.env.REACT_APP_BACKEND_URL;
      const socket = io.connect(url, {
        path: process.env.NODE_ENV === 'development' ? /collector-api/ + /socket.io/ : process.env.REACT_APP_BASE_URL + /socket.io/,
        reconnect: true,
        withCredentials: true,
        query: {
          idLive: id
        },
        transports: ['websocket', 'polling']
      });
  
      socket.on('connect', () => {
        setIsConnected(true);
      });

      socket.on('updated_match', (match) => {
        // Mettre à jour le live
        dispatch(resolveMessage(match))

        //dispatch({ type: EDIT_MATCH_IN_LIVE, payload: match})
      })
  
      socket.on('disconnect', () => {
        setIsConnected(false);
      });
  
      return () => {
        socket.disconnect();
      };
    }, [id]);
  
    useEffect(() => {
        if(isConnected){
          if (window.location.pathname.startsWith('/actualite/')) {

          }
          else{
            const resolvedMode = DAY_MODE === mode ? DAY_MODE : CALENDAR_MODE;
 
            // Update the URL with the resolved date if necessary
            if (!mode || mode !== resolvedMode) {
              navigateTo(`${resolvedMode}`);
            }
          }
        }
        
      }, [navigateTo, id, isConnected, mode]);
    
    if (!isConnected) {
      return <CircularProgress />
    }

    return <Outlet />;
};

export default LiveSocketManager