import * as api from '../api'
import { NOTE_PENDING, NOTE_RECEIVED } from '../constants/actionTypes.js'



export const addNote = (id, player, note) => async (dispatch, getState) => {
    try {
        const { data } = await api.addNote(id, {target : player._id, note : note})

        // Note received

        dispatch({ type : NOTE_RECEIVED, payload: {idNotation : id, target : player._id, note}})
    } catch(error){
        console.log(error.message)
    }
}

export const pendingNote = (id, player, note) => async (dispatch, getState) => {
    try {
        dispatch({ type : NOTE_PENDING, payload: {idNotation : id, target : player._id, note}})
    } catch(error){
        console.log(error.message)
    }
}