import { useOutletContext, useParams } from "react-router-dom";
import { CALENDAR_MODE, DAY_MODE } from "../constants/MODE_LIST.js";
import MultiplexCompetition from "./competition/MultiplexCompetition.js";
import MultiplexCalendar from "./calendar/MultiplexCalendar.js";
import { Box } from "@mui/material";

const MultiplexResolver = () => {

    const {live, liveDates, liveCompetitionsDays} = useOutletContext();

    const { mode } = useParams();

    if(mode === DAY_MODE)
        return <Box>
                <MultiplexCompetition live={live} liveCompetitionsDays={liveCompetitionsDays}/>
            </Box>

    if(mode === CALENDAR_MODE)
        return <Box>
            <MultiplexCalendar live={live} liveDates={liveDates}/>
        </Box>

}

export default MultiplexResolver