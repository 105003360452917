import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import BoxMedia from "../../items/fragments/BoxMedia.js";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillEditor from "../../lib/quill/QuillEditor.js";



const MediaArticle = ({ item, match }) => {
    const { title, content, imageUrl, timer, period, creation } = item;
    const [isPortrait, setIsPortrait] = useState(false);

    useEffect(() => {
      if (!imageUrl) return;
  
      const imageObject = new Image();
      imageObject.src = imageUrl;
  
      imageObject.onload = () => {
        setIsPortrait(imageObject.width <= imageObject.height);
      };
    }, [imageUrl]);

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.async = true;
      script.id = "twitterScript";
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);

    const isQuillContent = useMemo(() => {
      const regex = /^<(?!iframe|blockquote)/; // L'expression régulière vérifie que la chaîne commence par '<' mais pas par 'iframe' ou 'blockquote'
      return regex.test(content);
    }, [content]);
  

    const renderHTML = useCallback((rawHTML) => {
      const divStyle = {
        fontFamily: 'Source Sans Pro', // Ajoutez votre famille de polices ici
      };
    
      return React.createElement("div", { style: divStyle, dangerouslySetInnerHTML: { __html: rawHTML } });
    }, []);

    return (
      <BoxMedia media={item}>
        <Typography variant="mediaH2" component="div">{title}</Typography>

        {/** Si 'content' commence par '<' mais pas par '<iframe' ou '<blockquote' ==> alors c'est du quill content */}
        {
          isQuillContent ? 
          <QuillEditor theme="snow"
                content={content}
                readOnly={true}
                modules={{toolbar : null}}
            />
            :
            <>
              {renderHTML(content)}
              {imageUrl && (
                <img
                  src={imageUrl}
                  style={isPortrait ? { maxWidth: '100%', maxHeight: 600 } : { width: '100%' }}
                  alt="article"
                />
              )}
            </>
        }
        
  
        
      </BoxMedia>
    );
  };

export default MediaArticle