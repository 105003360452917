import { Box, Typography } from "@mui/material";
import { useMatchStatusResolver } from "../../hooks/useMatchStatusResolver.js";
import MatchScore from "./MatchScore.js";
import MatchTimerState from "./MatchTimerState.js";

const MatchStatusDetail = ({match}) => {


    const {isMatchStarted, isMatchEnded, isChronoRunning, isHalfTime, isMatchReported} = useMatchStatusResolver(match)

    if(isMatchReported)
      return (<Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-evenly"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        sx={{
          pointerEvents: "none",
          "& > *": {
            pointerEvents: "auto",
          },
        }}
      >
        <MatchScore match={match} />
        <MatchTimerState match={match} />
      </Box>)

    // Match pas démarré  : on affiche l'heure
    if(!isMatchStarted)
        return (<MatchScore match={match} />)

    // Match en cours : on affiche le score et l'état
    if(isMatchStarted && !isMatchEnded)
        return (<Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            sx={{
              pointerEvents: "none",
              "& > *": {
                pointerEvents: "auto",
              },
            }}
          >
            <MatchScore match={match} />
            <MatchTimerState match={match} />
          </Box>)

    // Match fini : on affiche le score
    if(isMatchEnded)
        return (<MatchScore match={match} />)


}

export default MatchStatusDetail