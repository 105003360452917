import {SURVEY_VOTE_RECEIVED, SURVEY_VOTES_RECEIVED } from '../constants/actionTypes.js'

const state = (surveys = {}, action) => {
    switch(action.type){
        case SURVEY_VOTE_RECEIVED:
            var {id, idChoice, idEntry} = action.payload
            surveys[id+'_'+idChoice] = idEntry

            return {...surveys}
        case SURVEY_VOTES_RECEIVED:
            var {id, votes} = action.payload
            votes.forEach(vote => {
                surveys[id+'_'+vote.idChoice] = vote.idEntry
            })

            return {...surveys}
        default :
            return {...surveys}
    }
}

export default state