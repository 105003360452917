import * as api from '../api'
import { NOTE_PENDING, NOTE_RECEIVED, QUIZZ_VOTE_RECEIVED, QUIZZ_VOTES_RECEIVED } from '../constants/actionTypes.js'



export const addVote = (id, idChoice, idEntry) => async (dispatch, getState) => {
    try {
        const { data } = await api.addVoteQuizz(id, {idChoice, idEntry})

        // Note received

        dispatch({ type : QUIZZ_VOTE_RECEIVED, payload: {id, idChoice, idEntry}})
    } catch(error){
        console.log(error.message)
    }
}

export const addVotes = (id, votes) => async (dispatch, getState) => {
    try {
        const { data } = await api.addVotesQuizz(id, votes)

        // Note received

        dispatch({ type : QUIZZ_VOTES_RECEIVED, payload: {id, votes}})
    } catch(error){
        console.log(error.message)
    }
}

export const pendingNote = (id, player, note) => async (dispatch, getState) => {
    try {
        dispatch({ type : NOTE_PENDING, payload: {idNotation : id, target : player._id, note}})
    } catch(error){
        console.log(error.message)
    }
}