import axios from 'axios';

const getToken = () => {
        return localStorage.getItem("token");
};

let url_to_use = undefined

if(process.env.NODE_ENV === 'development'){
    url_to_use = 'http://localhost:5000/collector-api';
}else{
    url_to_use = process.env.REACT_APP_COLLECTOR_API_URL;
}


const getHeaderLiveService = (params) => ({headers: {timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}, params})
const getHeaderView = (params) => ({headers: {timezone : Intl.DateTimeFormat().resolvedOptions().timeZone, 'Authorization': `Bearer ${getToken()}`}, params})


// V2        /v2/events/lives/dates/:liveId
const V2_url_event = url_to_use+'/v2/event';

// ENDPOINTS

/**
 * Récupère les date d'events du live
 * @param {*} idLive 
 * @returns LIVEDTO
 */
export const getV2LiveDates = (idLive) => axios.get(`${V2_url_event}/live/dates/${idLive}`, getHeaderLiveService())

/**
 * Récupère les events du live à la date donnée
 * @param {*} idLive 
 * @param {*} date 
 * @returns 
 */
export const getV2LiveDetails = (searchFilter) => axios.get(`${V2_url_event}/all`, getHeaderLiveService({ filter : searchFilter }))
export const getV2LiveById = (idLive) => axios.get(`${V2_url_event}/live/${idLive}`, getHeaderLiveService())


// V2 /v2/gamefact
const V2_url_gamefact = url_to_use+'/v2/gamefact'
///v2/gamefact/stats?filter={SearchFilterDTO} 

export const getAllV2Gamefacts = async (filter) => axios.get(`${V2_url_gamefact}/all?filter=${filter}`, getHeaderLiveService())
export const getAllV2GamefactsStats = async (filter) => axios.get(`${V2_url_gamefact}/stats?filter=${filter}`, getHeaderLiveService())

// V2 /v2/conf
const V2_url_conf = url_to_use+'/v2/conf'

export const getPageInfos = async (idLive, viewIdName) => axios.get(`${V2_url_conf}/view/tabs/${idLive}/${viewIdName}`, getHeaderLiveService())
export const getNavigationsComponent = async (idLive, idCompetition, idNameConfig, idPage, idView, idNavigation) => axios.get(`${V2_url_conf}/component/${idLive}/${idCompetition}/${idNameConfig}/${idPage}/view/${idView}/navigation/${idNavigation}`, getHeaderLiveService())
export const getOngletComponent = async (idLive, idCompetition, idNameConfig, idPage, idView, idTab, external) => axios.get(`${V2_url_conf}/component/${idLive}/${idCompetition}/${idNameConfig}/${idPage}/view/${idView}/tab/${idTab}/${JSON.stringify(external)}`, getHeaderLiveService())



//V1
const url_matchdetail = url_to_use+'/matchdetail';
const url_lives = url_to_use+'/lives';
const url_compositions = url_to_use+'/compositions';
const url_notation = url_to_use+'/notation';
const url_survey = url_to_use+'/survey';
const url_quizz = url_to_use+'/quizz';
const url_competition = url_to_use+'/competitions'
const url_view = url_to_use+'/view'

export const getLiveDetails = (idLive, mode, date, competition) => {
    let url = `${url_lives}/${idLive}`;
    return axios.get(url, getHeaderLiveService({ mode, date, competition }));
};

export const getLiveDates = (idLive) => axios.get(`${url_lives}/dates/${idLive}`, getHeaderLiveService())
export const getLiveCompetitions = (idLive) => axios.get(`${url_lives}/competitions/${idLive}`, getHeaderLiveService())

export const getCompetitionClassement = (idCompetition) => axios.get(`${url_competition}/classement/${idCompetition}`, getHeaderLiveService())

export const getMatchDetail = (idMatch) => axios.get(`${url_matchdetail}/${idMatch}`)

export const getPaginedMatchDetail = (idMatch, pagination) => axios.get(`${url_matchdetail}/${idMatch}/${pagination}`)

export const getPlayersPositions = (composition, options) => axios.get(`${url_compositions}/positions/${composition}`,getHeaderLiveService({ options }))

export const createNotation = (newNotation) => axios.post(url_notation, newNotation)
export const updateNotation = (id, updatedNotation) => axios.patch(`${url_notation}/${id}`, updatedNotation)
export const deleteNotation = (id) => axios.delete(`${url_notation}/${id}`)
export const addNote = (id, detail) => axios.patch(`${url_notation}/addnote/${id}`, detail)

export const addVote = (id, detail) => axios.patch(`${url_survey}/addvote/${id}`, detail)
export const addVotes = (id, detail) => axios.patch(`${url_survey}/addvotes/${id}`, detail)

export const addVoteQuizz = (id, detail) => axios.patch(`${url_quizz}/addvote/${id}`, detail)
export const addVotesQuizz = (id, detail) => axios.patch(`${url_quizz}/addvotes/${id}`, detail)


// TOKEN
export const getViewToken = (clientId, clientSecret) => axios.post(`${url_view}/token`, {clientId, clientSecret})

// VIEW
export const getViewCompetition = (id = undefined, day = undefined) => axios.get(
    (() => {
        if(id && day)
            return `${url_view}/competition/${id}/${day}`
        if(id)
            return `${url_view}/competition/${id}`

        return`${url_view}/competition`
    })()
    , getHeaderView({baseurl : '/dsas-api', day}))
export const getViewPlayer = (id = undefined) => axios.get(`${url_view}/player/${id}`, getHeaderView({baseurl : '/dsas-api'}))
export const getViewTeam = (id = undefined) => axios.get(`${url_view}/team/${id}`, getHeaderView({baseurl : '/dsas-api'}))

export const getCalendrierV2 = ({idlive = undefined, idcompetition = undefined, date, baseurl}) => axios.get(`${url_to_use+'/api'}/calendrier/${idlive}/${idcompetition}/${date}`, getHeaderView({baseurl}))
export const getClassementV2 = ({idlive = undefined, idcompetition = undefined, baseurl}) => axios.get(`${url_to_use+'/api'}/medaltable/${idlive}/${idcompetition}`, getHeaderView({baseurl}))
export const getResultatV2 = ({idlive = undefined, idcompetition = undefined, baseurl}) => axios.get(`${url_to_use+'/api'}/medaldetail/${idlive}/${idcompetition}`, getHeaderView({baseurl}))