import { Box } from "@mui/material";
import backgroundDefault from '../../../../images/live/header/default.png'
import backgroundBasket from '../../../../images/live/header/basket.png'
import backgroundHandball from '../../../../images/live/header/handball.png'

const BoxImageComponent = ({ src, discipline}) => {

    const defaultImage = () => {
      switch(discipline){
        case 'basket':
          return backgroundBasket
        case 'handball':
          return backgroundHandball
        default :
          return backgroundDefault
        
      }
    }

    const boxStyle = {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${src ?? defaultImage()})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  
    return <Box sx={boxStyle}></Box>;
  };
  
  export default BoxImageComponent;