import { Box } from "@mui/material"
import { getPlayerById, getTeamInfoById, getVisualTimer } from "../../../sports/genericUtils.js"

import BoxItem from "../../../items/fragments/BoxItem.js";
import TypoTimer from "../../../items/fragments/TypoTimer.js";
import ImgShadow from "../../../items/fragments/ImgShadow.js";
import TypoTitle from "../../../items/fragments/TypoTitle.js";
import TypoText from "../../../items/fragments/TypoText.js";
import BoxPlayer from "../../../items/fragments/BoxPlayer.js";


const GenericOther = ({item, match, icon}) => {

    const {
        text, title, 
        team, player, 
        period, timer
    } = item.detail

    const visualTimer = getVisualTimer(timer, period, match.discipline)
    const teamDetail = getTeamInfoById(team, match)
    const playerDetail = getPlayerById(player, match)

    return (
        <BoxItem color={teamDetail.color}
        >
          <TypoTimer timer={visualTimer} lisere={teamDetail.color}/>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '8px',
              position : 'relative'
            }}
          >
            <Box sx={{display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                <ImgShadow src={icon} width="45" height="45"/>
                <Box
                        sx={{
                        position: 'absolute',
                        backgroundColor: '#CCCCCC',
                        width: '2px',
                        height: 'calc(100% - 40px)',// Liseré gris
                        top : '50px',
                        }}
                />
            </Box>
            
            <Box sx={{paddingLeft : "8px"}}>
              <TypoTitle title={title}/>
              <TypoText text={text} />
            </Box>
          </Box>

          <BoxPlayer player={playerDetail} width={40} height={40} color={teamDetail.color}/>
          
        </BoxItem>
      );
}

export default GenericOther