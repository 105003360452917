import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Divider, Stack, Typography, } from "@mui/material";
import ItemResolver from "../items/ItemResolver.js";
import { getNumericTimer } from "../sports/genericUtils.js";
import CustomDivider from "../dsasComponents/CustomDivider.js";

const ActuView = ({ actu, loadMore  }) => {

    const defaultVisible = 10

    const [pinItems, setPinItems] = useState([])

    const [items, setItems] = useState([])
    const [visibleItems, setVisibleItems] = useState(defaultVisible);

    useEffect(() => {

        // Le but ici est de trier les items

        let buildMedias = actu.quizzs ? actu.quizzs.map(i => ({...i, type : 'quizz'})) : []
        let buildSurveys = actu.surveys ? actu.surveys.map(i => ({...i, type : 'survey'})) : []
        let buildNotations = actu.notations ? actu.notations.map(i => ({...i, type : 'notation'})) : []
        let buildArticles = actu.articles ? actu.articles.map(i => ({...i, type : 'article'})) : []

            const sortThings = (a, b) => {
                let bTimer = getNumericTimer(b.timer, b.period, actu.discipline)
                let aTimer = getNumericTimer(a.timer, a.period, actu.discipline)
    
    
                if(bTimer < aTimer)
                    if(b.period > a.period)
                        return 1
                    else
                        return -1
                if(bTimer > aTimer)
                    if(b.period < a.period)
                        return -1
                    else
                        return 1
                if(new Date(b.creation) < new Date(a.creation))
                    return -1
                if(new Date(b.creation) > new Date(a.creation))
                    return 1
            }

            setPinItems(buildMedias.filter(g => g.isPin).concat(buildSurveys.filter(g => g.isPin)).concat(buildNotations.filter(g => g.isPin)).concat(buildArticles.filter(g => g.isPin)).sort(sortThings))

            setItems(buildMedias.filter(g => !g.isPin).concat(buildSurveys.filter(g => !g.isPin)).concat(buildNotations.filter(g => !g.isPin)).concat(buildArticles.filter(g => !g.isPin)).sort(sortThings))
    }, [actu])

    const handleShowMore = async () => {

        if(actu.hasMore)
            await loadMore()

        const newVisibleItems = visibleItems + defaultVisible;
        setVisibleItems(newVisibleItems);
    };

    const disabledShowMore = useMemo(() => {
        return visibleItems >= items.length
        && !actu.hasMore
    }, [actu.hasMore, visibleItems, items])



    return (
        <Box>

                    <Box sx={{ display : 'flex', justifyContent : 'flex-start', alignItems : 'center', ml : '1.2em', mb : '1em'}}>
                        <Box sx={{
                                float : 'left',
                                color : 'white',
                                width : 'fit-content',
                                padding : "3px 9px 3px 7px",
                                display : 'flex',
                                background: '#CB1F1F 0% 0% no-repeat padding-box',
                                borderRadius: '20px'}}>
                                    <Typography sx={{fontSize : '0.7em', whiteSpace : 'nowrap'}}>&bull; DIRECT</Typography>

                        </Box>
                        <Typography 
                            gutterBottom  
                            component="div" 
                            variant="h1"
                            sx={{mt : ".3em", mb : '.3em', ml : ".4em", textTransform : 'none'}}>
                                {actu.name}
                        </Typography>                
                    </Box>
                    

                
                {pinItems.length>0 && <Stack spacing={0}>
                    {pinItems.map((t, idx) =>
                        <React.Fragment key={idx}>
                            <ItemResolver item={t} match={actu} />
                        </React.Fragment>
                    )}
                    <CustomDivider/>
                </Stack>}
                <Stack spacing={0}>
                {items.slice(0, visibleItems).map((t, idx) => 
                    <React.Fragment key={idx}>
                        <ItemResolver item={t} match={actu} />
                        <CustomDivider disabled={idx===items.slice(0, visibleItems).length - 1 ? true : false}/>
                    </React.Fragment>
                )}
                </Stack>
                {!disabledShowMore ? (
                    <BottomButton label="Voir plus" onClick={handleShowMore}/>
                ) : (
                    <BottomButton label="Début du live"/>
                    
                )}
        </Box>
        )

}

const BottomButton = ({label, onClick}) => {

    const disabled = onClick === undefined

    return (
    <Box sx={{position : 'relative', width : '100%', pb : '1em'}}>
        <div style={{position : 'absolute', bottom : '10px', width : '100%'}}>
            <CustomDivider/>
        </div>
        
        <Button sx={{
            position : 'absolute', 
            bottom : 0, 
            left : '50%',
            transform : 'translateX(-50%)',
            width : 'fit-content', 
            backgroundColor : disabled ? "rgba(200,200,200,1) !important" : "rgba(0,0,0,1) !important", 
            color : disabled ? 'rgb(50,50,50) !important' : 'white !important', 
            boxShadow : 'none',
            borderRadius : '20px',
            cursor: disabled ? 'not-allowed' : 'pointer',
        }} 
            
            onClick={onClick ? onClick : () => {}} 
            disabled={disabled}
            variant="contained">
            {label}
        </Button>
    </Box>
    
    )
}

export default ActuView