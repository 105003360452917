import React, {useMemo, useState} from 'react'
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigateTo } from "../../../v1/components/live/hooks/useNavigateTo.js";
import { useEffect } from "react";
import SportEventList from "../SportEventList.js";
import { useLiveResolver } from "../../hooks/useLiveResolver.js";
import Filter from "../Filter.js";
import CalendarNavigation from '../../components/calendar/CalendarNavigation.js';
import SearchFilterDTO from '../../DTO/SearchFilterDTO.js';
import { startOfDay, endOfDay, format } from 'date-fns';

const CalendarDetail = () => {


    const navigate = useNavigate();

    const navigateTo = useNavigateTo();
    const { id, date } = useParams();

    const queryParameters = new URLSearchParams(window.location.search)
    const sport = queryParameters.get("sport")
    const country = queryParameters.get("country")

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    const {liveDates, liveCompetitionsDays} = useOutletContext();

    const searchFilter = useMemo(() => {
        const startOfDayDate = startOfDay(new Date(date));
        const endOfDayDate = endOfDay(new Date(date));

        return new SearchFilterDTO({    global  : { _id : [id] }, 
                                        sportevent : { 
                                            beginDate : startOfDayDate, 
                                            endDate : endOfDayDate, 
                                        },
                                        cache:true
                                    })
    }, [id, date])

    const [live] = useLiveResolver(searchFilter)

    const filteredEvents = useMemo(() => {
        if(!live || !live.sportEvents)
            return null

        return live.sportEvents
                        .filter(event => 
                            (!sport || event.phase?.competitionDiscipline?.discipline?.sport?.label === sport) && 
                            (!country || ((event.players && event.players.find(p => p.nationality === country) || 
                            (event.teams && event.teams.find(t => t.nationality === country))))))
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
    }, [live, sport, country])

    const navigateToNewDate = (newDate) => {

        if(newDate === date)
            return

        const newPath = window.location.pathname.replace(/\/calendar\/[^/]+/, `/calendar/${newDate}`);
        live.sportEvents = []
        navigateTo(newPath);
    };

    const handleSportChange = (event) => {

        const currentUrl = new URL(window.location);

        // Utiliser URLSearchParams pour travailler avec les paramètres de requête
        const searchParams = currentUrl.searchParams;

        // Définir ou changer le paramètre 'sport'
        searchParams.set('sport', event);

        // Mettre à jour l'objet searchParams sur l'objet URL
        currentUrl.search = searchParams.toString();

        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {sport : event});
    };

    const handleSportClear = () => {
        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {}, ['sport']);
    }

    const handleCountryChange = (event) => {

        const currentUrl = new URL(window.location);

        // Utiliser URLSearchParams pour travailler avec les paramètres de requête
        const searchParams = currentUrl.searchParams;

        // Définir ou changer le paramètre 'sport'
        searchParams.set('country', event);

        // Mettre à jour l'objet searchParams sur l'objet URL
        currentUrl.search = searchParams.toString();

        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {country : event});
    };

    const handleCountryClear = () => {
        // Utiliser `navigate` de react-router pour changer l'URL sans recharger la page
        navigateTo('', {}, ['country']);
    }

    if(!live)
        return <CircularProgress />

    return <Box>
         {/*<Box sx={{ minWidth: '100%', display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', pt : 3, pb : 1 }}>
         <Typography sx={{fontSize : '1.6em', fontWeight : 'bold', textTransform : 'uppercase', color :  "#4A5168"}}>calendrier</Typography>
         </Box>*/}
        <CalendarNavigation liveDates={liveDates} activeDate={date} setActiveDate={navigateToNewDate} />

            <Box sx={{ minWidth: '100%', display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', pt : 3, pb : 1 }}>
                {/*<Button onClick={navigateToCustomArticle} sx={{color : 'blue'}}>Accédez à notre direct du jour</Button>*/}
                <Typography sx={{ fontStyle: 'italic', color: '#4A5168', mr: 1, fontSize: '0.8em' }}>Filtre</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        minHeight: 60,
                        justifyContent: 'space-evenly'
                    }}
                    >
                    
                    <Filter items={live.getSportsList()} selectedItem={sport} setSelectedItem={handleSportChange} clear={handleSportClear} label="Tous les sports" />
                    <Filter items={['France']} selectedItem={country} setSelectedItem={handleCountryChange} clear={handleCountryClear} label={"Tous les pays"} />
                </Box>
                
            </Box>
            {(!live.sportEvents || !live.sportEvents.length) ? <CircularProgress/> : 
            <React.Fragment>
                <SportEventList events={filteredEvents}
                selectedSport={sport}
                ></SportEventList>
            </React.Fragment>
        }
    </Box>
};

export default CalendarDetail