//TODO Créer filtre spécialisé competition : Liste de competitionFilter (crtières de recherches par compétition id)
/**
 * FilterDTO sur chaque DTO ?
 * 
 * CompetitionFilterDTO [
 * {
 *        id:'123',
 *              {
    *               sportevent: {beginDate: '2024/03/17', endDate: '2024/03/20'},
    *               competitionDiscipline: {_id: [''], phases : {_id: ['789', '012']}},
    *           }
    * }
 */


class SearchFilterDTO {

   /**
    * Constructeur de la classe SearchFilterDTO
    * @param {*} filterData Données de filtrage
    * @example {
    *               competition: {_id: ['123', '456']},
    *               sportevent: {beginDate: '2024/03/17', endDate: '2024/03/20'},
    *               discipline: {idName: ['Basket', 'Hockey']},
    *               phase: {_id: ['789', '012']}
    *           }
    * @returns {SearchFilterDTO} Instance de SearchFilterDTO
    */
    constructor(filterData){

        if (!filterData) {
            return; 
        }

        //On current element /ex {provider:'providerName', nationality:''}
        this.global = filterData.global || {};

        // Initialiser les propriétés avec les valeurs par défaut
        this.competition = filterData.competition || {};
        this.sportevent = filterData.sportevent || {};
        this.gamefact = filterData.gamefact || {};
        this.discipline = filterData.discipline || {};
        this.phase = filterData.phase || {};
        this.sport = filterData.sport || {};
        this.live = filterData.live || {};

        this.cache = filterData.cache || false;

    }

}

export default SearchFilterDTO