import { FETCH_ALL_ACTULIVE, CREATE_ACTULIVE, DELETE_ACTULIVE, UPDATE_ACTULIVE } from '../constants/actionTypes.js'

export default (actulives = [], action) => {
    switch(action.type){
        case FETCH_ALL_ACTULIVE:
            return action.payload
        case CREATE_ACTULIVE:
            return [...actulives, action.payload]
        case UPDATE_ACTULIVE:
            return actulives.map((actulive) => actulive._id === action.payload._id ? action.payload : actulive)
        case DELETE_ACTULIVE:
            return actulives.filter((actulive) => actulive._id !== action.payload)
        default :
            return actulives
    }
}