import DisciplineDTO from "./DisciplineDTO.js"
import TeamDTO from "./TeamDTO.js"

class PlayerDTO {

    constructor(playerData){
        if(!playerData)
            return

        const {_id, lastname, firstname, birthdate, nationality, department, teams, disciplines, provider} = playerData

        this._id = _id

        this.lastname = lastname
        this.firstname = firstname
        this.birthdate = birthdate
        this.nationality = nationality
        this.department = department

        this.disciplines = disciplines?.map(discipline => new DisciplineDTO(discipline))

        this.teams = teams?.map(team => new TeamDTO(team))

        this.provider = provider
    }
}

export default PlayerDTO