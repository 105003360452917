import { useMemo } from 'react'
import { Box } from "@mui/material"
import BoxItem from "../../../items/fragments/BoxItem.js"
import TypoTimer from "../../../items/fragments/TypoTimer.js"
import ImgShadow from "../../../items/fragments/ImgShadow.js"
import TypoTitle from "../../../items/fragments/TypoTitle.js"
import TypoText from "../../../items/fragments/TypoText.js"
import BoxPlayer from "../../../items/fragments/BoxPlayer.js"

const GenericScore = ({teamDetail, playerDetail, visualTimer, icon, succed = 0, title, text}) => {

    const indexIcone = useMemo(() => {
      if([true, "true", 1, "1"].includes(succed))
        return 1

      return 0
    }, [succed])

    return (
        <BoxItem color={teamDetail.color} lisiere={true}
        >
          <TypoTimer timer={visualTimer}/>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '8px',
              position : 'relative'
            }}
          >
            <Box sx={{display : 'flex', flexDirection : 'column', alignItems : 'center'}}>
                <ImgShadow src={icon[indexIcone]} width="45" height="45"/>
                <Box
                        sx={{
                        position: 'absolute',
                        backgroundColor: '#CCCCCC',
                        width: '2px',
                        height: 'calc(100% - 40px)',// Liseré gris
                        top : '50px',
                        }}
                />
            </Box>
            
            <Box sx={{paddingLeft : "8px"}}>
              <TypoTitle title={title}/>
              <TypoText text={text} />
            </Box>
          </Box>

          <BoxPlayer player={playerDetail} width={40} height={40} color={teamDetail.color}/>
          
        </BoxItem>
    )
}

export default GenericScore